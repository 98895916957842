import React, { useEffect, useState } from 'react'
import { SubscribersUserListing } from '../../common/Api';
import moment from 'moment';
const Subcribers = () => {
    const [getAllSubscribers, setGetAllSubscribers] = useState([]);
    const GetAllsubscribers = async () => {
        const response = await SubscribersUserListing()
        if (response && response?.statusCode === 200) {
            setGetAllSubscribers(response?.data)
        }
    }
    useEffect(() => {
        GetAllsubscribers()
    }, []);

    return (
        <div>
            <div>
                <div className='d-flex justify-content-between'>
                    <h3 className='admin_head'>All Subscribers</h3>
                    {/* <button className='admin_button mt-0' onClick={() => { navigate("/admin/add-product") }}>
                + Add a Product
            </button> */}
                </div>
                <div className="tabular_column_parent mt-5">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="d-flex justify-content-center tabular_heading">
                                <h4>S.No</h4>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="d-flex justify-content-center tabular_heading">
                                <h4>Email Address</h4>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="d-flex justify-content-center tabular_heading">
                                <h4>Date</h4>
                            </div>
                        </div>
                    </div>
                </div>
                {getAllSubscribers?.length > 0 && getAllSubscribers.map((item, index) => (
                    <div className='tabular_column_data_parent mt-4' key={index}>
                        <div className='row'>
                            <div className="col-lg-4">
                                <div className="d-flex justify-content-center tabular_heading">
                                    <h4>{index + 1}</h4>
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <div className='d-flex justify-content-center tabular_heading'>
                                    <h6>{item?.email_id}</h6>
                                </div>
                            </div>

                            <div className='col-lg-4'>
                                <div className='d-flex justify-content-center tabular_heading'>
                                    <h6>{moment(item?.createdAt).format('DD/MM/YYYY hh:mm A')}</h6>
                                </div>
                            </div>
                        </div>

                    </div>
                )
                )}
            </div>
        </div>
    )
}

export default Subcribers
