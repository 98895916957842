import Multiselect from "multiselect-react-dropdown";
import React, { useEffect, useState } from "react";
import { AdminCreateProduct, AdminGetCategory, AdminUpdateProducts, getCategory, ImageUpload } from "../../common/Api";
import { errorMsg, successMsg } from "../../common/Toastify";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import { NonEmptyId, NonEmptyValidation, PriceValidation } from "../../common/validate";
import { imageBaseUrl } from "../../common/BaseUrl";

const AddProduct = () => {
  const location = useLocation();
  const { EditproductDetails } = location.state || {};

  const navigate = useNavigate();
  const [fileName, setFileName] = useState("");
  const [allCategory, setAllCategory] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [productDetails, setProductDetails] = useState({});
  const [validation, setValidation] = useState({});

  const handleProductCreate = (value, key) => {
    setProductDetails({ ...productDetails, [key]: value });
    if (validation[key]) setValidation({ ...validation, [key]: false });
  };

  const handleImageUpload = async (e) => {
    let formData = new FormData();
    let image_name = e?.target?.files[0]?.name;
    let image_type = image_name?.split(".");
    let type = image_type.pop();
    if (type !== "jpeg" && type !== "png" && type !== "webp" && type !== "jpg") {
      errorMsg("File Not Supported")
      return;
    }
    setFileName(image_name);
    formData.append("file", e?.target?.files[0]);
    formData.append("fileName", "product" + "_" + moment().format("YYYYMMDDHHmmss") + "." + type);
    const response = await ImageUpload(formData);
    if (response && response.statusCode === 200) {
      successMsg("Image Uploaded Successfully");
      setProductDetails({
        ...productDetails,
        image: response?.data?.filename,
      });
    }
  };

  const GetAllCategory = async () => {
    const response = await AdminGetCategory();
    if (response && response?.statusCode === 200) {
      setAllCategory(response?.data);
    }
  };
  const onSelectOption = (selectedList) => {
    const categoryIds = selectedList.map((item) => item._id);
    setSelectedValues(selectedList);
    handleProductCreate(categoryIds, "category_id");
  };

  const onRemoveOption = (selectedList) => {
    const categoryIds = selectedList.map((item) => item._id);
    setSelectedValues(selectedList);
    handleProductCreate(categoryIds, "category_id");
  };

  const handleCreateproductSubmit = async (e) => {
    e.preventDefault();
    let validate = {};
    validate.product_name = NonEmptyValidation(productDetails?.product_name);
    validate.description = NonEmptyValidation(productDetails?.description);
    // validate.base_price = PriceValidation(productDetails?.base_price)
    validate.final_price = PriceValidation(productDetails?.final_price);
    validate.unit = NonEmptyValidation(productDetails?.unit);
    validate.stock = NonEmptyValidation(productDetails?.stock);
    validate.image = NonEmptyValidation(productDetails?.image)
    validate.category_id = {
      status: selectedValues?.length > 0,
      message: selectedValues?.length === 0 ? "is required" : ""
    };
    setValidation(validate);
    const validation_status = Object.values(validate).every((v) => v.status === true);
    if (validation_status) {
      let payload = {};
      payload.product_name = productDetails?.product_name;
      payload.description = productDetails?.description;
      payload.base_price = productDetails?.base_price;
      payload.final_price = productDetails?.final_price;
      payload.unit = productDetails?.unit;
      payload.stock = productDetails?.stock;
      payload.image = productDetails?.image;
      payload.category_id = productDetails?.category_id;
      const response = await AdminCreateProduct(payload);
      if (response && response?.statusCode === 200) {
        successMsg("Product Created  Successfully");
        setProductDetails({});
        navigate(-1);
        setValidation({});
        setSelectedValues({});
        setFileName("");
      } else {
        errorMsg(response?.message);
      }
    }
  };
  const UpdateProductDetails = async (e) => {
    e.preventDefault();
    let validate = {};
    validate.product_name = NonEmptyValidation(productDetails?.product_name);
    validate.description = NonEmptyValidation(productDetails?.description);
    // validate.base_price = PriceValidation(productDetails?.base_price)
    validate.final_price = PriceValidation(productDetails?.final_price);
    validate.unit = NonEmptyValidation(productDetails?.unit);
    validate.stock = NonEmptyValidation(productDetails?.stock);
    validate.image = NonEmptyValidation(productDetails?.image)
    validate.category_id = {
      status: selectedValues?.length > 0,
      message: selectedValues?.length === 0 ? "is required" : ""
    };
    setValidation(validate);
    const validation_status = Object.values(validate).every((v) => v.status === true);
    if (validation_status) {
      let payload = {};
      payload._id = productDetails?._id;
      payload.product_name = productDetails?.product_name;
      payload.description = productDetails?.description;
      payload.base_price = productDetails?.base_price;
      payload.final_price = productDetails?.final_price;
      payload.unit = productDetails?.unit;
      payload.stock = productDetails?.stock;
      payload.image = productDetails?.image;
      payload.category_id = productDetails?.category_id;
      const response = await AdminUpdateProducts(payload);
      if (response && response?.statusCode === 200) {
        successMsg("Product Updated Successfully");
        setProductDetails({});
        navigate(-1);
        setValidation({});
        setSelectedValues({});
        setFileName("");
      } else {
        errorMsg(response?.message);
      }
    }
  };
  const isEditMode = EditproductDetails && typeof EditproductDetails === "object" && Object.keys(EditproductDetails).length > 0;

  useEffect(() => {
    GetAllCategory();
  }, []);

  useEffect(() => {
    if (isEditMode) {
      setProductDetails(EditproductDetails);
      setSelectedValues(EditproductDetails?.category);
      setFileName(EditproductDetails?.image);
    }
  }, []);

  return (
    <div>
      <div className="d-flex justify-content-between">
        <h3 className="admin_head mt-2">{isEditMode ? "Edit" : "Add"} Product</h3>
        <button className="backbutton float-end mt-0"
          onClick={() => navigate(-1)}
        >
          <i className="fa-solid  fa-angles-left "></i> &nbsp;
          back to product list
        </button>
      </div>
      <div className="mt-5">
        <div className="row">
          <div className="col-lg-6">
            <div className="add_product_left">
              <p className="add_product">Basic information</p>
              <div className="d-flex flex-column">
                <label className="add_product_label">Product Name</label>
                <input className="add_product_input" value={productDetails?.product_name || ""}
                 onChange={(e) => handleProductCreate(e.target.value, "product_name")} maxLength={30}/>
                {validation?.product_name?.message && <p className="error_message text-start">{`Product name ${validation?.product_name?.message}` || ""}</p>}
              </div>
              <div className="d-flex flex-column mt-4">
                <label className="add_product_label">Product Description</label>
                <textarea className="add_product_textarea pt-2" maxLength={200} value={productDetails?.description || ""} onChange={(e) => handleProductCreate(e.target.value, "description")} />
                <p className="text-info fw-bold text-start">Note : Max 200 characters only </p>
                {validation?.description?.message && <p className="error_message text-start">{`Product description ${validation?.description?.message}` || ""}</p>}
              </div>
              <div className="d-flex flex-column mt-4">
                <Multiselect options={allCategory} selectedValues={selectedValues}
                  onSelect={onSelectOption} onRemove={onRemoveOption}
                  placeholder="Select Product Category" displayValue="category" showArrow={true}
                // onChange={()=>}
                />
                {validation?.category_id?.message && <p className="error_message text-start">{`Category ${validation?.category_id?.message}` || ""}</p>}
              </div>
            </div>
            <div className="add_product_left mt-5">
              <p className="add_product">Pricing & Inventory</p>
              <div className="d-flex flex-row justify-content-between">
                <div className="d-flex flex-column">
                  <label className="add_product_label">Final pricing</label>
                  <input
                    className="add_product_input"
                    type="number"
                    style={{ width: "220px" }}
                    value={productDetails?.final_price || ""}
                    onChange={(e) => handleProductCreate(e.target.value, "final_price")}
                    onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                    onWheelCapture={(e) => {
                      e.target.blur();
                    }}
                  />
                  {validation?.final_price?.message && <p className="error_message text-start">{`Final price ${validation?.final_price?.message}` || ""}</p>}
                </div>
                <div className="d-flex flex-column">
                  <label className="add_product_label">Base pricing</label>
                  <input
                    className="add_product_input"
                    type="number"
                    style={{ width: "220px" }}
                    value={productDetails?.base_price || ""}
                    onChange={(e) => handleProductCreate(e.target.value, "base_price")}
                    onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                    onWheelCapture={(e) => {
                      e.target.blur();
                    }}
                  />
                  <p className="text-info fw-bold text-start">Note : If there is no base price give 0 </p>
                </div>
              </div>
              <div className="d-flex flex-column mt-4">
                <label className="add_product_label">Unit</label>
                <select className="add_product_select" onChange={(e) => handleProductCreate(e.target.value, "unit")} value={productDetails?.unit || ""}>
                  <option value="" selected disabled>
                    {" "}
                    Select Product Unit
                  </option>
                  <option value="Kg">Kg</option>
                  <option value="Packet">Packet</option>
                  <option value="Piece">Piece</option>
                  <option value="Dozen">Dozen</option>
                </select>
                {validation?.unit?.message && <p className="error_message text-start">{`Unit ${validation?.unit?.message}` || ""}</p>}
              </div>
            </div>
            <div className="d-flex flex-column mt-4">
              <label className="add_product_label">Stock Status</label>
              <select className="add_product_select" onChange={(e) => handleProductCreate(e.target.value, "stock")} value={productDetails?.stock || ""}>
                <option value="" selected disabled>
                  Select Stock Status
                </option>
                <option value="in_stock">In Stock</option>
                <option value="out_of_stock">Out of Stock</option>
              </select>
              {validation?.stock?.message && <p className="error_message text-start">{`Stock ${validation?.stock?.message}` || ""}</p>}
            </div>
            <button
              className="admin_button mt-4 w-100"
              onClick={(e) => {
                if (isEditMode) {
                  UpdateProductDetails(e);
                } else {
                  handleCreateproductSubmit(e);
                }
              }}
            >
              {isEditMode ? "Update" : "Create New"} Product
            </button>
          </div>
          <div className="col-lg-1"></div>
          <div className="col-lg-5">
            <div className="add_product_right">
              <p className="add_product">Product images</p>
              <div className="d-flex justify-content-center align-items-center w-100 mt-2">
                <label htmlFor="dropzone-file" className="d-flex flex-column justify-content-center align-items-center w-100 h-64 border border-secondary border-dashed  cursor-pointer bg-light hover-border-secondary" style={{ height: "16rem", cursor: "pointer", borderRadius: "15px" }}>
                  <div className="d-flex flex-column justify-content-center align-items-center py-3">
                    <svg className="mb-3" style={{ width: "2rem", height: "2rem", color: "#6c757d" }} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                    </svg>
                    <p className="mb-2 text-secondary">
                      <strong>Click to upload</strong>
                    </p>
                    <p className="text-muted small">PNG , JPG , JPEG , webp</p>
                    {fileName && (
                      <p className="mt-2 text-secondary">
                        <strong>Uploaded file : </strong>
                        <strong className="text-danger fw-bold">{fileName}</strong>
                      </p>
                    )}
                  </div>
                  <input id="dropzone-file" type="file" className="d-none" accept="image/jpeg,image/png,image/webp" onChange={(e) => handleImageUpload(e)} />
                </label>
              </div>
              <p className="text-info fw-bold text-start">Note: Upload an image with a resolution of 250px x 260px to ensure optimal rendering</p>
              {fileName === "" && validation?.image?.message && (
                <p className="error_message text-start">
                  {`Product image ${validation?.image?.message}`}
                </p>
              )}
                          </div>

            {fileName !== "" && (
              <div className="product_add_edit_image d-flex flex-column justify-content-center align-items-center w-100 h-64 border border-secondary border-dashed  cursor-pointer bg-light hover-border-secondary" style={{ height: "16rem", cursor: "pointer", borderRadius: "15px", marginTop: "20px" }}>
                {isEditMode ? <img src={`${imageBaseUrl + EditproductDetails?.image}`} alt={EditproductDetails?.image} className="mx-auto" />
                  : <img src={`${imageBaseUrl + productDetails?.image}`} alt={productDetails?.image} className="mx-auto" />}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddProduct;
