import React, { useState } from 'react';
import Line from '../assets/images/decorative-line-divider-design_23-2151358267.jpg'
import OtpInput from 'react-otp-input';
import { AdminLogin } from '../common/Api';
import { errorMsg, successMsg } from '../common/Toastify';
import { EmailValidation, PasswordValidation } from '../common/validate';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { loginSuccess } from '../store/slice/loginSlice';


const Loginpage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [activelink, setActivelink] = useState('login');
    const [otp, setOtp] = useState('');

    const [userData, setUserData] = useState({})
    const [validation, setValidation] = useState({})
    const handleChange = (value, key) => {
        setUserData({ ...userData, [key]: value })
        if (validation[key]) setValidation({ ...validation, [key]: false })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        let validate = {}
        validate.email_id = EmailValidation(userData?.email_id, 'Email id')
        validate.password = PasswordValidation(userData?.password, 'Password')
        setValidation(validate)
        const validation_status = Object.values(validate).every((v) => v.status === true)
        if (validation_status) {
            let payload = {
                email_id: userData?.email_id,
                password: userData?.password
            }
            const response = await AdminLogin(payload);
            if (response && response?.statusCode === 200) {
                navigate("/admin/add-product")
                let loginSlice_obj = response?.data;
                loginSlice_obj = { ...response?.data, isLoggedIn: true }
                dispatch(loginSuccess(loginSlice_obj))
                successMsg('Login Successfully')
            }
            else {
                errorMsg(response?.message)
            }
        }

    }

    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className='container-fluid p-0'>
            <div className='login-image align-content-center'>
                <div className='row justify-content-end'>
                    {activelink === "login" && (
                        <div className='col-lg-6'>
                            <div className='login-card'>
                                <div className="card-body">
                                    <h3 className="login-title">Log In</h3>
                                    <div className="form-group">
                                        <label className="login-icon-input">
                                            <i class="fa-solid fa-envelope"></i>
                                            <input
                                                type="text"
                                                placeholder="Email"
                                                value={userData?.email_id}
                                                onChange={(e) => handleChange(e.target.value, "email_id")}
                                            />

                                        </label>
                                        {validation?.email_id?.message && <p className="error_message text-start">{`Email id ${validation?.email_id?.message}` || ""}</p>}

                        
                                    </div>
                                    <div className="loginform-group">
                                        <label className="login-icon-input">
                                            <i className="fa-solid fa-lock"></i>
                                            <input
                                                type={showPassword ? "text" : "password"}
                                                placeholder="Password"
                                                value={userData?.password}
                                                onChange={(e) => handleChange(e.target.value, "password")}
                                            />
                                            <i
                                                className={`fa-solid ${showPassword ? " fa-eye" : "fa-eye-slash"} password-toggle`}
                                                onClick={togglePasswordVisibility}
                                                style={{ cursor: "pointer", marginLeft: "10px" }}
                                                title={showPassword ? "Hide password" : "Show password"}
                                            ></i>
                                        </label>
                                        {validation?.password?.message && (
                                            <p className="error_message text-start">
                                                {`Password ${validation?.password?.message}` || ""}
                                            </p>
                                        )}
                                    </div>

                                    <div className='button-res d-flex'>
                                        <button className='login-button' onClick={handleSubmit}>Log In</button>
                                    </div>
                                    <img className='log-lin mt-3' src={Line} alt="Great Moghul Biryani Logo" style={{ width: "340px" }} />
                                    {/* <p className='forgot-link' onClick={() => setActivelink('forgot-password')}>Forgot Password?</p> */}
                                </div>
                            </div>
                        </div>
                    )}
                    {activelink === "forgot-password" && (
                        <div className='col-lg-6'>
                            <div className='forgot-card'>
                                <div className="card-body">
                                    <h3 className="login-title">Forgot Password</h3>
                                    <div className="form-group mt-5">
                                        <label className="login-icon-input">
                                            <i class="fa-solid fa-envelope"></i>
                                            <input
                                                type="text"
                                                placeholder="Email"
                                            />
                                        </label>
                                    </div>
                                    <div className='button-res pt-0'>
                                        <button className='login-button mt-4' onClick={() => setActivelink('OTP')}>Get OTP</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {activelink === "OTP" && (
                        <div className='col-lg-6'>
                            <div className='otp-card'>
                                <div className="card-body">
                                    <h3 className="login-title">verify OTP</h3>
                                    <div className="form-group">
                                        <OtpInput
                                            value={otp}
                                            onChange={setOtp}
                                            numInputs={4}
                                            renderInput={(props) => <input {...props} className="loginotp-input" />}
                                        />
                                    </div>
                                    <div className='button-res'>
                                        <button className='login-button' onClick={() => setActivelink('change-password')}>Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {activelink === "change-password" && (
                        <div className='col-lg-6'>
                            <div className='changelogin-card'>
                                <div className="card-body">
                                    <h3 className="login-title">Change Password</h3>
                                    <div className="form-group">
                                        <label className="login-icon-input">
                                            <i class="fa-solid fa-lock"></i>
                                            <input
                                                type="text"
                                                placeholder="New Password"
                                            />
                                        </label>
                                    </div>
                                    <div className="loginform-group">
                                        <label className="login-icon-input">
                                            <i class="fa-solid fa-lock"></i>
                                            <input
                                                type="text"
                                                placeholder="Confirm Password"
                                            />
                                        </label>
                                    </div>
                                    <div className='button-res'>
                                        <button className='login-button'>Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Loginpage
