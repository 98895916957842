import axios from "axios";
import { url, token } from "./BaseUrl";
import ApiInstance from "./ApiInterceptor";
import { logout } from "../store/slice/loginSlice";
import { store } from "../store/store";
const ApiBaseUrl = url + "/api/v1";

const makeRequest = async (method, url, payload = {}) => {
  try {
    const response = await ApiInstance({
      method,
      url,
      headers: {
        "Content-Type": "application/json",
      },
      responseType: url.includes("invoicedownload") ? "blob" : null,
      data: method !== "get" ? payload : null,
      params: method === "get" ? payload : null,
    });
    return response.data;
  } catch (error) {
    return { error: error };
  }
};

export const ImageUpload = async (Payload) => {
  const response = await axios
    .post(`${ApiBaseUrl}/admin/imageupload`, Payload, {
      headers: {
        Accept: "image/json",
        "Content-Type": "image/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const getCategory = async (category_type) => {
  return makeRequest("get", `/user/category/listing?category_type=${category_type}`);
};

export const getCmsContent = async (cms_document_type) => {
  return makeRequest("get", `/cms/getbyAll?cms_document_type=${cms_document_type}`);
};
export const UpdateCmsContent = async (payload) => {
  return makeRequest("post", `/cms/create`, payload);
};

// admin panel add category
export const AddCategory = async () => {
  return makeRequest("post", `/category/create`);
};
// Admin Login
export const AdminLogin = async (payload) => {
  return makeRequest("post", `/admin/adminlogin`, payload);
};
export const UserSignup = async (payload) => {
  return makeRequest("post", `/user/signup`, payload);
};
export const UserLogin = async (payload) => {
  return makeRequest("post", `/user/login`, payload);
};
export const UserLoginVerifyOtp = async (payload) => {
  return makeRequest("post", `/user/login/verifyotp`, payload);
};
export const GetProductByCategory = async (category_id) => {
  if (category_id !== "") {
    return makeRequest("get", `/user/product/getproductbycategory?category_id=${category_id}`);
  }
  return makeRequest("get", `/user/product/getproductbycategory`);
};
export const AddTocart = async (payload) => {
  return makeRequest("post", `/order/addtocart`, payload);
};
export const GetCardProducts = async (user_id) => {
  return makeRequest("get", `/order/getcartsdetail?user_id=${user_id}`);
};

export const SaveDeliveryAddress = async (payload) => {
  return makeRequest("post", `/user/address/createdeliveryaddress`, payload);
};
export const GetDeliveryAddress = async (user_id) => {
  return makeRequest("get", `/user/address/getalldeliveryaddress?user_id=${user_id}`);
};
export const UpdateDeliveryAddress = async (payload) => {
  return makeRequest("put", `/user/address/updatedeliveryaddress`, payload);
};
export const DeleteDeliveryAddress = async (_id) => {
  return makeRequest("delete", `/user/address/deletedeliveryaddress`, _id);
};

export const SignUp_VerifyOtp = async (payload) => {
  return makeRequest("post", `/user/signup/verifyotp`, payload);
};

export const CreateOrder = async (payload) => {
  return makeRequest("post", `/user/order/create`, payload);
};

export const UserSubcribe = async (payload) => {
  return makeRequest("post", `/user/subcribe`, payload);
};

// *************************************** Admin Panel Api **********************************

export const AdminAddCategory = async (payload) => {
  return makeRequest("post", `/admin/category/create`, payload);
};

export const AdminGetCategory = async () => {
  return makeRequest("get", `/admin/category/getbyAll`, {});
};

export const AdminUpdateCategory = async (payload) => {
  return makeRequest("put", `/admin/category/update`, payload);
};

export const AdminCreateProduct = async (payload) => {
  return makeRequest("post", `/admin/product/create`, payload);
};

export const AdminGetAllProducts = async () => {
  return makeRequest("get", `/admin/product/getallproduct`, {});
};

export const AdminUpdateProducts = async (payload) => {
  return makeRequest("put", `/admin/product/update`, payload);
};

export const AdminDeleteProducts = async (payload) => {
  return makeRequest("delete", `/admin/product/delete`, payload);
};

export const AdminOrderListing = async (order_status) => {
  if (order_status !== "") {
    return makeRequest("get", `/admin/order/listing?order_status=${order_status}`, {});
  } else {
    return makeRequest("get", `/admin/order/listing`, {});
  }
};
export const AdminUserListing = async () => {
  return makeRequest("get", `/admin/userlisting`, {});
};
export const SubscribersUserListing = async () => {
  return makeRequest("get", `/admin/subcriberslist`, {});
};
export const AdminUpdateUser = async (payload) => {
  return makeRequest("put", `/admin/userupdate`, payload);
};
export const UserOrderHistory = async (user_id) => {
  return makeRequest("get", `/user/orderhistory?_id=${user_id}`, {});
};
export const AdminUpdateOrderStatus = async (payload) => {
  return makeRequest("put", `/admin/order/statusupdate`, payload);
};

export const AdminGetAppConfig = async () => {
  return makeRequest("get", `/admin/app_configuration/findappconfig`, {});
};

export const AdminUpdateAppConfig = async (payload) => {
  return makeRequest("put", `/admin/app_configuration/update`, payload);
};

export const UserDwonloadInvoice = async (payload) => {
  return makeRequest("post", `/user/invoicedownload`, payload);
};

export const AdminProfileData = async (user_id) => {
  return makeRequest("get", `/admin/findparticular?_id=${user_id}`);
};

export const AdminChangePassword = async (payload) => {
  return makeRequest("put", `/admin/profile/changepassword`, payload);
};
export const AdminDeleteAccount = async (payload) => {
  return makeRequest("delete", `/admin/deleteaccount`, payload);
};

export const AdminUpdateProfile = async (payload) => {
  return makeRequest("put", `/admin/profile/updateprofile`, payload);
};

export const AdminListing = async () => {
  return makeRequest("get", `/admin/findall`, {});
};

export const AddNewAdmin = async (payload) => {
  return makeRequest("post", `/admin/createaccount`, payload);
};
export const AddEditOrderDetails = async (payload) => {
  return makeRequest("put", `/admin/order/editorder`, payload);
};
export const UserOrderFindParticular = async (_id) => {
  return makeRequest("get", `/user/product/findparticular?_id=${_id}`, {});
};