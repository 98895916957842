import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import logo from "../assets/images/gmb_logo.png";
import { useDispatch, useSelector } from "react-redux";
import CustomModal from "../common/CustomModel";
import { logout } from "../store/slice/loginSlice";
import { clearCart } from "../store/slice/cartSlice";
import { AdminGetAppConfig } from "../common/Api";
import { imageBaseUrl } from "../common/BaseUrl";
import { setAppConfig } from "../store/slice/appConfigSlice";

function Navbar() {
  const { isLoggedIn } = useSelector((state) => state?.login);
  const { items, overallqty } = useSelector((state) => state.cart);
  const configData = useSelector((state) => state.appconfig);
  // console.log(configData, "configData---configData")
  const [logoutModel, setLogout] = useState(false);
  const [configLoaded, setConfigLoaded] = useState(false);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(logout());
    dispatch(clearCart());
    navigate("/")
    setLogout(false);
  };

  const NavMenus = [
    { path: "/", title: "Home" },
    { path: "/menu", title: "Menu" },
  ];

  const delivery_timelimit = configData?.delivery_timelimit;

  const currentTime = new Date();

  let deliveryTimeLimit;
  if (delivery_timelimit) {
    const [hours, minutes] = delivery_timelimit.split(":").map(Number);
    deliveryTimeLimit = new Date();
    deliveryTimeLimit.setHours(hours, minutes, 0, 0);
  }

  const shouldDisplayMarquee = delivery_timelimit && currentTime > deliveryTimeLimit;


  const GetAppConfig = async () => {
    const response = await AdminGetAppConfig();
    if (response && response?.statusCode === 200) {
      dispatch(setAppConfig(response?.data[0]));
      setConfigLoaded(true)
    }
  };

  useEffect(() => {
    if (!configLoaded) {
      GetAppConfig();
    }
  }, [configLoaded]);
  // console.log(configLoaded, "configLoaded--configLoaded")
  return (
    <div>
      {shouldDisplayMarquee && (
        <div className="nav-scroll">
          {/* <marquee behavior="scroll" direction="left">
      Our Delivery is from 10am to 4pm. Any queries, please reach out - +91 9677055551
    </marquee> */}
          <div class="marquee-container">
            <div class="marquee-content">
              Our Delivery is from 10am to 4pm. Any queries, please reach out - {configData?.phone_number?.value}
            </div>
          </div>
        </div>
      )}
      <div className="">
        <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top shadow-sm" style={{ marginTop: shouldDisplayMarquee ? "35px" : "0px" }}>
          <div className="container">
            <a className="navbar-brand" href="/">
              <img src={`${imageBaseUrl + configData?.logo}`} alt="Great Moghul Biryani Logo" className="logo" />
            </a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
                {NavMenus.map((nav, index) => {
                  return (
                    <li className="nav-item" key={index}>

                      <NavLink className={({ isActive }) => (isActive ? "nav-link-active" : "nav-link")} to={nav.path}>
                        <p className="navbar_menus"> {nav.title}</p>
                      </NavLink>
                    </li>
                  );
                })}
              </ul>
              <ul className="navbar-nav">
                <li className="nav-item me-3">
                  <Link to="/menu" className="nav-link position-relative">
                    <i className="fa-solid fa-cart-shopping"></i>
                    {items?.length > 0 && <span className="cart-count">{overallqty}</span>}
                  </Link>
                </li>
                {!isLoggedIn && (
                  <Link className="dropdown-item" to="/checkout" >
                    <p className="navbar_menus"> Sign In / Log In</p>
                  </Link>
                )}
                {isLoggedIn && (
                  <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <i class="fa-regular fa-user-circle profile_logo" style={{ fontSize: "23px" }}></i>
                      {/* profile */}
                    </a>
                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                      <li>
                        <Link className="dropdown-item" to="/user/order-history">
                          Order History
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/checkout" state={{ activeTab: 1 }}>
                          Manage Address
                        </Link>
                      </li>
                      <li onClick={() => { setLogout(true); }} className="dropdown-item">
                        Logout <i class="fa-solid fa-right-from-bracket ms-2"></i>
                      </li>
                    </ul>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </nav>
        <CustomModal open={logoutModel}>
          <div className=" p-5">
            <p className="text-center mb-4 text-black delete_para_model">Are you sure do you want to Logout ?</p>
            <div className="row">
              <div className="col-6">
                <button className="px-5" onClick={() => handleLogout()}>
                  Yes
                </button>
              </div>
              <div className="col-6">
                <button className="px-5 ms-5" onClick={() => setLogout(false)} style={{ backgroundColor: "red" }}>
                  No
                </button>
              </div>
            </div>
          </div>
        </CustomModal>
      </div>
    </div>
  );
}

export default Navbar;
