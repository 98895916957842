import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbarcontainer";
import Footer from "./Footerpage";
import { getCmsContent } from "../common/Api";
import { useLocation } from "react-router-dom";

function Termsandcondition({ cms_document_type, title }) {
  const [cmscontent, setCmsContent] = useState();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const get_Cms_Content = async () => {
    const response = await getCmsContent(cms_document_type);
    if (response && response?.statusCode === 200) {
      setCmsContent(response?.data);
    }
  };

  useEffect(() => {
    get_Cms_Content();
    window.scrollTo(0, 0);
    function hideError(e) {
      if (e.message === "ResizeObserver loop completed with undelivered notifications.") {
        const resizeObserverErrDiv = document.getElementById("webpack-dev-server-client-overlay-div");
        const resizeObserverErr = document.getElementById("webpack-dev-server-client-overlay");
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }
    }

    window.addEventListener("error", hideError);
    return () => {
      window.addEventListener("error", hideError);
    };
  }, [cms_document_type]);

  return (
    <div>
      <Navbar />
      <div>
        <div className="container-fluid p-0">
          <div>
            <div className="text-center menu-image">
              <h1 className="terms-header">{title}</h1>
            </div>
            <div className="footer_page_parent_class">
              <p className="paragraph" dangerouslySetInnerHTML={{ __html: cmscontent?.content }}></p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Termsandcondition;
