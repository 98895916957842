const EmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const NumberRegex = /^[0-9]/;

export function NonEmptyValidation(value) {
    if (!value) {
        return { status: false, message: "is required" };
    }
    return value.length < 2
        ? { status: false, message: "cannot be less than two character" }
        : { status: true, message: "" };
}
export function NonEmptyId(value) {
    if (!value) {
        return { status: false, message: "is required" };
    }
    return { status: true, message: "" };
}
export function EmailValidation(value, key) {
    if (!value?.trim()?.length) {
        return { status: false, message: `is required` };
    } else if (value?.includes(" ")) {
        return { status: false, message: `cannot be include space` };
    }
    return !EmailRegex.test(value?.trim())
        ? { status: false, message: `is invalid` }
        : { status: true, message: "" };
}
export function EmailPhoneValidation(value, key) {
    if (!value?.trim()?.length) {
        return { status: false, message: `${key} is required` };
    }
    if (value.includes(" ")) {
        return { status: false, message: `${key} cannot include spaces` };
    }
    if (/^\d+$/.test(value)) {
        if (value.length !== 10) {
            return { status: false, message: `${key} must be 10 digits` };
        }
        if (!/^[6789]\d{9}$/.test(value)) {
            return { status: false, message: `${key} must start with 6, 7, 8, or 9` };
        }
        return { status: true, message: "" };
    }
    if (!EmailRegex.test(value.trim())) {
        return { status: false, message: `${key} is invalid` };
    }
    return { status: true, message: "" };
}
export function PasswordValidation(value) {
    if (!value || value.length === 0) {
        return { status: false, message: "is required" };
    }
    const lowerCaseRegex = /[a-z]/;
    const upperCaseRegex = /[A-Z]/;
    const digitRegex = /\d/;
    const specialCharRegex = /[.,:;'!@#$%^&*_+=|(){}[\]\\-]/;

    const hasLowerCase = lowerCaseRegex.test(value);
    const hasUpperCase = upperCaseRegex.test(value);
    const hasDigit = digitRegex.test(value);
    const hasSpecialChar = specialCharRegex.test(value);
    const isAtLeastEightChars = value?.length >= 8;

    if (value?.length === 0) {
        return { status: false, message: "is required" };
    }
    if (!isAtLeastEightChars) {
        return { status: false, message: "must be at least 8 characters long" };
    }
    if (!hasLowerCase) {
        return { status: false, message: "must contain at least 1 lowercase letter" };
    }
    if (!hasUpperCase) {
        return { status: false, message: "must contain at least 1 uppercase letter" };
    }
    if (!hasDigit) {
        return { status: false, message: "must contain at least 1 number" };
    }
    if (!hasSpecialChar) {
        return { status: false, message: "must contain at least 1 special character" };
    }
    return { status: true, message: "" };
}
export function OtpValidation(value) {
    if (value === undefined) {
        return { status: false, message: "is required" };
    } else if (value?.length !== 4) {
        return { status: false, message: "must be a 4 digits" };
    } else if (!value) {
        return { status: false, message: "is required" };
    }
    return !NumberRegex.test(value)
        ? { status: false, message: "is only a number" }
        : { status: true, message: "" };
}
export function PincodeValidation(value) {
    if (value === "") {
        return { status: false, message: "is required" };
    } else if (value?.length !== 6) {
        return { status: false, message: "must be a 6 digits" };
    } else if (!value) {
        return { status: false, message: "is required" };
    }
    return !NumberRegex.test(value)
        ? { status: false, message: "is only a number" }
        : { status: true, message: "" };
}
export function PriceValidation(value) {
    if (value === undefined || value === null || value === "") {
        return { status: false, message: "is required" };
    }
    if (isNaN(value)) {
        return { status: false, message: "must be a valid number." };
    }
    if (value <= 0) {
        return { status: false, message: "must be greater than zero." };
    }
    return { status: true, message: "" };
}
export function PhoneNumberValidation(value) {
    if (value?.length === 0 || !value) {
        return { status: false, message: "is required" };
    } else if (value?.length !== 10) {
        return { status: false, message: "must be a 10 digits" };
    } else if (!/^[6789]\d{9}$/.test(value)) {
        return { status: false, message: "must start with 6, 7, 8, or 9" };
    }
    return !NumberRegex.test(value)
        ? { status: false, message: "is only a number" }
        : { status: true, message: "" };
}