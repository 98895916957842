import React from 'react'

const UserDetail = () => {
    return (
        <div>
            <h3 className='admin_head'>User Detail Information</h3>
            <div className='mt-5'>
                <div className='row'>
                    <div className='col-lg-6'>
                        <div className='add_product_left'>
                            {/* <p className='add_product'></p> */}
                            <div className='d-flex flex-column'>
                                <label className='add_product_label'>
                                    Customer Name
                                </label>
                                <input className='add_product_input' />
                            </div>
                            <div className='d-flex flex-column mt-3'>
                                <label className='add_product_label'>
                                    Phone Nunmber
                                </label>
                                <input className='add_product_input' />
                            </div>
                            <div className='d-flex flex-column mt-3'>
                                <label className='add_product_label'>
                                    Street and house number
                                </label>
                                <input className='add_product_input ' />
                            </div>
                            <div className='d-flex flex-column mt-3'>
                                <label className='add_product_label'>
                                    Company Name
                                </label>
                                <input className='add_product_input' />
                            </div>
                            <div className='d-flex flex-column mt-3'>
                                <label className='add_product_label'>
                                    Zip Code
                                </label>
                                <input className='add_product_input' />
                            </div>

                            <div className='d-flex flex-column mt-4'>
                                <label className='add_product_label'>
                                    Address
                                </label>
                                <textarea className='address_textarea' />
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-1'></div>
                    <div className='col-lg-5'>
                        <div className='add_product_right'>
                            <p className='add_product'>Order History</p>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default UserDetail
