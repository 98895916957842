import { createSlice } from "@reduxjs/toolkit";

const loginSlice = createSlice({
  name: "login",
  initialState: {
    isLoggedIn: false,
    userType: "",
    token: "",
    email_id: "",
    phone_number: "",
    _id: "",
    name: "",
  },
  reducers: {
    loginSuccess: (state, action) => {
      const userDetails = action.payload;
      state.userType = userDetails?.user_type || state.userType;
      state.token = userDetails?.access_token || state.token;
      state.name = userDetails?.name || state.name;
      state.phone_number = userDetails?.phone_number || state.phone_number;
      state.email_id = userDetails?.email_id || state.email_id;
      state._id = userDetails?._id || state._id;
      state.isLoggedIn = userDetails?.isLoggedIn !== undefined ? userDetails?.isLoggedIn : state.isLoggedIn;
    },
    logout: () => {
      return {
        isLoggedIn: false,
        userType: "",
        token: "",
        email_id: "",
        phone_number: "",
        _id: "",
      };
    },
    otpVerified: (state) => {
      state.isLoggedIn = true;
    },
  },
});

export const { loginSuccess, logout, otpVerified } = loginSlice.actions;
export const selectToken = (state) => state?.login?.token;
export default loginSlice.reducer;
