import React, { useState, useEffect, useRef } from "react";
import logo from '../assets/images/gmb_logo.png';
import food1 from '../assets/images/user_3237447.png';
import { logout } from "../store/slice/loginSlice";
import { useDispatch, useSelector } from "react-redux";
import CustomModal from "../common/CustomModel";
import { useNavigate } from "react-router-dom";
import { imageBaseUrl } from "../common/BaseUrl";


const Header = () => {
    const [isOpen, setIsOpen] = useState(false);
    // const configData = useSelector((state) => state.appconfig);
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const navigate = useNavigate();
    const [logoutModel, setLogout] = useState(false)
    const dispatch = useDispatch()
    const handleLogout = () => {
        dispatch(logout())
        navigate("/adminlogin")
        setLogout(false)
    }
    const handleNavigation = (tab) => {
        setIsOpen(false);
        navigate(`/admin/change-password#${tab}`);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };
    const dropdownRef = useRef(null);
    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div>
            <img className='admin-logo'
                // src={`${imageBaseUrl + configData?.logo}`} 
                src={logo}
                alt="Great Moghul Biryani" />
            <div className='admin_header'>
                <div className="custom-dropdown" ref={dropdownRef}>
                    <div className="dropdown-trigger" onClick={toggleDropdown}>
                    <i class="fa-regular fa-user-circle profile_logo" style={{ fontSize: "25px" }}></i>
                    </div>
                    {isOpen && (
                        <ul className="dropdown-menu1">
                        <li>
                            <a onClick={() => handleNavigation("profile")}>
                                <i className="fa-solid fa-user"></i><span style={{marginLeft:"10px"}}> My Profile </span>
                            </a>
                        </li>
                        <li>
                            <a onClick={() => handleNavigation("password")}>
                                <i className="fa-solid fa-key"></i><span style={{marginLeft:"10px"}}> Change Password </span>
                            </a>
                        </li>
                        {/* <li>
                            <a href="/admin/order">
                                <i className="fa-solid fa-box"></i><span style={{marginLeft:"10px"}}> Order History </span>
                            </a>
                        </li> */}
                        <li>
                            <a href="#logout" onClick={() => setLogout(true)}>
                                <i className="fa-solid fa-right-from-bracket"></i><span style={{marginLeft:"10px"}}> Logout </span>
                            </a>
                        </li>
                    </ul>                   
                    )}
                </div>

            </div>
            <CustomModal
                open={logoutModel}>
                <div className=" p-5">
                    <p className="text-center mb-4 text-black delete_para_model">
                        Are you sure do you want to Logout ?
                    </p>
                    <div className="row">
                        <div className="col-6">
                            <button className='px-5' onClick={() => handleLogout()}>Yes</button>
                        </div>
                        <div className="col-6">
                            <button className='px-5 ms-5' onClick={() => setLogout(false)}
                                style={{ backgroundColor: "red" }}>No</button>
                        </div>
                    </div>
                </div>
            </CustomModal>
        </div>
    )
}

export default Header
