import AddProduct from "../featured/protected/AddProduct";
import Category from "../featured/protected/Category";
import OrderDetails from "../featured/protected/OrderDetails";
import Orders from "../featured/protected/Orders";
import Product from "../featured/protected/Product";
import UserDetail from "../featured/protected/UserDetail";
import Users from "../featured/protected/Users";
import Reviews from "../featured/protected/Reviews";
import EditProduct from "../featured/protected/EditProduct";
import AppConfiguration from "../featured/protected/appconfig/AppConfiguration";
import UserOrders from "../featured/protected/UserOrders";
import Cms from "../featured/protected/cms/Cms";
import Admin from "../featured/protected/Admin";
import ChangePwd from "../components/Changepwd";
import CMSEditPage from "../components/CMSEditPage";
import Subcribers from "../featured/protected/Subcribers"

export const routes = [
  {
    path: "/admin/category",
    component: Category,
  },
  {
    path: "/admin/product",
    component: Product,
  },
  {
    path: "/admin/add-product",
    component: AddProduct,
  },
  {
    path: "/admin/edit-product",
    component: EditProduct,
  },
  {
    path: "/admin/order",
    component: Orders,
  },
  {
    path: "/admin/order-detail",
    component: OrderDetails,
  },
  {
    path: "/admin/users",
    component: Users,
  },
  {
    path: "/admin/reviews",
    component: Reviews,
  },
  {
    path: "/admin/users-detail",
    component: UserDetail,
  },
  {
    path: "/admin/app-configuration",
    component: AppConfiguration,
  },
  {
    path: "/admin/userorders",
    component: UserOrders,
  },
  {
    path: "/admin/cms",
    component: Cms,
  },
  {
    path: "/admin/admin",
    component: Admin,
  },
  {
    path: "/admin/change-password",
    component: ChangePwd,
  },
  {
    path: "/admin/subscribers",
    component: Subcribers,
  },
  { path: "/admin/terms-and-condition", component: <CMSEditPage cms_document_type="Terms_and_condition" title="Terms & Conditions" /> },
  { path: "/admin/refund-and-cancellation", component: <CMSEditPage cms_document_type="Refund_cancellation" title="Refund and Cancellation" /> },
  { path: "/admin/privacy-policy", component: <CMSEditPage cms_document_type="Privacy_policy" title="Privacy Policy" /> },
];
