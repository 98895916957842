import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import food1 from '../../assets/images/food2.png';
import OrderDetails from './OrderDetails';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { AdminOrderListing, AdminUpdateOrderStatus, UserDwonloadInvoice } from '../../common/Api';
import { errorMsg, successMsg } from '../../common/Toastify';
import CustomModal from '../../common/CustomModel';

const Orders = () => {
    const navigate = useNavigate()
    const [activeTab, setActiveTab] = useState("ordered");
    const [ordersListing, setOrdersListing] = useState([]);
    const [activeModel, setActiveModel] = useState(false);
    const [declineReason, setDeclineReason] = useState("");
    const [selectedOrderId, setSelectedOrderId] = useState(null);
    const [declineReasonvalidation, setDeclineReasonvalidation] = useState(false);

    const order_tabs = [
        {
            label: "New Orders",
            key: "ordered"
        },
        {
            label: "Accepted Orders",
            key: "accepted"
        },
        {
            label: "Out For Delivery",
            key: "out-for-delivery"
        },
        {
            label: "Completed Orders",
            key: "delivered"
        },
        {
            label: "Declined Orders",
            key: "decline"
        },
    ]
    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
    };

    const GetOrders = async () => {
        const response = await AdminOrderListing(activeTab)
        if (response && response?.statusCode === 200) {
            setOrdersListing(response?.data)
        }
    }
    const handleViewProductDetails = (item) => {
        // setDeleteProduct(item)
        navigate("/admin/order-detail", {
            state: { OrderProductDetails: item },
        });
    }
    const UpdateOrderStatus = async (status, order_id, decline_reason) => {
        let payload;
        if (status === "decline" && decline_reason === "") {
            setDeclineReasonvalidation(true)
            return
        }
        else if (status === "decline" && decline_reason !== "") {
            setActiveModel(false);
            setDeclineReasonvalidation(false)
            setDeclineReason("");
            payload = {
                order_status: status,
                _id: order_id,
                order_decline_reason: decline_reason
            }
        }
        else {
            payload = {
                order_status: status,
                _id: order_id,
            }
        }

        const response = await AdminUpdateOrderStatus(payload);
        if (response && response?.statusCode === 200) {
            GetOrders()
            setActiveTab(status);
            successMsg(`Order Updated Successfully`);
        } else {
            errorMsg(response?.message);
        }
    }
    useEffect(() => {
        GetOrders()
        setOrdersListing({})
    }, [activeTab]);

    const downloadInvoice = async (order_id, order_no) => {
        let payload = { _id: order_id };
        try {
            const response = await UserDwonloadInvoice(payload);
            const blob = new Blob([response], { type: "application/pdf" });
            const url = URL.createObjectURL(blob);
            // window.open(url);
            const link = document.createElement('a');
            link.href = url;

            link.setAttribute('download', `invoice_${order_no}.pdf`);
            document.body.appendChild(link);
            link.click();
            link.remove();

        } catch (error) {
            console.error("Error during invoice download:", error);
        }
    };

    return (
        <div>
            <h3 className="text-center mb-4 fw-bold ">Orders</h3>
            <ul className="ordertabs nav nav-tabs d-flex w-100" role="tablist">
                {order_tabs.map(
                    (tab, index) => (
                        <li className="nav-item flex-fill col-2" key={index}>
                            <button
                                className={`nav-link w-100 ${activeTab === tab?.key ? 'active' : ''}`}
                                onClick={() => handleTabClick(tab?.key)}
                            >
                                {tab?.label}
                            </button>
                        </li>
                    )
                )}
            </ul>

            <div className="tab-content mt-4">
                {activeTab === "ordered" && (
                    <>
                        {ordersListing?.length > 0 ? (
                            ordersListing.map((item, index) => (
                                <div className="tab-pane fade show active" key={index}>
                                    <div className="historycard p-3">
                                        <div className="d-flex justify-content-between mt-3">
                                            <div className='col d-flex flex-column '>
                                                <div className="card-header g-col-6 d-flex justify-content-between mt-3">
                                                    <span>Order# : {item?.order_id}</span>
                                                    <div className="text-danger delivered-date g-col-6 my-auto">Order Received !!</div>
                                                </div>

                                                <div className='d-flex row'>
                                                    <div className='col-3'>
                                                        <div className="  d-flex  delivered-date justify-content-center">
                                                            <div className="item-info position-relative">
                                                                {/* Blurred Background Image */}
                                                                <div
                                                                    className="blurred-background"
                                                                    style={{
                                                                        backgroundImage: `url(${food1})`,
                                                                        backgroundSize: "cover",
                                                                        backgroundPosition: "center",
                                                                        filter: "blur(8px)",
                                                                    }}
                                                                ></div>
                                                                {/* Foreground Image */}
                                                                <img
                                                                    src={food1}
                                                                    alt="food1"
                                                                    className="foreground-image"
                                                                    style={{ width: "80px", marginRight: "10px" }}
                                                                />

                                                                {/* Total Items Overlay (conditionally rendered) */}
                                                                {item?.Products.length > 1 && (
                                                                    <div className="item-quantity-overlay">
                                                                        <span>{item?.Products.length} Items</span>
                                                                    </div>
                                                                )}
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className='col-9 justify-content-center'>
                                                        {/* <div className="d-flex my-2  ">

                                                <div>
                                                    {items?.map((item, idx) => (
                                                        <tr key={idx}>
                                                            <td>
                                                                <div className="item-info">
                                                                    <div className="price-info">
                                                                        <span className="price-name">{item?.name}&nbsp; ({item?.quantity})</span>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}

                                                </div>
                                            </div> */}
                                                        <div className="row m-auto mt-3">
                                                            <div className='d-flex fw-bold'>
                                                                <b>Bill Amt:</b>  &nbsp;
                                                                <div style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>₹ {item?.total_payable_amount}

                                                                </div>
                                                            </div>
                                                            <p className='fw-bold mt-2 mb-0'>Ordered At : {moment(item?.createdAt).format('DD/MM/YYYY hh:mm A')}</p>
                                                            <p className='fw-bold mt-2'>Payment Type : {item?.payment_type === "cash-on-delivery" ? "Cash on Delivery" : "Online Payment"}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='p-2 d-flex flex-column col '>
                                                <button type="button" class="btn border text-white bg-success" onClick={() => { UpdateOrderStatus("accepted", item?._id); }} >Accept Order</button>

                                                <button type="button" class="btn border " onClick={() => handleViewProductDetails(item)}>View Order Details</button>

                                                <button type="button" class="btn border "
                                                    onClick={() => {
                                                        setActiveModel(true);
                                                        setSelectedOrderId(item?._id);
                                                    }}
                                                >Decline</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p className='no_order_display'>No New Orders !!!</p>
                        )}
                    </>
                )}
                {activeTab === "accepted" && (
                    <>
                        {ordersListing?.length > 0 ? (
                            ordersListing.map((item, index) => (
                                <div className="tab-pane fade show active" key={index}>
                                    <div className="historycard p-3">
                                        <div className="d-flex justify-content-between mt-3">
                                            <div className='col d-flex flex-column '>
                                                <div className="card-header g-col-6 d-flex justify-content-between mt-3">
                                                    <span>Order# : {item?.order_id}</span>
                                                    <div className="text-success delivered-date g-col-6 my-auto">Order Accepted !!</div>
                                                </div>

                                                <div className='d-flex row'>
                                                    <div className='col-3'>
                                                        <div className="  d-flex  delivered-date justify-content-center">
                                                            <div className="item-info position-relative">
                                                                {/* Blurred Background Image */}
                                                                <div
                                                                    className="blurred-background"
                                                                    style={{
                                                                        backgroundImage: `url(${food1})`,
                                                                        backgroundSize: "cover",
                                                                        backgroundPosition: "center",
                                                                        filter: "blur(8px)",
                                                                    }}
                                                                ></div>
                                                                {/* Foreground Image */}
                                                                <img
                                                                    src={food1}
                                                                    alt="food1"
                                                                    className="foreground-image"
                                                                    style={{ width: "80px", marginRight: "10px" }}
                                                                />

                                                                {/* Total Items Overlay (conditionally rendered) */}
                                                                {item?.Products.length > 1 && (
                                                                    <div className="item-quantity-overlay">
                                                                        <span>{item?.Products.length} Items</span>
                                                                    </div>
                                                                )}
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className='col-9 justify-content-center'>
                                                        {/* <div className="d-flex my-2  ">

                                             <div>
                                                 {items?.map((item, idx) => (
                                                     <tr key={idx}>
                                                         <td>
                                                             <div className="item-info">
                                                                 <div className="price-info">
                                                                     <span className="price-name">{item?.name}&nbsp; ({item?.quantity})</span>
                                                                 </div>
                                                             </div>
                                                         </td>
                                                     </tr>
                                                 ))}

                                             </div>
                                         </div> */}
                                                        <div className="row m-auto mt-3">
                                                            <div className='d-flex fw-bold'>
                                                                <b>Bill Amt:</b>  &nbsp;
                                                                <div style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>₹ {item?.total_payable_amount}

                                                                </div>
                                                            </div>
                                                            <p className='fw-bold mt-2 mb-0'>Ordered Accepted At : {moment(item?.order_accepted_at).format('DD/MM/YYYY hh:mm A')}</p>
                                                            <p className='fw-bold mt-2'>Payment Type : {item?.payment_type === "cash-on-delivery" ? "Cash on Delivery" : "Online Payment"}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='p-2 d-flex flex-column col '>
                                                <button type="button" class="btn border text-white bg-success" onClick={() => { UpdateOrderStatus("out-for-delivery", item?._id); GetOrders() }} >Out For Delivery</button>
                                                <button type="button" class="btn border " onClick={() => handleViewProductDetails(item)}>View Order Details</button>
                                                {/* <button type="button" class="btn border " onClick={() => navigate('/admin/order-detail')}></button> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p className='no_order_display'>No New Orders !!!</p>
                        )}
                    </>
                )}
                {activeTab === "out-for-delivery" && (
                    <>
                        {ordersListing?.length > 0 ? (
                            ordersListing.map((item, index) => (
                                <div className="tab-pane fade show active" key={index}>
                                    <div className="historycard p-3">
                                        <div className="d-flex justify-content-between mt-3">
                                            <div className='col d-flex flex-column '>
                                                <div className="card-header g-col-6 d-flex justify-content-between mt-3">
                                                    <span>Order# : {item?.order_id}</span>
                                                    <div className="text-warning delivered-date g-col-6 my-auto">Order Out For Delivery</div>
                                                </div>

                                                <div className='d-flex row'>
                                                    <div className='col-3'>
                                                        <div className="  d-flex  delivered-date justify-content-center">
                                                            <div className="item-info position-relative">
                                                                {/* Blurred Background Image */}
                                                                <div
                                                                    className="blurred-background"
                                                                    style={{
                                                                        backgroundImage: `url(${food1})`,
                                                                        backgroundSize: "cover",
                                                                        backgroundPosition: "center",
                                                                        filter: "blur(8px)",
                                                                    }}
                                                                ></div>
                                                                {/* Foreground Image */}
                                                                <img
                                                                    src={food1}
                                                                    alt="food1"
                                                                    className="foreground-image"
                                                                    style={{ width: "80px", marginRight: "10px" }}
                                                                />

                                                                {/* Total Items Overlay (conditionally rendered) */}
                                                                {item?.Products.length > 1 && (
                                                                    <div className="item-quantity-overlay">
                                                                        <span>{item?.Products.length} Items</span>
                                                                    </div>
                                                                )}
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className='col-9 justify-content-center'>
                                                        {/* <div className="d-flex my-2  ">

                                             <div>
                                                 {items?.map((item, idx) => (
                                                     <tr key={idx}>
                                                         <td>
                                                             <div className="item-info">
                                                                 <div className="price-info">
                                                                     <span className="price-name">{item?.name}&nbsp; ({item?.quantity})</span>
                                                                 </div>
                                                             </div>
                                                         </td>
                                                     </tr>
                                                 ))}

                                             </div>
                                         </div> */}
                                                        <div className="row m-auto mt-3">
                                                            <div className='d-flex fw-bold'>
                                                                <b>Bill Amt:</b>  &nbsp;
                                                                <div style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>₹ {item?.total_payable_amount}

                                                                </div>
                                                            </div>
                                                            <p className='fw-bold mt-2 mb-0'>Out For Delivery At : {moment(item?.order_outfordelivery_at).format('DD/MM/YYYY hh:mm A')}</p>
                                                            <p className='fw-bold mt-2'>Payment Type : {item?.payment_type === "cash-on-delivery" ? "Cash on Delivery" : "Online Payment"}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='p-2 d-flex flex-column col '>
                                                <button type="button" class="btn border text-white bg-success" onClick={() => { UpdateOrderStatus("delivered", item?._id); GetOrders() }} >Delivered</button>
                                                <button type="button" class="btn border " onClick={() => handleViewProductDetails(item)}>View Order Details</button>
                                                {/* <button type="button" class="btn border " onClick={() => navigate('/admin/order-detail')}></button> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p className='no_order_display'>No Order Out For Delivery !!!</p>
                        )}
                    </>
                )}
                {activeTab === "delivered" && (
                    <>
                        {ordersListing?.length > 0 ? (
                            ordersListing.map((item, index) => (
                                <div className="tab-pane fade show active" key={index}>
                                    <div className="historycard p-3">
                                        <div className="d-flex justify-content-between mt-3">
                                            <div className='col d-flex flex-column '>
                                                <div className="card-header g-col-6 d-flex justify-content-between mt-3">
                                                    <span>Order# : {item?.order_id}</span>
                                                    <div className="text-success delivered-date g-col-6 my-auto">Order Delivered  !!</div>
                                                </div>

                                                <div className='d-flex row'>
                                                    <div className='col-3'>
                                                        <div className="  d-flex  delivered-date justify-content-center">
                                                            <div className="item-info position-relative">
                                                                {/* Blurred Background Image */}
                                                                <div
                                                                    className="blurred-background"
                                                                    style={{
                                                                        backgroundImage: `url(${food1})`,
                                                                        backgroundSize: "cover",
                                                                        backgroundPosition: "center",
                                                                        filter: "blur(8px)",
                                                                    }}
                                                                ></div>
                                                                {/* Foreground Image */}
                                                                <img
                                                                    src={food1}
                                                                    alt="food1"
                                                                    className="foreground-image"
                                                                    style={{ width: "80px", marginRight: "10px" }}
                                                                />

                                                                {/* Total Items Overlay (conditionally rendered) */}
                                                                {item?.Products.length > 1 && (
                                                                    <div className="item-quantity-overlay">
                                                                        <span>{item?.Products.length} Items</span>
                                                                    </div>
                                                                )}
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className='col-9 justify-content-center'>

                                                        <div className="row m-auto mt-3">
                                                            <div className='d-flex fw-bold'>
                                                                <b>Bill Amt:</b>  &nbsp;
                                                                <div style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>₹ {item?.total_payable_amount}

                                                                </div>
                                                            </div>
                                                            <p className='fw-bold mt-2 mb-0'>Ordered Delivered At : {moment(item?.order_delivered_at).format('DD/MM/YYYY hh:mm A')}</p>
                                                            <p className='fw-bold mt-2'>Payment Type : {item?.payment_type === "cash-on-delivery" ? "Cash on Delivery" : "Online Payment"}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='p-2 d-flex flex-column col '>
                                                <button type="button" class="btn border text-white bg-success" onClick={() => downloadInvoice(item?._id, item.order_id)} >Get Invoice</button>
                                                <button type="button" class="btn border " onClick={() => handleViewProductDetails(item)}>View Order Details</button>
                                                {/* <button type="button" class="btn border " onClick={() => navigate('/admin/order-detail')}></button> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p className='no_order_display'>No Order Delivered !!!</p>
                        )}
                    </>
                )}
                {activeTab === "decline" && (
                    <>
                        {ordersListing?.length > 0 ? (
                            ordersListing.map((item, index) => (
                                <div className="tab-pane fade show active" key={index}>
                                    <div className="historycard p-3">
                                        <div className="d-flex justify-content-between mt-3">
                                            <div className='col d-flex flex-column '>
                                                <div className="card-header g-col-6 d-flex justify-content-between mt-2">
                                                    <span>Order# : {item?.order_id}</span>
                                                    <div className="text-danger delivered-date g-col-6 my-auto">Order Decline  !!</div>
                                                </div>

                                                <div className='d-flex row'>
                                                    <div className='col-3'>
                                                        <div className="  d-flex  delivered-date justify-content-center">
                                                            <div className="item-info position-relative">
                                                                {/* Blurred Background Image */}
                                                                <div
                                                                    className="blurred-background"
                                                                    style={{
                                                                        backgroundImage: `url(${food1})`,
                                                                        backgroundSize: "cover",
                                                                        backgroundPosition: "center",
                                                                        filter: "blur(8px)",
                                                                    }}
                                                                ></div>
                                                                {/* Foreground Image */}
                                                                <img
                                                                    src={food1}
                                                                    alt="food1"
                                                                    className="foreground-image"
                                                                    style={{ width: "80px", marginRight: "10px" }}
                                                                />

                                                                {/* Total Items Overlay (conditionally rendered) */}
                                                                {item?.Products.length > 1 && (
                                                                    <div className="item-quantity-overlay">
                                                                        <span>{item?.Products.length} Items</span>
                                                                    </div>
                                                                )}
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className='col-9 justify-content-center'>
                                                        {/* <div className="d-flex my-2  ">

                                             <div>
                                                 {items?.map((item, idx) => (
                                                     <tr key={idx}>
                                                         <td>
                                                             <div className="item-info">
                                                                 <div className="price-info">
                                                                     <span className="price-name">{item?.name}&nbsp; ({item?.quantity})</span>
                                                                 </div>
                                                             </div>
                                                         </td>
                                                     </tr>
                                                 ))}

                                             </div>
                                         </div> */}
                                                        <div className="row m-auto mt-3">
                                                            <div className='d-flex fw-bold'>
                                                                <b>Bill Amt:</b>  &nbsp;
                                                                <div style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>₹ {item?.total_payable_amount}

                                                                </div>
                                                            </div>
                                                            <p className='fw-bold mt-2 mb-0'>Ordered Decline At : {moment(item?.order_decline_at).format('DD/MM/YYYY hh:mm A')}</p>
                                                            <p className='fw-bold mt-2'>Payment Type : {item?.payment_type === "cash-on-delivery" ? "Cash on Delivery" : "Online Payment"}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='p-2 d-flex flex-column col mt-0'>
                                                <button type="button" class="btn border mt-0" onClick={() => handleViewProductDetails(item)}>View Order Details</button>
                                                {/* <button type="button" class="btn border " onClick={() => navigate('/admin/order-detail')}></button> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p className='no_order_display'>No Order Decline !!!</p>
                        )}
                    </>
                )}
            </div>
            <CustomModal open={activeModel}>
                <div className="logout-modal p-5">
                    <p className="text-center mb-4 text-black delete_para_model">Reason for declining this order?</p>
                    <textarea
                        className="form-control mb-0"
                        rows="4"
                        placeholder="Enter your reason here..."
                        value={declineReason}
                        onChange={(e) => setDeclineReason(e.target.value)}
                    ></textarea>
                    {declineReasonvalidation && <p className="error_message text-start mb-3">Decline reason is required</p>}
                    <div className="row">
                        <div className="col text-center mt-4">
                            <button
                                className="px-5 me-2"
                                style={{ backgroundColor: "red", color: "white" }}
                                onClick={() => setActiveModel(false)}
                            >
                                Close
                            </button>
                            <button
                                className="px-5"
                                style={{ backgroundColor: "green", color: "white" }}
                                onClick={() => {
                                    UpdateOrderStatus("decline",
                                        selectedOrderId,
                                        declineReason);
                                    
                                }}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </CustomModal>
            {/* Modal */}
            {/* {showModal && (
        <div className="delivery-modal-overlay">
          <div className="delivery-modal-content">
            <h5>Delivery Details</h5>
            <input
              type="text"
              placeholder="Delivery Person Name"
              className="delivery-input my-2"
              value={deliveryDetails.name}
              onChange={(e) => setDeliveryDetails({ ...deliveryDetails, name: e.target.value })}
            />
            <input
              type="text"
              placeholder="Contact Number"
              className="delivery-input my-2"
              value={deliveryDetails.contact}
              onChange={(e) => setDeliveryDetails({ ...deliveryDetails, contact: e.target.value })}
            />
            <button
              type="button"
              className="btn btn-primary"
              onClick={confirmDelivery}
            >
              Confirm
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => setShowModal(false)}
            >
              Close
            </button>
          </div>
        </div>
      )} */}
        </div>
    );
};

export default Orders;
