export const menu = [
  {
    path: "/admin/order",
    icon: <i class="fa-solid fa-truck-fast"></i>,
    name: "Orders",
  },
  {
    path: "/admin/app-configuration",
    icon: <i class="fa-solid fa-gears"></i>,
    name: "App Configuration",
  },
  {
    path: "/admin/admin",
    icon: <i class="fa-solid fa-user-shield"></i>,
    name: "Admin",
  },
  {
    path: "/admin/category",
    icon: <i class="fa-solid fa-layer-group"></i>,
    name: "Category",
  },
  {
    path: "/admin/product",
    icon: <i class="fa-solid fa-utensils"></i>,
    name: "Product",
  },
  {
    path: "/admin/users",
    icon: <i class="fa-solid fa-users"></i>,
    name: "Users",
  },
  {
    path: "/admin/subscribers",
    icon: <i class="fa-solid fa-users-viewfinder"></i>,
    name: "Subscribers"
  },
  // {
  //     path: '/admin/reviews',
  //     icon: <i class="fa-solid fa-star"></i>,
  //     name: 'Reviews'
  // },


  {
    path: "",
    icon:<i class="fa-regular fa-pen-to-square"></i>,
    name: "CMS",
    subMenu: [
      {
        path: "/admin/terms-and-condition",
        icon: <i class="fa-solid fa-file-contract"></i>,
        name: "Terms And Condition",
      },
      {
        path: "/admin/refund-and-cancellation",
        icon: <i class="fa-solid fa-money-bill-transfer"></i>,
        name: "Refund And Cancellation",
      },
      {
        path: "/admin/privacy-policy",
        icon: <i class="fa-solid fa-arrow-down-up-lock"></i>,
        name: "Privacy Policy",
      },
    ],
  },

  // {
  //     path: '/admin/others',
  //     icon: <i class="fa-solid fa-earth-americas"></i>,
  //     name: 'Learning'
  // },
];
