import React, { useState, useEffect } from "react";
import { AdminGetAppConfig, AdminUpdateAppConfig } from "../../../common/Api";
import { errorMsg, successMsg } from "../../../common/Toastify";
import { PincodeValidation } from "../../../common/validate";

const PincodeSection = () => {
    const [pincode, setPincode] = useState("");
    const [showAddPincode, setShowAddPincode] = useState(false);
    const [getPincode, setGetPincode] = useState([]);
    const [validation, setValidation] = useState({});
    const [cashOnDelivery, setCashOnDelivery] = useState(null);
    const [deliveryCharge, setDeliveryCharge] = useState(null);
    const [WhatsappPhonenumberId, setWhatsappPhonenumberId] = useState("");
    const [WhatsappAccesstoken, setWhatsappAccesstoken] = useState("");
    const [showAddDeliveryBtn, setShowAddDeliveryBtn] = useState(false);
    const [showAddDeliveryTimeBtn, setShowAddDeliveryTimeBtn] = useState(false);
    const [DeliveryTimeLimit, setDeliveryTimeLimit] = useState(null);


    // Fetch app configuration on component mount
    useEffect(() => {
        GetAppConfig();
    }, []);

    const GetAppConfig = async () => {
        const response = await AdminGetAppConfig();
        if (response && response?.statusCode === 200) {
            const data = response?.data[0];
            setGetPincode(data?.pincode || []);
            setCashOnDelivery(data?.cash_on_delivery ?? null);
            setDeliveryCharge(data?.delivery_charge || "");
            setDeliveryTimeLimit(data?.delivery_timelimit || "");
            setWhatsappPhonenumberId(data?.whatsapp_phonenumber_id || "");
            setWhatsappAccesstoken(data?.whatsapp_access_token || "");
        }
    };

    const UpdateAppConfiguration = async (updatedConfig = {}) => {
        const payload = {
            pincode: updatedConfig.pincode ?? getPincode,
            whatsapp_phonenumber_id: updatedConfig.whatsapp_phonenumber_id ?? WhatsappPhonenumberId,
            whatsapp_access_token: updatedConfig.whatsapp_access_token ?? WhatsappAccesstoken,
            cash_on_delivery: updatedConfig.cash_on_delivery ?? cashOnDelivery,
            delivery_charge: updatedConfig.delivery_charge ?? deliveryCharge,
            delivery_timelimit: updatedConfig.delivery_timelimit ?? DeliveryTimeLimit,
        };
        const response = await AdminUpdateAppConfig(payload);
        if (response && response?.statusCode === 200) {
            successMsg("Appconfig Updated Successfully");
            if (updatedConfig.delivery_charge) setDeliveryCharge(""); // Reset delivery charge after update
        } else {
            errorMsg(response?.message);
        }
    };

    const handleChangePincode = async (newPincode) => {
        let validate = {};
        validate.pincode = PincodeValidation(newPincode);
        setValidation(validate);

        if (validate.pincode.status === true) {
            if (getPincode.includes(newPincode)) {
                errorMsg("Pincode already exists");
                return;
            }
            const updatedPincode = [...getPincode, newPincode];
            setGetPincode(updatedPincode);
            await UpdateAppConfiguration({ pincode: updatedPincode });
            setPincode("");
            setShowAddPincode(false);
            setValidation({});
        }
    };

    const handleDeletePincode = async (pincodeToDelete) => {
        const updatedPincode = getPincode.filter((item) => item !== pincodeToDelete);
        setGetPincode(updatedPincode);
        await UpdateAppConfiguration({ pincode: updatedPincode });
    };

    const handleCashOnDeliveryToggle = async () => {
        const updatedValue = !cashOnDelivery;
        setCashOnDelivery(updatedValue);
        await UpdateAppConfiguration({ cash_on_delivery: updatedValue });
    };

    const handleDeliveryChargeSubmit = async () => {
        if (deliveryCharge !== "") {
            setShowAddDeliveryBtn(false)
            await UpdateAppConfiguration({ delivery_charge: deliveryCharge });
           await  GetAppConfig()
        } else {
            setShowAddDeliveryBtn(true)
            errorMsg("Delivery charge is required")
        }
    };

    const handleWhatsappPhonenumberIdSubmit = async () => {
        if (WhatsappPhonenumberId !== "") {
            await UpdateAppConfiguration({ whatsapp_phonenumber_id: WhatsappPhonenumberId });
            await  GetAppConfig()
        } else {
            errorMsg("Whatsapp Phonenumber Id is required")
        }
    };

    const handleWhatsappAccesstokenSubmit = async () => {
        if (WhatsappAccesstoken !== "") {
            await UpdateAppConfiguration({ whatsapp_access_token: WhatsappAccesstoken });
            await  GetAppConfig()
        } else {
            errorMsg("Whatsapp Accesstoken is required")
        }
    };

    const handleDeliveryTimeLimit = async () => {
        if (DeliveryTimeLimit !== "") {
            await UpdateAppConfiguration({ delivery_timelimit: DeliveryTimeLimit });
            setShowAddDeliveryTimeBtn(false)
        } else {
            errorMsg("Delivery Time is required")
        }
    }

    return (
        <div>
            <div className="row">
                {/* Pincode Section */}
                <div className="col-lg-6">
                    <div className="tab-pane fade show active mt-4 me-5">
                        <div className="d-flex justify-content-between">
                            <p className="app_menu_para my-auto">Pincode Available For Delivery</p>
                            <p
                                className="Admin_add_button"
                                onClick={() => setShowAddPincode(!showAddPincode)}
                            >
                                ADD
                            </p>
                        </div>

                        <div className="selected_menus d-flex mt-3 mb-4">
                            {getPincode.map((item, index) => (
                                <div key={index}>
                                    <p className="select_app_menu_para me-3">
                                        {item}
                                        <i
                                            className="fa-solid fa-trash-can ms-3 cursor text-danger mt-1"
                                            onClick={() => handleDeletePincode(item)}
                                        ></i>
                                    </p>
                                </div>
                            ))}
                        </div>

                        {showAddPincode && (
                            <div>
                                <input
                                    className="pincode_input_box mt-3"
                                    placeholder="Enter Pincode"
                                    value={pincode}
                                    type="number"
                                    onChange={(e) => setPincode(e.target.value)}
                                    onKeyDown={(evt) =>
                                        ["e", "E", "+", "-"].includes(evt.key) &&
                                        evt.preventDefault()
                                    }
                                    onWheelCapture={(e) => e.target.blur()}
                                />
                                {validation?.pincode?.message && (
                                    <p className="error_message text-start">
                                        {`Pincode ${validation?.pincode?.message}`}
                                    </p>
                                )}
                                <button
                                    className="admin_button float-end mt-4 w-100"
                                    onClick={() => handleChangePincode(pincode)}
                                >
                                    Add Pincode
                                </button>
                            </div>
                        )}
                    </div>
                    <div className="mt-5 pt-3">
                            <div className="d-flex justify-content-between">
                                <p className="app_menu_para my-auto text-nowrap mt-4">Whatsapp Phonenumber Id</p>
                                {/* <p
                                    className="Admin_add_button"
                                >
                                    Edit
                                </p> */}
                            </div>
                            <input
                                className="pincode_input_box mt-2"
                                placeholder="Enter Whatsapp Phonenumber Id"
                                value={WhatsappPhonenumberId  }
                                type="text"
                                onChange={(e) => setWhatsappPhonenumberId(e.target.value)}                            />
                            
                                <button
                                    className="Admin_add_button float-end mt-3 me-auto w-100"
                                    onClick={() => { handleWhatsappPhonenumberIdSubmit() }}
                                >
                                    Submit
                                </button>
                        </div>
                        <div className="mt-5 pt-5">
                            <div className="d-flex justify-content-between">
                                <p className="app_menu_para my-auto">Whatsapp Accesstoken</p>
                               
                            </div>
                            <input
                                className="pincode_input_box mt-2"
                                placeholder="Enter Whatsapp Accesstoken"
                                value={WhatsappAccesstoken  }
                                type="text"
                                onChange={(e) => setWhatsappAccesstoken(e.target.value)}                                 
                            />
                                <button
                                    className="Admin_add_button float-end mt-3 me-auto w-100"
                                    onClick={() => { handleWhatsappAccesstokenSubmit() }}
                                >
                                    Submit
                                </button>
                        </div>
                </div>

                {/* Cash on Delivery and Delivery Charge Section */}
                <div className="col-lg-6">
                    <div className="tab-pane fade show active mt-4 ms-5 ps-5">
                        <div className="d-flex justify-content-between">
                            <p className="app_menu_para my-auto">Cash On Delivery</p>
                            <label className="switch ms-3">
                                <input
                                    type="checkbox"
                                    checked={cashOnDelivery}
                                    onClick={handleCashOnDeliveryToggle}
                                />
                                <span className="slider round"></span>
                            </label>
                        </div>

                        
                        <div className="mt-5 pt-3">
                            <div className="d-flex justify-content-between">
                                <p className="app_menu_para my-auto">Delivery Charge</p>
                                {/* <p
                                    className="Admin_add_button"
                                >
                                    Edit
                                </p> */}
                            </div>
                            <input
                                className="pincode_input_box mt-2"
                                placeholder="Enter Delivery Charge"
                                value={deliveryCharge  }
                                type="number"
                                onChange={(e) => setDeliveryCharge(e.target.value)}
                                onKeyDown={(evt) =>
                                    ["e", "E", "+", "-"].includes(evt.key) &&
                                    evt.preventDefault()
                                }
                                onWheelCapture={(e) => e.target.blur()}
                            />
                            
                                <button
                                    className="Admin_add_button float-end mt-3 me-auto w-100"
                                    onClick={() => { handleDeliveryChargeSubmit() }}
                                >
                                    Submit
                                </button>
                        </div>
                        <div className="mt-5 pt-5">
                            <div className="d-flex justify-content-between">
                                <p className="app_menu_para my-auto">Delivery Time</p>
                               
                            </div>
                            <input
                                className="pincode_input_box mt-2"
                                placeholder="Enter Delivery Charge"
                                value={DeliveryTimeLimit}
                                type="time"
                                onChange={(e) => setDeliveryTimeLimit(e.target.value)}
                            />
                                <button
                                    className="Admin_add_button float-end mt-3 me-auto w-100"
                                    onClick={() => { handleDeliveryTimeLimit() }}
                                >
                                    Submit
                                </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PincodeSection;
