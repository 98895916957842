import Multiselect from "multiselect-react-dropdown";
import React, { useEffect, useState } from "react";
import { AdminGetAppConfig, AdminGetCategory, AdminUpdateAppConfig } from "../../../common/Api";
import { errorMsg, successMsg } from "../../../common/Toastify";

const MenuSection = () => {
  const [allCategory, setAllCategory] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [selectedValues2, setSelectedValues2] = useState([]);
  const [productDetails, setProductDetails] = useState({});
  const [productDetails2, setProductDetails2] = useState({});
  const [validation, setValidation] = useState({});
  const [appConfigData, setAppConfigData] = useState({});
  const [showAddHomeMenus, setShowAddHomeMenus] = useState(false);
  const [showAddmenus, setShowAddmenus] = useState(false);

  const GetAllCategory = async () => {
    const response = await AdminGetCategory();
    if (response && response?.statusCode === 200) {
      setAllCategory(response?.data);
    }
  };
  const onSelectOption = (selectedList) => {
    const categoryIds = selectedList.map((item) => item._id);
    setSelectedValues(selectedList);
    handleProductCreate(categoryIds, "category_id");
  };
  const handleProductCreate = (value, key) => {
    setProductDetails({ ...productDetails, [key]: value });
    if (validation[key]) setValidation({ ...validation, [key]: false });
  };
  const onRemoveOption = (selectedList) => {
    const categoryIds = selectedList.map((item) => item._id);
    setSelectedValues(selectedList);
    handleProductCreate(categoryIds, "category_id");
  };
  const onSelectOption2 = (selectedList) => {
    const categoryIds = selectedList.map((item) => item._id);
    setSelectedValues2(selectedList);
    handleProductCreate2(categoryIds, "category_id");
  };
  const handleProductCreate2 = (value, key) => {
    setProductDetails2({ ...productDetails2, [key]: value });
    if (validation[key]) setValidation({ ...validation, [key]: false });
  };
  const onRemoveOption2 = (selectedList) => {
    const categoryIds = selectedList.map((item) => item._id);
    setSelectedValues2(selectedList);
    handleProductCreate2(categoryIds, "category_id");
  };

  const GetAppConfig = async () => {
    const response = await AdminGetAppConfig();
    if (response && response?.statusCode === 200) {
      setAppConfigData(response?.data[0]);
      setSelectedValues(response?.data[0]?.home_page_menus);
      setSelectedValues2(response?.data[0]?.menu_page_menus);
    }
  };
  let payload = {};
  const UpdateAppConfiguration = async () => {
    payload = {
      homepage_menus: selectedValues?.map((item) => item?._id),
      menupage_menus: selectedValues2?.map((item) => item?._id),
    };
    const response = await AdminUpdateAppConfig(payload);
    if (response && response?.statusCode === 200) {
      successMsg("Appconfig Updated Successfully");
      GetAppConfig();
    } else {
      errorMsg(response?.message);
    }
  };

  useEffect(() => {
    GetAllCategory();
    GetAppConfig();
  }, []);

  return (
    <div>
      <>
        <div className="mt-5">
          <div className="row">
            <div className="col-lg-6">
              <div className="tab-pane fade show active me-5">
                <div className="d-flex justify-content-between">
                  <p className="app_menu_para my-auto">Home Page Menu</p>
                  <p
                    className="Admin_add_button"
                    onClick={() => setShowAddmenus(!showAddmenus)}
                  >
                    ADD or EDIT
                  </p>
                </div>
                <div className="selected_menus d-flex mt-5 mb-5">
                  {appConfigData &&
                    appConfigData?.home_page_menus?.map((item, index) => (
                      <div>
                        <p className="select_app_menu_para me-3" key={index}>
                          {item?.category}
                        </p>
                      </div>
                    ))}
                </div>
                {showAddmenus && (
                  <div>
                    <div className="d-flex flex-column mt-4">
                      <Multiselect options={allCategory} selectedValues={selectedValues} onSelect={onSelectOption} onRemove={onRemoveOption} placeholder="Select Product Category" displayValue="category" showArrow={true} />
                      {/* {selectedValues?.length === 0 && (
                    <p className='error_message text-start'>{`Category is required ` || ''}</p>
                )} */}
                    </div>
                    <button
                      className="admin_button float-end mt-4 me-auto w-100"
                      onClick={() => {
                        UpdateAppConfiguration();
                      }}
                      disabled={selectedValues?.length === 0}
                    >
                      Submit
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="tab-pane fade show active ms-5">
                {/* <p className="app_menu_para">Menus Page Menus</p> */}
                <div className="d-flex justify-content-between">
                  <p className="app_menu_para my-auto">Menu Page Menu</p>
                  <p
                    className="Admin_add_button"
                    onClick={() => setShowAddHomeMenus(!showAddHomeMenus)}
                  >
                    ADD or EDIT
                  </p>
                </div>
                <div className="selected_menus d-flex mt-5 mb-5">
                  {appConfigData &&
                    appConfigData?.menu_page_menus?.map((item, index) => (
                      <div>
                        <p className="select_app_menu_para me-3" key={index}>
                          {item?.category}
                        </p>
                      </div>
                    ))}
                </div>
                {showAddHomeMenus && (
                  <div>
                    <div className="d-flex flex-column mt-4">
                      <Multiselect options={allCategory} selectedValues={selectedValues2} onSelect={onSelectOption2} onRemove={onRemoveOption2} placeholder="Select Product Category" displayValue="category" showArrow={true} />
                      {/* {selectedValues?.length === 0 && (
                    <p className='error_message text-start'>{`Category is required ` || ''}</p>
                )} */}
                    </div>
                    <button
                      className="admin_button float-end mt-4 me-auto w-100"
                      onClick={() => {
                        UpdateAppConfiguration();
                      }}
                      disabled={selectedValues2?.length === 0}
                    >
                      Submit
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default MenuSection;
