import React from 'react'

const Cms = () => {
  return (
    <div>
     <p>swedrftghyjnmk,l.;</p>
    </div>
  )
}

export default Cms
