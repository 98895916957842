import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: [], 
  deliveryFee: 0, 
  overallqty: 0,
  subtotal: 0,
  grandTotal: 0
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const product = action.payload;
      if (!Array.isArray(state.items)) {
        state.items = [];
      }

      const existingProduct = state.items.find((item) => item.id === product.id);
      if (existingProduct) {
        existingProduct.quantity += 1;
        existingProduct.product_subtotal = existingProduct.quantity * existingProduct.price;
      } else {
        state.items = [...state.items, { ...product, quantity: 1, product_subtotal: product.price }];
      }
    },
    updateQuantity: (state, action) => {
      const { id, increment } = action.payload;

      if (!Array.isArray(state.items)) {
        state.items = [];
      }

      const product = state.items.find((item) => item.id === id);

      if (product) {
        product.quantity += increment;
        product.product_subtotal = product.quantity * product.price;

        if (product.quantity <= 0) {
          state.items = state.items.filter((item) => item.id !== id);
        }
      }
    },
    calculateDeliveryFee: (state) => {

      if (!Array.isArray(state.items)) {
        state.items = [];
      }

      const subproducttotal = state.items.reduce(
        (total, item) => total + (item?.product_subtotal || 0),
        0
      );
      const overallproducttotal = state.items.reduce(
        (total, item) => total + (item?.quantity || 0),
        0
      );

      state.subtotal = subproducttotal;
      state.overallqty = overallproducttotal;

    },
    clearCart: (state) => {
      state.items = [];
      state.subtotal = 0;
      state.grandTotal = 0;
      state.overallqty = 0;
    },
  },
});

export const { addToCart, updateQuantity, calculateDeliveryFee, clearCart } = cartSlice.actions;

export default cartSlice.reducer;
