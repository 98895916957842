import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Navbar from "../../components/Navbarcontainer";
import Footer from "../../components/Footerpage";
import moment from "moment";
import { UserOrderFindParticular } from "../../common/Api";

const UserOrderDetails = () => {
  const { id } = useParams()
  const location = useLocation();
  const navigate = useNavigate();
  const [ordersDetails, setOrdersDetails] = useState({});

  const orderStatus = ordersDetails?.order_status
  const configData = useSelector((state) => state.appconfig);

  const GetParticularProduct = async () => {
    const response = await UserOrderFindParticular(id);
    if (response && response?.statusCode === 200) {
      setOrdersDetails(response?.data);
    }
  }

  useEffect(() => {
    GetParticularProduct()
  }, []);
  const progressSteps = [
    { icon: "fa-cart-shopping", label: "Order Placed", status: "ordered" },
    { icon: "fa-check", label: "Accepted", status: "accepted" },
    { icon: "fa-truck", label: "Out For Delivery", status: "out-for-delivery" },
    { icon: "fa-check-to-slot", label: "Delivered", status: "delivered" },
  ];

  const currentStepIndex = progressSteps.findIndex(
    (step) => step.status === orderStatus
  );

  const [currentStep, setCurrentStep] = useState(1);

  useEffect(() => {
    if (location.state?.currentStep) {
      setCurrentStep(location.state.currentStep);
    }
  }, [location.state]);
  const progressWidth = ((currentStep - 1) / (progressSteps.length - 1)) * 90;

  return (
    <>
      <div className="overflow-hidden">
        <Navbar />
        <div className="container">
          <div className="user-order-detail-parent">
            <div className="order-details-container">
              <h3 className="text-center mb-4 fw-bold ">Orders Detail</h3>
              <button className="backbutton float-end mb-2" onClick={() => navigate("/user/order-history")}>
                <i className="fa-solid  fa-angles-left "></i> &nbsp; back to orders
              </button>


              <div className="stepper-wrapper">
                <div className="card-header border-2 text-primary bg-warn g-col-6 me-auto  mt-2">
                  <span>Order#: {ordersDetails?.order_id}</span>
                </div>
                <div className={ordersDetails?.order_status !== "decline" ? "stepper-progress-container" : ""}>

                  <div
                    className="stepper-progress bg-warning border-warn"
                    style={{ width: `${progressWidth}%` }}
                  ></div>

                  {ordersDetails?.order_status !== "decline" ?
                    progressSteps?.map((step, index) => (
                      <div
                        key={index}
                        className={`stepper-progress-step ${index <= currentStepIndex ? "active border-danger" : ""}`}
                      >
                        <i className={`fa-solid ${step.icon} text-danger ${index <= currentStepIndex ? 'active bg-warning border-danger' : ''}`}></i>
                        <p className="step-label mb-0">{step.label}</p>
                        <p className="mb-0 text-center">
                          {index === 0 && moment(ordersDetails?.createdAt).format('DD/MM/YYYY hh:mm A')}
                          {index === 1 && (
                            ordersDetails?.order_accepted_at
                              ? moment(ordersDetails?.order_accepted_at).format('DD/MM/YYYY hh:mm A')
                              : "Not Yet Accepted"
                          )}
                          {index === 2 && (
                            ordersDetails?.order_outfordelivery_at
                              ? moment(ordersDetails?.order_outfordelivery_at).format('DD/MM/YYYY hh:mm A')
                              : "Not Yet Out-For Delivery"
                          )}
                          {index === 3 && (
                            ordersDetails?.order_delivered_at
                              ? moment(ordersDetails?.order_delivered_at).format('DD/MM/YYYY hh:mm A')
                              : "Not Yet Delivered"
                          )}
                        </p>
                      </div>
                    ))
                    :
                    <div className="decline_reason_div">
                      <h4 className="text-center decline_head">Reason for Order Decline!!!</h4>
                      <p className="fw-bold">
                        {ordersDetails?.order_decline_reason}
                      </p>
                      <div className="d-flex justify-content-between">
                        <p className="mb-0 fw-bold">Order Placed At : {moment(ordersDetails?.createdAt).format("DD/MM/YYYY hh:mm A")}</p>
                        <p className="mb-0 fw-bold">Order Decline At : {moment(ordersDetails?.order_decline_at).format("DD/MM/YYYY hh:mm A")}</p>
                      </div>
                      <p className="fw-bold mb-0">If you have any questions or require assistance, please feel free to contact us : +91 {configData?.phone_number?.value}</p>
                    </div>
                  }


                </div>
                <br></br>
              </div>
              <div>{/* <h3 className="admin-head">Order Detail Information</h3> */}</div>
              <div className="order-details-content mt-5">
                <div className="row">
                  {/* Customer Information */}
                  {ordersDetails && (
                    <div className="col-lg-6">
                      <div className="customer-info">
                        <div className="row">
                          <h3 className="cart-title m-auto col ">Order Address</h3>
                        </div>
                        <div className="ordered-address mt-4">
                          <p className="label">Name</p>
                          <p className="data">{ordersDetails?.delivery_details?.name}</p>
                        </div>
                        <div className="ordered-address mt-4">
                          <p className="label">Delivery Phone Number</p>
                          <p className="data">{ordersDetails?.delivery_details?.phone_number}</p>
                        </div>
                        <div className="ordered-address mt-4">
                          <p className="label">Door Number</p>
                          <p className="data">{ordersDetails?.delivery_details?.door_number}</p>
                        </div>
                        <div className="ordered-address mt-4">
                          <p className="label">Street</p>
                          <p className="data">{ordersDetails?.delivery_details?.street}</p>
                        </div>
                        <div className="ordered-address mt-4">
                          <p className="label">Pincode</p>
                          <p className="data">{ordersDetails?.delivery_details?.pincode}</p>
                        </div>
                        <div className="ordered-address mt-4">
                          <p className="label">Landmark</p>
                          <p className="data">{ordersDetails?.delivery_details?.landmark}</p>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* Products Information */}
                  {ordersDetails && (
                    <div className="col-lg-6">
                      <div className="d-flex justify-content-between">
                        <h3 className="cart-title m-auto mb-3 cart-title-user-order">Order Summary</h3>
                        {/* <button className="btn mb-2 float-end col-3 border" onClick={toggleEditorder}>
                {isEditingOrder ? "Save" : "Edit"}
              </button> */}
                      </div>

                      <div className="cart fixed-column user-order-detail-cart">
                        {/* {items?.length === 0 && (
                  <img className="cart-icon" src={cart} alt="cart" />
                )} */}
                        {ordersDetails?.Products?.length > 0 ? (
                          <div className="cart-content">
                            <table className="cart-table">
                              <thead>
                                <tr>
                                  <th>Item</th>
                                  <th>Quantity</th>
                                  <th>Total Price</th>
                                </tr>
                              </thead>
                              <tbody>
                                {ordersDetails?.Products.map((item, idx) => (
                                  <tr key={idx} className="order-product-para-admin">
                                    <td>{item.name}</td>
                                    <td>{item.quantity}</td>
                                    <td>₹{item.price * item.quantity}</td>
                                  </tr>
                                ))}
                              </tbody>
                              <tfoot>
                                <tr>
                                  <td colSpan="2">Subtotal:</td>
                                  <td>₹{ordersDetails?.sub_total}</td>
                                </tr>
                                <tr>
                                  <td colSpan="2">Delivery Charges:</td>
                                  <td>₹{configData?.delivery_charge}</td>
                                </tr>
                                <tr>
                                  <td colSpan="2">Grand Total:</td>
                                  <td>₹{ordersDetails?.total_payable_amount}</td>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                        ) : (
                          <p>Your Order is empty.</p>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};
export default UserOrderDetails;
