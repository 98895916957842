import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Homepage from "./components/Homepage";
import Menupage from "./components/Menupage";
import CheckoutPage from "./components/Checkoutoage";
import Loginpage from "./components/Loginpage";
import Termsandcondition from "./components/Termsandcondition";
import { useSelector } from "react-redux";
import UserOrders from "./featured/protected/UserOrders";
import UserOrderDetails from "./featured/protected/UserOrderDetails";
import { routes } from "./routes";
import RoleBasedRoute from "./routes/RoleBasedRoute";
import UnAuthorized from "./common/UnAuthorized";
import Layout from "./container/Layout";
import NotFound from "./common/NotFound";

function App() {
  const { isLoggedIn, userType } = useSelector((state) => state?.login);
  document.addEventListener('keydown', (event) => {
    if (event.ctrlKey && event.key === '/') {
      event.preventDefault();
      const baseDomain = window.location.origin;
      const newUrl = `${baseDomain}/adminlogin`;
      window.location.href = newUrl;
    }
  })
  return (
    <>
      <ToastContainer />
      <Router>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/Menu" element={<Menupage />} />
          <Route path="/checkout" element={<CheckoutPage />} />
          <Route path="/terms-and-condition" element={<Termsandcondition cms_document_type="Terms_and_condition" title="Terms & Conditions" />} />
          <Route path="/privacy-policy" element={<Termsandcondition cms_document_type="Privacy_policy" title="Privacy Policy" />} />
          <Route path="/help" element={<Termsandcondition cms_document_type="Help_Support" title="Help & Support" />} />
          <Route path="/refund-and-cancellation" element={<Termsandcondition cms_document_type="Refund_cancellation" title="Refund and Cancellation" />} />
          <Route path="/user/order-history" element={<UserOrders />} />
          <Route path="/user/order-details/:id" element={<UserOrderDetails />} />
          <Route path="/adminlogin" element={(isLoggedIn && userType === "admin") ? <Navigate replace to="/admin/admin" />
            : !isLoggedIn ? <Loginpage /> :
              (isLoggedIn && userType === "user") ? <UnAuthorized /> : ""} />
          <Route path="/admin/*" element={(isLoggedIn && userType === "admin") ? <Layout /> : <UnAuthorized />} />
          <Route path="*" element={<NotFound />} />
          {routes.map((item) => (
            <Route key={item.path} path={item.path} element={<RoleBasedRoute Component={item.component} roles={["admin"]} />} />
          ))}
        </Routes>
      </Router>
    </>
  );
}

export default App;
