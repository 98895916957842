import React, { useState } from "react";

const EditProduct = () => {
  const [fileName, setFileName] = useState("");

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
    } else {
      setFileName("");
    }
  };
  return (
    <div>
      <h3 className="admin_head">Edit Product</h3>
      <div className="mt-5">
        <div className="row">
          <div className="col-lg-6">
            <div className="add_product_left">
              <p className="add_product">Basic information</p>
              <div className="d-flex flex-column">
                <label className="add_product_label">Product Name</label>
                <input className="add_product_input" />
              </div>
              <div className="d-flex flex-column mt-4">
                <label className="add_product_label">Product Description</label>
                <textarea className="add_product_textarea" />
              </div>
              <div className="d-flex flex-column mt-4">
                <label className="add_product_label">Product Category</label>
                <select name="cars" id="cars" className="add_product_select">
                  <option value="volvo">Volvo</option>
                  <option value="saab">Saab</option>
                  <option value="mercedes">Mercedes</option>
                  <option value="audi">Audi</option>
                </select>
              </div>
            </div>
            <div className="add_product_left mt-5">
              <p className="add_product">Pricing & Inventory</p>
              <div className="d-flex flex-row justify-content-between">
                <div className="d-flex flex-column">
                  <label className="add_product_label">Base pricing</label>
                  <input className="add_product_input" style={{ width: "220px" }} />
                </div>
                <div className="d-flex flex-column">
                  <label className="add_product_label">Base pricing</label>
                  <input className="add_product_input" style={{ width: "220px" }} />
                </div>
              </div>
              <div className="d-flex flex-row justify-content-between mt-3">
                <div className="d-flex flex-column">
                  <label className="add_product_label">Stock</label>
                  <input className="add_product_input" style={{ width: "220px" }} />
                </div>
                <div className="d-flex flex-column">
                  <label className="add_product_label">Base pricing</label>
                  <input className="add_product_input" style={{ width: "220px" }} />
                </div>
              </div>
            </div>
            <div className="d-flex flex-column mt-4">
              <label className="add_product_label">Stock Status</label>
              <select name="cars" id="cars" className="add_product_select">
                <option value="volvo"> Stock Status</option>
                <option value="saab">In Stock</option>
                <option value="mercedes">Out of Stock</option>
              </select>
            </div>
          </div>
          <div className="col-lg-1"></div>
          <div className="col-lg-5">
            <div className="add_product_right">
              <p className="add_product">Product images</p>

              <div className="d-flex justify-content-center align-items-center w-100 mt-5">
                <label htmlFor="dropzone-file" className="d-flex flex-column justify-content-center align-items-center w-100 h-64 border border-secondary border-dashed  cursor-pointer bg-light hover-border-secondary" style={{ height: "16rem", cursor: "pointer", borderRadius: "15px" }}>
                  <div className="d-flex flex-column justify-content-center align-items-center py-3">
                    <svg className="mb-3" style={{ width: "2rem", height: "2rem", color: "#6c757d" }} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                    </svg>
                    <p className="mb-2 text-secondary">
                      <strong>Click to upload</strong>
                    </p>
                    <p className="text-muted small">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
                    {fileName && (
                      <p className="mt-2 text-secondary">
                        <strong>Uploaded file : </strong>
                        <strong className="text-danger fw-bold">{fileName}</strong>
                      </p>
                    )}
                  </div>
                  <input id="dropzone-file" type="file" className="d-none" onChange={handleFileChange} />
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProduct;
