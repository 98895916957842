import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import food1 from "../../assets/images/food2.png";
import OrderDetails from "./OrderDetails";
import { useSelector } from "react-redux";
import CustomModal from "../../common/CustomModel";
import { UserDwonloadInvoice, UserOrderHistory } from "../../common/Api";
import moment from "moment";
import Footer from "../../components/Footerpage";
import Navbar from "../../components/Navbarcontainer";
import { successMsg } from "../../common/Toastify";

import { Buffer } from 'buffer';

const UserOrders = () => {
  const [ordersListing, setOrdersListing] = useState([]);
  const { _id } = useSelector((state) => state?.login)
  const navigate = useNavigate();
  const GetOrders = async () => {
    const response = await UserOrderHistory(_id);
    if (response && response?.statusCode === 200) {
      setOrdersListing(response?.data);
    }
  };

  const hanldeOrderDetails = (id) => {
    navigate(`/user/order-details/${id}`);
  };

  const downloadInvoice = async (order_id, order_no) => {
    let payload = { _id: order_id };
    try {
      const response = await UserDwonloadInvoice(payload);
      const blob = new Blob([response], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);
      // window.open(url);
      const link = document.createElement('a');
      link.href = url;

      link.setAttribute('download', `invoice_${order_no}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();

    } catch (error) {
      console.error("Error during invoice download:", error);
    }
  };

  const handleUserViewProductDetails = (item) => {
    navigate("/user/order-details", {
      state: { UserOrderedProductDetails: item },
    });
  };
  useEffect(() => {
    GetOrders();
  }, []);
  return (
    <div className="overflow-hidden">
      <Navbar />
      <div className="container">
        <div className="user-order-detail-parent">
          <div className="row">
            <div className="order-history-parent-div">
              <h3 className="text-center mb-4 fw-bold ">Orders History</h3>
              {ordersListing.length > 0 ?
                ordersListing.map((item, index) => (
                  <div className="tab-content mt-4" key={index}>
                    <div className="tab-pane fade show active">
                      <div className="historycard p-3">
                        <div className="d-flex flex-column flex-md-row justify-content-between mt-3">
                          <div className="col-12 col-md-9 d-flex flex-column ">
                            <div className="card-header g-col-6 d-flex justify-content-between ">
                              <span>Order# : {item?.order_id}</span>
                              <div className={
                                item?.order_status === "decline"
                                  ? "text-danger delivered-date g-col-6 my-auto"
                                  : "text-success delivered-date g-col-6 my-auto"
                              }
                              >
                                {item?.order_status !== "decline" && (
                                  <i className="fa-solid fa-circle-check"></i>
                                )}&nbsp;
                                {item?.order_status === "ordered" ? "Ordered" :
                                  item?.order_status === "accepted" ? "Order Accepted" :
                                    item?.order_status === "decline" ? "Order Declined !!"
                                      : item?.order_status === "out-for-delivery" ? "Out For Delivery" : "Order Delivered"}
                              </div>
                            </div>

                            <div className="d-flex row">
                              <div className="col-3">
                                <div className="  d-flex  delivered-date justify-content-center">
                                  <div className="item-info position-relative">
                                    {/* Blurred Background Image */}
                                    <div
                                      className="blurred-background"
                                      style={{
                                        backgroundImage: `url(${food1})`,
                                        backgroundSize: "cover",
                                        backgroundPosition: "center",
                                        filter: "blur(8px)",
                                      }}
                                    ></div>
                                    {/* Foreground Image */}
                                    <img src={food1} alt="food1" className="foreground-image" style={{ width: "80px", marginRight: "10px" }} />
                                    {/* Total Items Overlay (conditionally rendered) */}
                                    {item?.Products.length > 1 && (
                                      <div className="item-quantity-overlay">
                                        <span>{item?.Products.length} Items</span>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="col-9 justify-content-center py-2 gap-x-4">
                                <div className="  d-flex flex-wrap   ">
                                </div>
                                <div className="row m-auto mt-3">
                                  <div className="d-flex fw-bold">
                                    <b>Bill Amt:</b> &nbsp;
                                    <div style={{ fontWeight: "bold", fontSize: "1.2rem" }}>₹ {item?.total_payable_amount}</div>
                                  </div>
                                  <p className="fw-bold mt-2 mb-0">Ordered At : {moment(item?.createdAt).format("DD/MM/YYYY hh:mm A")}</p>
                                  <p className="fw-bold mt-2">Payment Type : {item?.payment_type === "cash-on-delivery" ? "Cash on Delivery" : "Online Payment"}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="p-2 pt-0 d-flex flex-column col-12 col-md-3 " style={{ marginTop: item?.order_status === "delivered" ? "0px" : "-10px" }}>
                            {item?.order_status === "delivered" && (
                              <button type="button" class="btn border mt-0 text-white bg-success" onClick={() => downloadInvoice(item?._id, item.order_id)}>
                                GET INVOICE
                              </button>
                            )}
                            <button type="button" class="btn border " onClick={() => {handleUserViewProductDetails(item);
                              hanldeOrderDetails(item?._id)
                            }}>
                              View Order Details
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )) : <p className='no_order_display'>No Order Yet !!!!</p>}
            </div>
          </div>
        </div>
      </div>
      <div className="bottom_footer">
        <Footer />
      </div>
    </div>
  );
};

export default UserOrders;
