import React, { useEffect, useState } from "react";
import { AdminGetAppConfig, AdminUpdateAppConfig } from "../../../common/Api";
import { errorMsg, successMsg } from "../../../common/Toastify";
import { NonEmptyValidation, PhoneNumberValidation } from "../../../common/validate";

function FooterConfiguration() {
  const [config, setConfig] = useState({
    social_media: [],
    address: { value: "", isShow: true },
    phone_number: { value: "", isShow: true },
  });
  const [validation, setValidation] = useState({});


  // Fetch configuration on component mount
  useEffect(() => {
    const fetchAppConfig = async () => {
      try {
        const response = await AdminGetAppConfig();
        if (response?.statusCode === 200) {
          const appConfig = response.data[0] || {};
          setConfig({
            social_media: appConfig.social_media || [
              { key: "facebook", url: "", isShow: true },
              { key: "twitter", url: "", isShow: true },
              { key: "instagram", url: "", isShow: true },
            ],
            address: appConfig.address || { value: "", isShow: true },
            phone_number: appConfig.phone_number || { value: "", isShow: true },
          });
        } else {
          errorMsg("Failed to fetch app configuration");
        }
      } catch {
        errorMsg("An error occurred while fetching the configuration");
      }
    };

    fetchAppConfig();
  }, []);

  const updateConfigField = (field, value) => {
    setConfig((prev) => ({ ...prev, [field]: { ...prev[field], ...value } }));
  };

  const updateSocialMediaField = (index, value) => {
    setConfig((prev) => {
      const updatedSocialMedia = [...prev.social_media];
      updatedSocialMedia[index] = { ...updatedSocialMedia[index], ...value };
      return { ...prev, social_media: updatedSocialMedia };
    });
  };

  const validateURL = (url) => {
    const urlPattern = /^(https?:\/\/)?([\w\d-]+\.)+[\w]{2,}(\/.*)?$/;
    return urlPattern.test(url) && url.trim() !== "";
  };


  const handleSaveConfig = async (e) => {
    try {

      e.preventDefault();
      let validate = {};
      validate.address = NonEmptyValidation(config?.address?.value);
      validate.phone_number = PhoneNumberValidation(config?.phone_number?.value);
      setValidation(validate);
      const socialMediaValidation = config?.social_media.map((item) => {
        if (!item.url.trim()) {
          return { ...item, validationError: "URL is required" };
        } else if (!validateURL(item.url)) {
          return { ...item, validationError: "Enter a valid URL" };
        } else {
          return { ...item, validationError: null }; 
        }
      });

      setConfig((prev) => ({
        ...prev,
        social_media: socialMediaValidation,
      }));

      const validation_status =
        Object.values(validate).every((v) => v.status === true) &&
        socialMediaValidation.every((item) => item.validationError === null);

      if (validation_status) {
        const response = await AdminUpdateAppConfig(config);
        if (response?.statusCode === 200) {
          successMsg("Configuration updated successfully");
        } else {
          errorMsg(response?.message || "Failed to update configuration");
        }
      } else {
        errorMsg("Please ensure all fields are filled correctly");
      }
    } catch {
      errorMsg("An error occurred while saving the configuration");
    }
  };

  return (
    <div className="container mt-4 me-auto">
      <div className="row">
        <div className="col-lg-12 footertable">
          <table className="table">
            <thead>
              <tr >
                <th className="app_menu_para2">Section</th>
                <th className="app_menu_para2">Value</th>
                <th className="app_menu_para2">Show/Hide</th>
              </tr>
            </thead>

            <tbody>
              {/* Social Media Inputs */}
              {config?.social_media.map((item, index) => (
                <tr key={index}>
                  <th className="text-capitalize">{item.key}</th>
                  <td>
                    <input
                      type="text"
                      className={`footer_input_box ${item.validationError ? "error" : ""
                        }`}
                      value={item.url}
                      onChange={(e) =>
                        updateSocialMediaField(index, { url: e.target.value })
                      }
                    />
                    {item.validationError && (
                      <p className="error_message text-start">{item.validationError}</p>
                    )}
                  </td>
                  <td>
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={item.isShow}
                        onChange={(e) =>
                          updateSocialMediaField(index, { isShow: e.target.checked })
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                  </td>
                </tr>
              ))}
              {/* Address Input */}
              <tr>
                <th>Shop Address</th>
                <td>
                  <textarea
                    className="footer_input_box_address"
                    value={config?.address.value}
                    onChange={(e) =>
                      updateConfigField("address", { value: e.target.value })
                    }
                  />
                  {validation?.address?.message && <p className="error_message text-start">{`Address ${validation?.address?.message}` || ""}</p>}
                </td>
                <td>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={config?.address.isShow}
                      onChange={(e) =>
                        updateConfigField("address", {
                          isShow: e.target.checked,
                        })
                      }
                    />
                    <span className="slider round"></span>
                  </label>
                </td>
              </tr>
              {/* Contact Number Input */}
              <tr>
                <th>Contact Number</th>
                <td>
                  <input
                    type="text"
                    className="footer_input_box"
                    value={config?.phone_number.value}
                    onChange={(e) =>
                      updateConfigField("phone_number", {
                        value: e.target.value,
                      })
                    }
                  />
                  {validation?.phone_number?.message && <p className="error_message text-start">{`Phone Number ${validation?.phone_number?.message}` || ""}</p>}
                </td>
                <td>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={config.phone_number.isShow}
                      onChange={(e) =>
                        updateConfigField("phone_number", {
                          isShow: e.target.checked,
                        })
                      }
                    />
                    <span className="slider round"></span>
                  </label>
                </td>
              </tr>
            </tbody>

          </table>
          <div className="d-flex justify-content-center">
            <button className="btn btn-primary" onClick={handleSaveConfig} style={{ width: "500px" }}>
              Save Configuration
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FooterConfiguration;
