import Multiselect from 'multiselect-react-dropdown'
import React, { useEffect, useState } from 'react'
import { AdminGetAppConfig, AdminUpdateAppConfig, ImageUpload } from '../../../common/Api';
import { errorMsg, successMsg } from '../../../common/Toastify';
import moment from 'moment';
import { imageBaseUrl } from '../../../common/BaseUrl';
import { useDispatch, useSelector } from 'react-redux';
import { setAppConfig } from '../../../store/slice/appConfigSlice';

const LogoSection = () => {

    const dispatch = useDispatch();
    // const [logoImage, setLogoImage] = useState("");
    const [fileName, setFileName] = useState("");
    const configData = useSelector((state) => state.appconfig);
    const GetAppConfig = async () => {
        const response = await AdminGetAppConfig();
        if (response && response?.statusCode === 200) {
            const appConfigData = response?.data[0];
            dispatch(setAppConfig(appConfigData));
        }
    };

    const handleImageUpload = async (e) => {
        let formData = new FormData();
        let image_name = e?.target?.files[0]?.name;
        let image_type = image_name?.split(".");
        let type = image_type?.pop();
        if (type !== "jpeg" && type !== "png" && type !== "webp" && type !== "jpg") {
            errorMsg("File not supported")
            return;
        }
        formData.append("file", e?.target?.files[0]);
        formData.append("fileName", "logo" + "." + type);
        const response = await ImageUpload(formData);
        if (response && response.statusCode === 200) {
            // successMsg("Image Uploaded Successfully");
            window.location.reload();
            const newLogoImage = response?.data?.filename;
            UpdateAppConfiguration(newLogoImage);
        }
    };

    const UpdateAppConfiguration = async (logo) => {
        let payload = {
            logo: logo
        };
        const response = await AdminUpdateAppConfig(payload);
        if (response && response?.statusCode === 200) {
            successMsg("Appconfig Updated Successfully");
            setFileName("")
            GetAppConfig();
        } else {
            errorMsg(response?.message);
        }
    };

    return (
        <div>
            <>
                <div className='row'>
                    <div className='col-lg-7'>
                        <div className="tab-pane fade show active mx-5">
                            <div className='d-flex flex-column mt-0'>
                                <div className="add_product_right">
                                    <p className="add_product text-center">Logo Image</p>
                                    <div className="d-flex justify-content-center align-items-center w-100 mt-4">
                                        <label htmlFor="dropzone-file" className="d-flex flex-column justify-content-center align-items-center w-100 h-64 border border-secondary border-dashed  cursor-pointer bg-light hover-border-secondary" style={{ height: "16rem", cursor: "pointer", borderRadius: "15px" }}>
                                            <div className="d-flex flex-column justify-content-center align-items-center py-3">
                                                <svg className="mb-3" style={{ width: "2rem", height: "2rem", color: "#6c757d" }} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                                                </svg>
                                                <p className="mb-2 text-secondary">
                                                    <strong>Click to upload</strong>
                                                </p>
                                                <p className="text-muted small">PNG , JPG , JPEG , webp</p>

                                                <p className="mt-2 text-secondary">
                                                    <strong>Uploaded file : </strong>
                                                    <strong className="text-danger fw-bold">{`${configData?.logo}`}</strong>
                                                </p>
                                            </div>
                                            <input id="dropzone-file" type="file" className="d-none" accept="image/jpeg,image/png,image/webp"
                                                onChange={(e) => handleImageUpload(e)}
                                            />
                                        </label>
                                    </div>
                                    {/* {logoImage==="" && <p className="error_message text-start">{`Logo image is required`}</p>} */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-5'>
                        <div className='logo_div_app d-flex flex-column justify-content-center align-items-center w-100 h-64 border border-secondary border-dashed  cursor-pointer bg-light hover-border-secondary' style={{ height: "16rem", cursor: "pointer", borderRadius: "15px" }}>
                            <img src={`${imageBaseUrl + configData?.logo}`}
                                alt={`${configData?.logo}`} className='app_logo_image my-auto mx-auto' />
                        </div>
                    </div>
                </div>
            </>
        </div>
    )
}

export default LogoSection
