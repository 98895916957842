import React from "react";
import { menu } from "../routes/Sidebar";
import { NavLink } from "react-router-dom";
import SubMenu from "./SubMenu";

const Sidebar = () => {
  return (
    <div className="sidebar pt-3">
      <div className="menus">
        {menu?.map((item, i) => {
          return item?.subMenu ? <SubMenu {...item} key={ "submenu_" + i} /> : SideBarMenu(i, item);
        })}
      </div>
    </div>
  );
};

export default Sidebar;
export function SideBarMenu(i, item) {
  return (
    <NavLink key={i} to={item?.path} className={({ isActive }) => (!isActive ? "d-flex align-items-center sidebar_admin_menus" : "d-flex align-items-center sidebar_admin_menus_active")}>
      <div className="sidebar-menu">
        <p>
          <span className="sidebar-icon">{item?.icon}</span>
        </p>
      </div>
      <p className="mb-0 ps-0">{item?.name}</p>
    </NavLink>
  );
}
