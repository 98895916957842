import React from 'react'
import { useNavigate } from 'react-router-dom'

const NotFound = () => {
    const navigate = useNavigate()

    return (
        <div className='mt-5'>
            <p className='text-center'>Page Not Found</p>
            <p className='text-center'>GO Back To
                <a className='unauthorized_btn' onClick={() => navigate("/")}>Landing Page
                </a>
            </p>
        </div>
    )
}

export default NotFound
