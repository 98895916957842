import React, { useEffect, useState } from 'react';
import food1 from '../assets/images/food1.png';
import cart from '../assets/images/cart.png';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { calculateDeliveryFee, updateQuantity } from '../store/slice/cartSlice';
import { AdminGetAppConfig } from './Api';
import { imageBaseUrl } from './BaseUrl';


const CartComponent = ({
  checkout
}) => {
  const location = useLocation();
  const pathUrl = location.pathname
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { items, subtotal } = useSelector((state) => state.cart);
  const [deliveryCharge, setDeliveryCharge] = useState(0);


  useEffect(() => {
    dispatch(calculateDeliveryFee());
  }, [items, dispatch]);

  const GetAppConfig = async () => {
    const response = await AdminGetAppConfig();
    if (response && response?.statusCode === 200) {
      setDeliveryCharge(response?.data[0]?.delivery_charge);
    }
  };

  useEffect(() => {
    GetAppConfig();
  }, []);

  const grandTotalfinal = subtotal + deliveryCharge;

  return (
    <div className={`cart ${pathUrl === "/menu" ? "fixed-column-menu" : "fixed-column-checkout cart-checkout"}`}>

      <h3 className="cart-title">Cart</h3>
      {items?.length === 0 && (
        <img className="cart-icon" src={cart} alt="cart" />
      )}

      {items?.length > 0 ? (
        <div className="cart-content">
          <div className={`cart-mobile cart-scroll-height`}>
            <table className="cart-table">
              <thead>
                <tr>
                  <th>Item</th>
                  <th>Quantity</th>
                  {checkout && (<th>Total Price</th>)}
                </tr>
              </thead>
              <tbody className='cartItemHeight'>
                {items?.map((item, idx) => (
                  <tr key={idx}>
                    <td>
                      <div className="item-info">
                        <img
                          src={imageBaseUrl + item?.image}
                          alt="..food1"
                          style={{ width: '50px', marginRight: '10px' }}
                        />
                        <div className="price-info">
                          <span className="price-name">{item?.name}

                            {!checkout && (
                              <span> x {item.quantity}</span>
                            )}
                          </span>
                          <span className="price-weight">{item?.unit}</span>
                        </div>
                      </div>
                    </td>
                    {checkout && (
                      <td>
                        <div className="quantity-control">
                          <button
                            onClick={() => dispatch(updateQuantity({ id: item?.id, increment: -1 }))}
                          >
                            <i class="fas fa-minus"></i>
                          </button>
                          <span>{item.quantity}</span>
                          <button onClick={() => dispatch(updateQuantity({ id: item?.id, increment: 1 }))}>
                            <i class="fas fa-plus"></i>
                          </button>
                        </div>
                      </td>
                    )}
                    <td>₹{item?.price * item?.quantity}</td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan="3">
                    <hr style={{ border: "1px solid #ccc" }} />
                  </td>
                </tr>
                <tr>
                  <td colSpan={`${!checkout ? "1" : "2"}`}>Subtotal:</td>
                  <td>₹{subtotal}</td>
                </tr>
                <tr>
                  <td colSpan={`${!checkout ? "1" : "2"}`}>Delivery Charges:</td>
                  <td>₹{deliveryCharge}</td>
                </tr>
                <tr>
                  <td colSpan={`${!checkout ? "1" : "2"}`}>Grand Total:</td>
                  <td>₹{grandTotalfinal}</td>
                </tr>
              </tfoot>
            </table>
          </div>
          {checkout && (<button className="cartcheck-button mb-3" onClick={() => navigate('/checkout')}>Checkout</button>)}
        </div>
      ) : (
        <p>Your cart is empty.</p>
      )}
    </div>
  );
};

export default CartComponent;

