import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import cart from '../../assets/images/cart.png';
import food1 from '../../assets/images/food1.png';
import { calculateDeliveryFee, updateQuantity } from "../../store/slice/cartSlice";
import moment from "moment";
import { AddEditOrderDetails } from "../../common/Api";
import { errorMsg, successMsg } from "../../common/Toastify";
import { NonEmptyValidation, PhoneNumberValidation } from "../../common/validate";
import { imageBaseUrl } from "../../common/BaseUrl";



const OrderDetails = () => {
  const location = useLocation();
  const { OrderProductDetails } = location.state || {};
  const orderStatus = OrderProductDetails?.order_status

  const configData = useSelector((state) => state.appconfig);
  useEffect(() => {
    if (location.state?.currentStep) {
      setCurrentStep(location.state.currentStep);
    }
  }, [location.state]);
  const isProductDetails = OrderProductDetails && typeof OrderProductDetails === "object" && Object.keys(OrderProductDetails).length > 0;

  const progressSteps = [
    { icon: "fa-cart-shopping", label: "Order Placed", status: "ordered" },
    { icon: "fa-check", label: "Accepted", status: "accepted" },
    { icon: "fa-truck", label: "Out For Delivery", status: "out-for-delivery" },
    { icon: "fa-check-to-slot", label: "Delivered", status: "delivered" },
  ];

  const currentStepIndex = progressSteps.findIndex(
    (step) => step.status === orderStatus
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { items, subtotal } = useSelector((state) => state.cart);
  useEffect(() => {
    dispatch(calculateDeliveryFee());
  }, [items, dispatch]);

  const [currentStep, setCurrentStep] = useState(1);

  useEffect(() => {
    if (location.state?.currentStep) {
      setCurrentStep(location.state.currentStep);
    }
  }, [location.state]);


  const progressWidth = ((currentStep - 1) / (progressSteps.length - 1)) * 90;

  const [editOrder, setEditOrder] = useState(false);
  const [EditOrderdetails, setEditOrderdetails] = useState(OrderProductDetails);
  const [editedOrderDetails, setEditedOrderDetails] = useState({});

  const [EditedOrderAddress, setEditedOrderAddress] = useState(OrderProductDetails?.delivery_details);

  const getSubtotal = () =>
    (EditOrderdetails?.Products || []).reduce((total, item) => {
      const quantity = editedOrderDetails[item.id]?.quantity || item.quantity;
      return total + item.price * quantity;
    }, 0);

  const Updatedsubtotal = getSubtotal();
  const grandTotal = Updatedsubtotal + (configData?.delivery_charge || 0);

  const [validation, setValidation] = useState({});


  const handleOrderAddressEdit = (value, key) => {
    setEditedOrderAddress({ ...EditedOrderAddress, [key]: value })
  }

  const handleSaveChanges = () => {
    const updatedProducts = (EditOrderdetails?.Products || []).map((item) => ({
      ...item,
      quantity: editedOrderDetails[item.id]?.quantity || item.quantity,
      product_subtotal: editedOrderDetails[item.id]?.quantity * editedOrderDetails[item.id]?.price || item.quantity * item.price,
    }));

    const validate = {};
    validate.name = NonEmptyValidation(EditedOrderAddress?.name);
    validate.phone_number = PhoneNumberValidation(EditedOrderAddress?.phone_number);
    validate.door_number = NonEmptyValidation(EditedOrderAddress?.door_number);
    validate.street = NonEmptyValidation(EditedOrderAddress?.street);
    validate.pincode = NonEmptyValidation(EditedOrderAddress?.pincode);
    validate.landmark = NonEmptyValidation(EditedOrderAddress?.landmark);
    setValidation(validate);

    const validation_status = Object.values(validate).every((v) => v.status === true);

    let updatedDetails
    if (validation_status) {
      updatedDetails = {
        ...EditOrderdetails,
        Products: updatedProducts,
        sub_total: Updatedsubtotal,
        total_payable_amount: grandTotal,
        delivery_details: EditedOrderAddress,
      };
    }
    else {
      errorMsg("Fill All The Required Feilds")
      return;
    }

    setEditOrderdetails(updatedDetails);
    setEditOrder(false);
    EditOrderDetails(updatedDetails)
  };

  const EditOrderDetails = async (updatedDetails) => {
    let payload = {
      _id: OrderProductDetails?._id,
      Products: updatedDetails?.Products,
      sub_total: updatedDetails?.sub_total,
      total_payable_amount: updatedDetails?.total_payable_amount,
      delivery_details: EditedOrderAddress,
      schelude_date: scheduleDelivery?.schelude_date,
      schelude_time: scheduleDelivery?.schelude_time,
    }
    const response = await AddEditOrderDetails(payload)
    if (response && response?.statusCode === 200) {
      successMsg("Order Updated Succesfully")
      setEditOrder(false)
      navigate("/admin/order")
    }
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split("T")[0];
  };

  const [scheduleDelivery, setScheduleDelivery] = useState({
    schelude_date: formatDate(OrderProductDetails?.schelude_date),
    schelude_time: OrderProductDetails?.schelude_time,
  });

  const handleScheduleDelivery = (value, key) => {
    setScheduleDelivery({ ...scheduleDelivery, [key]: value });
  };

  return (

    <div className="order-details-container">
      <div className="stepper-wrapper">
        <div className="d-flex justify-content-between">
          <div className="card-header border-2 text-primary bg-warn mt-2">
            <span>Order#: {OrderProductDetails?.order_id}</span>
          </div>
          <div>
            <button className="backbutton float-end mb-2"
              onClick={() => navigate('/admin/order')}
            >
              <i className="fa-solid  fa-angles-left "></i> &nbsp;
              back to orders
            </button>
          </div>
        </div>
        <div className={OrderProductDetails?.order_status !== "decline" ? "stepper-progress-container" : ""}>
          <div
            className="stepper-progress bg-warning border-warn"
            style={{ width: `${progressWidth}%` }}
          ></div>
          {OrderProductDetails?.order_status !== "decline" ?
            progressSteps?.map((step, index) => (
              <div
                key={index}
                className={`stepper-progress-step ${index <= currentStepIndex ? "active border-danger" : ""}`}
              >
                <i className={`fa-solid ${step.icon} text-danger ${index <= currentStepIndex ? 'active bg-warning border-danger' : ''}`}></i>
                <p className="step-label mb-0">{step.label}</p>
                <p className="mb-0 text-center">
                  {index === 0 && moment(OrderProductDetails?.createdAt).format('DD/MM/YYYY hh:mm A')}
                  {index === 1 && (
                    OrderProductDetails?.order_accepted_at
                      ? moment(OrderProductDetails?.order_accepted_at).format('DD/MM/YYYY hh:mm A')
                      : "Not Yet Accepted"
                  )}
                  {index === 2 && (
                    OrderProductDetails?.order_outfordelivery_at
                      ? moment(OrderProductDetails?.order_outfordelivery_at).format('DD/MM/YYYY hh:mm A')
                      : "Not Yet Out-For Delivery"
                  )}
                  {index === 3 && (
                    OrderProductDetails?.order_delivered_at
                      ? moment(OrderProductDetails?.order_delivered_at).format('DD/MM/YYYY hh:mm A')
                      : "Not Yet Delivered"
                  )}
                </p>
              </div>
            ))
            :
            <div className="decline_reason_div">
              <h4 className="text-center decline_head">Reason for Order Decline!!!</h4>
              <p className="fw-bold">
                {OrderProductDetails?.order_decline_reason}
              </p>
              <div className="d-flex justify-content-between">
                <p className="mb-0 fw-bold">Order Placed At : {moment(OrderProductDetails?.createdAt).format("DD/MM/YYYY hh:mm A")}</p>
                <p className="mb-0 fw-bold">Order Decline At : {moment(OrderProductDetails?.order_decline_at).format("DD/MM/YYYY hh:mm A")}</p>
              </div>
            </div>
          }
        </div>
        <div className="stepper-btn-container">
          {/* <button
          className={`stepper-btn ${currentStep === 1 ? 'disabled' : ''}`}
          disabled={currentStep === 1}
          onClick={handlePrev}
        >
          Previous
        </button> */}
          {/* <button
          className={`stepper-btn ${
            currentStep === progressSteps.length ? 'disabled' : ''
          }`}
          disabled={currentStep === progressSteps.length}
          onClick={handleNext}
        >
          Next
        </button> */}
        </div>
        <br></br>
      </div>
      <div>
        {/* <h3 className="admin-head">Order Detail Information</h3> */}
      </div>
      <div className="order-details-content mt-5">
        <div className="row">
          {/* Customer Information */}
          {isProductDetails && (
            <div className="col-lg-6">
              <div className="customer-info">

                <h3 className="cart-title m-auto col ">Order Address</h3>

                <form className="delivery-form mt-4">
                  <div className="form-group">
                    <label className="icon-order-input">
                      <span className="icon-order">&#128100;</span>
                      <input type="text" onChange={(e) => handleOrderAddressEdit(e.target.value, "name")}
                        value={EditedOrderAddress?.name}
                        disabled={!editOrder}
                      />
                    </label>
                    {validation?.name?.message && <p className="error_message text-start">{`Name ${validation?.name?.message}` || ""}</p>}
                  </div>
                  <div className="form-group">
                    <label className="icon-order-input">
                      <span className="icon-order">&#9742;</span>
                      <input
                        type="number"
                        disabled={!editOrder}
                        onChange={(e) => handleOrderAddressEdit(e.target.value, "phone_number")}
                        value={EditedOrderAddress?.phone_number}
                      />
                    </label>
                    {validation?.phone_number?.message && <p className="error_message text-start">{`Phone Number ${validation?.phone_number?.message}` || ""}</p>}

                  </div>
                  <div className="form-group">
                    <label className="icon-order-input">
                      <span className="icon-order">&#127968;</span>
                      <input type="text"
                        disabled={!editOrder}
                        onChange={(e) => handleOrderAddressEdit(e.target.value, "door_number")}
                        value={EditedOrderAddress?.door_number}
                      />
                    </label>
                    {validation?.door_number?.message && <p className="error_message text-start">{`Door Number ${validation?.door_number?.message}` || ""}</p>}

                  </div>
                  <div className="form-group">
                    <label className="icon-order-input">
                      <span className="icon-order">&#127963;</span>
                      <input type="text"
                        value={EditedOrderAddress?.street}
                        onChange={(e) => handleOrderAddressEdit(e.target.value, "street")}
                        disabled={!editOrder}
                      />
                    </label>
                    {validation?.street?.message && <p className="error_message text-start">{`Street ${validation?.street?.message}` || ""}</p>}
                  </div>
                  <div className="form-group">
                    <label className="icon-order-input">
                      <span className="icon-order">&#127759;</span>

                      <input type="text"
                        value={EditedOrderAddress?.pincode}
                        onChange={(e) => handleOrderAddressEdit(e.target.value, "pincode")}
                        disabled={!editOrder}
                      />

                    </label>
                    {validation?.pincode?.message && <p className="error_message text-start mb-3">{`Pincode ${validation?.pincode?.message}` || ""}</p>}
                  </div>
                  <div className="form-group">
                    <label className="icon-order-input">
                      <span className="icon-order">&#128205;</span>
                      <input type="text"
                        disabled={!editOrder}
                        onChange={(e) => handleOrderAddressEdit(e.target.value, "landmark")}
                        value={EditedOrderAddress?.landmark}
                      />
                    </label>
                    {validation?.landmark?.message && <p className="error_message text-start">{`Landmark ${validation?.landmark?.message}` || ""}</p>}
                  </div>
                </form>
                <div className="mt-5 mb-3">
                  <h3 className="cart-title m-auto col ">Schelude Delivery</h3>
                </div>
                <div className="form-group">
                  <label className="icon-input">
                    <span className="icon">&#128197;</span>
                    <input
                      type="date"
                      className="bg-white"
                      onChange={(e) => handleScheduleDelivery(e.target.value, "schelude_date")}
                      value={scheduleDelivery?.schelude_date || ""}
                    />
                  </label>
                </div>
                <div className="form-group">
                  <label className="icon-input">
                    <span className="icon">&#9200;</span>
                    <input type="time" className="bg-white" value={scheduleDelivery?.schelude_time || ""}
                      // min={currentTime}
                      onChange={(e) => handleScheduleDelivery(e.target.value, "schelude_time")}
                    />
                  </label>
                </div>
              </div>
            </div>
          )}
          {/* Products Information */}
          {isProductDetails && (
            <div className="col-lg-6">
              <div className="d-flex justify-content-between mb-2">
                <h3 className="cart-title mx-auto">Order Summary</h3>
                {OrderProductDetails?.order_status !== "decline" && (
                  <button className="btn mb-3 float-end col-3 border my-auto" onClick={() => {
                    setEditOrder(!editOrder);
                  }}>
                    Edit
                  </button>
                )}
              </div>
              <div className='cart'>
                {OrderProductDetails?.Products?.length > 0 ? (
                  <div className="cart-content">
                    <table className="cart-table">
                      <thead>
                        <tr>
                          <th>Item</th>
                          <th>Quantity</th>
                          <th>Total Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        {EditOrderdetails?.Products?.map((item, idx) => {
                          const updatedQuantity = editedOrderDetails[item.id]?.quantity || item.quantity;
                          return (
                            <tr key={idx} className="order-product-para-admin">
                              <td>
                                <div className="item-info">
                                  <img src={imageBaseUrl + item?.image} alt="..food1" style={{ width: '50px', marginRight: '10px' }} />
                                  <div className="price-info">
                                    <span className="price-name">{item.name}</span>
                                    <span className="price-weight">{item.unit}</span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="quantity-control">
                                  {editOrder && (
                                    <button
                                      onClick={() => {
                                        const newQuantity = Math.max(updatedQuantity - 1, 1);
                                        setEditedOrderDetails((prev) => ({
                                          ...prev,
                                          [item.id]: { ...item, quantity: newQuantity },
                                        }));
                                      }}
                                    >
                                      <i className="fas fa-minus"></i>
                                    </button>
                                  )}


                                  <span>{updatedQuantity}</span>

                                  
                                  {editOrder && (
                                    <button
                                      onClick={() => {
                                        const newQuantity = updatedQuantity + 1;
                                        setEditedOrderDetails((prev) => ({
                                          ...prev,
                                          [item.id]: { ...item, quantity: newQuantity },
                                        }));
                                      }}
                                    >
                                      <i className="fas fa-plus"></i>
                                    </button>
                                  )}
                                </div>
                              </td>
                              <td>₹{item.price * updatedQuantity}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colSpan="2">Subtotal:</td>
                          <td>₹{Updatedsubtotal}</td>
                        </tr>
                        <tr>
                          <td colSpan="2">Delivery Charges:</td>
                          <td>₹{configData?.delivery_charge}</td>
                        </tr>
                        <tr>
                          <td colSpan="2">Grand Total:</td>
                          <td>₹{grandTotal}</td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                ) : (
                  <p>Your Order is empty.</p>
                )}
              </div>
            </div>
          )}
          {editOrder && (
            <button className="btn btn-primary" onClick={handleSaveChanges}>
              Save Changes
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
