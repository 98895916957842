import React, { useEffect, useState } from "react";
import FreshIcon from "../assets/images/fresh (1) 1.png";
import ServeIcon from "../assets/images/serve.png";
import DeliveryIcon from "../assets/images/fast-delivery 2.png";
import briyani from "../assets/images/briyani.png";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Navbarcontainer";
import Footer from "./Footerpage";
import { GetProductByCategory } from "../common/Api";
import AOS from "aos";
import "aos/dist/aos.css";
import { useDispatch, useSelector } from "react-redux";
import { addToCart, calculateDeliveryFee } from "../store/slice/cartSlice";
import { imageBaseUrl } from "../common/BaseUrl";

function Home() {
  const navigate = useNavigate();
  const { items } = useSelector((state) => state.cart);
  const configData = useSelector((state) => state.appconfig);
  console.log(configData,"configData--configData")

  const dispatch = useDispatch();
  const [category] = useState([]);
  const [products, setProducts] = useState([]);
  const [categoryId, setCategoryId] = useState(
    configData?.home_page_menus && Array.isArray(configData.home_page_menus) && configData.home_page_menus.length > 0
      ? configData.home_page_menus[0]._id
      : null
  );
  const [cartCount, setCartCount] = useState(0);
  const Getproducts = async () => {
    if (!categoryId) return;
    const response = await GetProductByCategory(categoryId);
    if (response && response?.statusCode === 200) {
      setProducts(response?.data);
    }
  };

  useEffect(() => {
    if (category.length > 0) {
      setCategoryId(category[0]._id);
    }
  }, [category]);

  useEffect(() => {
    dispatch(calculateDeliveryFee());
  }, [items, dispatch]);

  useEffect(() => {
    if (categoryId) {
      Getproducts();
    }
  }, [categoryId]);

  useEffect(() => {
    AOS.init({
      duration: 1300,
      once: false,
    });
    AOS.refresh();
  }, []);

  const handleAddToCart = () => {
    setCartCount(cartCount + 1);
  };

  return (
    <div className="overflow-hidden">
      <Navbar cartCount={cartCount} />
      <div className="container-fluid text-center container-image" data-aos="fade-in" data-aos-offset="150" data-aos-easing="ease-in-out">
        <button className="primaryButton">
          <svg className="icon" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="white">
            <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 6.42 3.42 5 5.5 5c1.74 0 3.41.81 4.5 2.09C11.09 5.81 12.76 5 14.5 5 16.58 5 18 6.42 18 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z" />
          </svg>
          People Trust Us
        </button>
        <div className="headertext">
          <h1 className="header" data-aos="slide-right">
            Best Food For
          </h1>
          <h1 className="header1" data-aos="slide-left">
            Your Taste
          </h1>
          <p className="paragraph" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-offset="150">
            Discover delectable cuisine and unforgettable moments in our welcoming, culinary haven.
          </p>
        </div>
        <div className="buttonGroup">
          <button className="secondaryButton1" data-aos="fade-up-right">
            Book A Table
          </button>
          <a href="/menu">
            <button className="secondaryButton" data-aos="fade-up-left">
              Explore Menu
            </button>
          </a>
        </div>
      </div>
      <div className="card" data-aos="zoom-in-up">
        <div className="feature">
          <img className="featureIcon" src={FreshIcon} alt="High Quality" />
          <div>
            <h3 className="featureTitle">Fresh Ingredients</h3>
            <p className="featureText">All of our products are made with the finest quality ingredients and guaranteed fresh.</p>
          </div>
        </div>
        <div className="feature">
          <img className="featureIcon" src={ServeIcon} alt="Freshly Sourced" />
          <div>
            <h3 className="featureTitle">Served With Love</h3>
            <p className="featureText">We are passionate about serving delicious. Each of our handmade items are carefully cook with love. </p>
          </div>
        </div>
        <div className="feature">
          <img className="featureIcon" src={DeliveryIcon} alt="Guaranteed Fresh" />
          <div>
            <h3 className="featureTitle">On Time Delivery</h3>
            <p className="featureText">We are very punctual and promise to deliver fresh made products to every customer on time. </p>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="menucontainer">
          <h2 className="menuHeading" data-aos="zoom-in">
            Our Menu
          </h2>
          <div className="tabContainer">
            {configData?.home_page_menus?.map((item, index) => (
              item?.product_count !== 0 && (
                <button
                  key={index}
                  className={`tab-button ${item?._id === categoryId ? "active" : "inactive"}`}
                  onClick={() => setCategoryId(item?._id)}
                >
                  {item?.category}
                </button>
              )
            ))}
          </div>
          <div className="container">
            <div className="row foodcard " data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="2000">
              {products &&
                products.map((item, index) => (
                  <div className="col-lg-3">
                    <div className="">
                      <div className="position-relative" key={index}>
                        <img className="cardimage" src={`${imageBaseUrl + item?.image}`} alt="food_images" />

                        <div className="gradient-border">
                          <div className="price-details">
                            <div className="d-flex">
                              <span className="price-amount">₹{item?.final_price}</span>
                              {item?.base_price != null && item?.base_price !== 0 && (
                                <span className="price-amount ms-1" style={{ textDecoration: "line-through" }}>
                                  ₹{item?.base_price}
                                </span>
                              )}
                            </div>
                            <hr className="m-0" />
                            <span className="price-weight">{item?.unit}</span>
                          </div>
                        </div>
                        <div className="card1">
                          <div className="card-body">
                            <h5 className="card-title">{item?.product_name}</h5>
                            <p className="card-text">{item?.description}</p>
                            <div className="button-res">

                              {item?.stock === "in_stock" ? <button
                                className="check-button1"
                                onClick={() => {
                                  dispatch(
                                    addToCart({
                                      id: item?._id,
                                      name: item?.product_name,
                                      price: item?.final_price,
                                      image: item?.image,
                                      unit: item?.unit,
                                    })
                                  );
                                  handleAddToCart();
                                }}
                              >
                               + Add
                              </button> :
                                <button
                                  className="check-button1 bg-danger text-white"
                                  disabled
                                  style={{cursor:"none"}}
                                >
                                  Out Of Stock
                                </button>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div className="buttonGroup" data-aos="zoom-out">
            <a href="/menu">
              <button className="view-button">View All</button>
            </a>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="cardcontainer">
          <div className="row">
            <div className="col-lg-5 pe-0" data-aos="fade-right">
              <div className="col-title">
                <h3 className="header-title">Authentic Biryani</h3>
                <h3 className="header1-title">Delicious Flavors</h3>
                <p className="paragraph-card">
                  A symbol of rich culinary heritage and exquisite taste for centuries.
                  Each grain of rice is perfectly cooked and infused with aromatic spices, delivering an unforgettable experience.
                  A perfect blend of tradition and flavor.
                </p>

                {/* <button className='order-button'>PROCEED TO ORDER</button> */}
              </div>
            </div>
            <div className="col-lg-7 p-lg-0 footerimg" data-aos="fade-left">
              <img className="imagecontainer" src={briyani} alt="briayni" />
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid p-0">
        <div className="text-center footer-image">
          <h1 className="footer-header" data-aos="fade-right">
            Are you ready to order with
          </h1>
          <h1 className="header1" data-aos="fade-left">
            the best deals?
          </h1>
          <div className="d-flex justify-content-center">
            <button className="order-button1" onClick={() => navigate("/menu")}>
              PROCEED TO ORDER
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
