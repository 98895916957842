import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import Layout from "../container/Layout";

const RoleBasedRoute = ({ Component, roles }) => {
  const { userType } = useSelector((state) => state?.login);

  return roles.includes(userType) ? (
    <Layout>
      {typeof Component === "object" ? Component :
      <Component />}
    </Layout>
  ) : (
    <Navigate to="/adminlogin" />
  );
};

export default RoleBasedRoute;
