import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { errorMsg, successMsg } from '../../common/Toastify';
import { AdminUpdateUser, AdminUserListing } from '../../common/Api';
import moment from 'moment';
const Users = () => {
    const [getAllUser, setGetAllUser] = useState([]);
    const GetAllusers = async () => {
        const response = await AdminUserListing()
        if (response && response?.statusCode === 200) {
            setGetAllUser(response?.data)
        }
    }
    useEffect(() => {
        GetAllusers()
    }, []);

    const UpdateUserStatus = async (status, user_id) => {
        let updated_status = status === "active" ? "in-active" : "active"

        let payload = {
            status: updated_status,
            _id: user_id
        }
        const response = await AdminUpdateUser(payload);
        if (response && response?.statusCode === 200) {
            GetAllusers()
            successMsg(`User Status Updated Successfully`);
        } else {
            errorMsg(response?.message);
        }
    }
    return (
        <div>
            <div>
                <div className='d-flex justify-content-between'>
                    <h3 className='admin_head'>All Users</h3>
                    {/* <button className='admin_button mt-0' onClick={() => { navigate("/admin/add-product") }}>
                + Add a Product
            </button> */}
                </div>
                <div className="tabular_column_parent mt-5">
                    <div className="row">
                        <div className="col-lg-1">
                            <div className="d-flex justify-content-center tabular_heading">
                                <h4>S.No</h4>
                            </div>
                        </div>
                        <div className="col-lg-2">
                            <div className="d-flex justify-content-center tabular_heading">
                                <h4>User</h4>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="d-flex justify-content-center tabular_heading">
                                <h4>Email Address</h4>
                            </div>
                        </div>
                        <div className="col-lg-2">
                            <div className="d-flex justify-content-center tabular_heading">
                                <h4>Phone Number</h4>
                            </div>
                        </div>
                        <div className="col-lg-2">
                            <div className="d-flex justify-content-center tabular_heading">
                                <h4>Date</h4>
                            </div>
                        </div>
                        <div className="col-lg-2">
                            <div className="d-flex justify-content-center tabular_heading">
                                <h4>Action</h4>
                            </div>
                        </div>
                    </div>
                </div>
                {getAllUser?.length > 0 && getAllUser.map((item, index) => (
                    <div className='tabular_column_data_parent mt-4' key={index}>
                        <div className='row'>
                            <div className="col-lg-1">
                                <div className="d-flex justify-content-center tabular_heading">
                                    <h4>{index + 1}</h4>
                                </div>
                            </div>
                            <div className='col-lg-2'>
                                <div className='d-flex justify-content-center tabular_heading'>
                                    {/* <img src={item_1} alt="product_image" /> */}
                                    <h6>{item?.name}</h6>
                                </div>
                            </div>
                            <div className='col-lg-3'>
                                <div className='d-flex justify-content-center tabular_heading'>
                                    <h6>{item?.email_id}</h6>
                                </div>
                            </div>
                            <div className='col-lg-2'>
                                <div className='d-flex justify-content-center tabular_heading'>
                                    <h6>{item?.phone_number}</h6>
                                </div>
                            </div>

                            <div className='col-lg-2'>
                                <div className='d-flex justify-content-center tabular_heading'>
                                    <h6>{moment(item?.createdAt).format('DD/MM/YYYY')}</h6>
                                </div>
                            </div>

                            <div className='col-lg-2'>
                                <div className='d-flex justify-content-center tabular_heading cur_pointer'>
                                    <label class="switch ms-3">
                                        <input type="checkbox"
                                            checked={item?.status === "active" ? true : false}
                                            onClick={() => {
                                                // setCatgeory(item);
                                                UpdateUserStatus(item?.status, item?._id)
                                            }} />
                                        <span class="slider round"></span>
                                    </label>
                                </div>
                            </div>
                        </div>

                    </div>
                )
                )}
            </div>
        </div>
    )
}

export default Users
