import React, { useState, useEffect } from "react";
import { AddNewAdmin, AdminChangePassword, AdminProfileData, AdminUpdateProfile } from "../common/Api";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { EmailValidation, NonEmptyValidation, PasswordValidation, PhoneNumberValidation } from "../common/validate";
import { errorMsg, successMsg } from "../common/Toastify";

const ChangePwd = () => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("profile");
  const [validation, setValidation] = useState({});
  const [adminprofileData, setAdminprofileData] = useState({});
  const [adminChangePassword, setAdminChangePassword] = useState({});
  const [addNewAdmin, setAddNewAdmin] = useState({});
  const [showPassword, setShowPassword] = useState();
  const { _id } = useSelector((state) => state?.login);
  const navigate = useNavigate();

  const { EditAdminDetails } = location.state || {};

  const isAdminEditMode = EditAdminDetails && typeof EditAdminDetails === "object" && Object.keys(EditAdminDetails).length > 0;

  useEffect(() => {
    if (isAdminEditMode) {
      setAddNewAdmin(EditAdminDetails);
    }
  }, []);


  const handleChangePassword = (value, key) => {
    setAdminChangePassword({ ...adminChangePassword, [key]: value })
    if (validation[key]) setValidation({ ...validation, [key]: false });
  }

  const handleChangeNewAdmin = (value, key) => {
    setAddNewAdmin({ ...addNewAdmin, [key]: value })
    if (validation[key]) setValidation({ ...validation, [key]: false });
  }

  const handleEyeClick = (key, value) => {
    setShowPassword({
      ...showPassword,
      [key]: value
    })
  }

  const handleChangePasswordSubmit = async (e) => {
    e.preventDefault();
    let validate = {};
    validate.old_password = PasswordValidation(adminChangePassword?.old_password);
    validate.new_password = PasswordValidation(adminChangePassword?.new_password);
    validate.confirm_password = PasswordValidation(adminChangePassword?.confirm_password);
    setValidation(validate)
    const validation_status = Object.values(validate).every((v) => v.status === true);
    if (validation_status) {
      if (adminChangePassword?.new_password !== adminChangePassword?.confirm_password) {
        errorMsg("New password and Confirm Password Must be equal")
        return;
      }
      let payload = {
        _id: _id,
        old_password: adminChangePassword?.old_password,
        new_password: adminChangePassword?.new_password
      }
      const response = await AdminChangePassword(payload)
      if (response && response?.statusCode === 200) {
        successMsg("Password Changed Successfully")
        setAdminChangePassword({})
      }
      else {
        errorMsg(response?.message)
      }
    }
  }

  const handleAddNewAdmin = async (e) => {
    e.preventDefault();
    let validate = {};
    validate.name = NonEmptyValidation(addNewAdmin?.name);
    validate.email_id = EmailValidation(addNewAdmin?.email_id, 'Email ');
    validate.phone_number = PhoneNumberValidation(addNewAdmin?.phone_number);
    validate.password = PasswordValidation(addNewAdmin?.password);
    setValidation(validate)
    const validation_status = Object.values(validate).every((v) => v.status === true);
    if (validation_status) {
      let payload = {
        name: addNewAdmin?.name,
        email_id: addNewAdmin?.email_id,
        phone_number: addNewAdmin?.phone_number,
        password: addNewAdmin?.password,
      }
      const response = await AddNewAdmin(payload)
      if (response && response?.statusCode === 200) {
        successMsg("Admin Added Successfully")
        setAddNewAdmin({})
        navigate(-1)
        setValidation({})
      }
      else {
        errorMsg(response?.message)
      }
    }
  }

  const handleUpdateAdmin = async (e) => {
    e.preventDefault();
    let validate = {};
    validate.name = NonEmptyValidation(addNewAdmin?.name);
    validate.email_id = EmailValidation(addNewAdmin?.email_id, 'Email ');
    validate.phone_number = PhoneNumberValidation(addNewAdmin?.phone_number);
    setValidation(validate)
    const validation_status = Object.values(validate).every((v) => v.status === true);
    if (validation_status) {
      let payload = {
        _id: addNewAdmin?._id,
        name: addNewAdmin?.name,
        email_id: addNewAdmin?.email_id,
        phone_number: addNewAdmin?.phone_number,
      }
      const response = await AdminUpdateProfile(payload)
      if (response && response?.statusCode === 200) {
        successMsg("Admin updated Successfully")
        setAddNewAdmin({})
        navigate(-1);
        setValidation({})
      }
      else {
        errorMsg(response?.message)
      }
    }
  }


  const GetAdminProfileDetails = async () => {
    const response = await AdminProfileData(_id)
    if (response && response?.statusCode === 200) {
      setAdminprofileData(response?.data)
    }
  }
  useEffect(() => {
    GetAdminProfileDetails()
  }, []);

  useEffect(() => {
    if (location.hash === "#password") {
      setActiveTab("password");
    }
    else if (location.hash === "#profile") {
      setActiveTab("profile");
    }
    else {
      setActiveTab("addadmin");
    }
  }, [location]);

  return (
    <div className="container">

      {activeTab === "profile" && (
        <div className="row justify-content-center">
          <div className="col-lg-7">
            <div className="pt-4 ">
              <h3 className="fw-bold app_menu_para" style={{ fontSize: "25px" }}>Profile Details</h3>
              <div className="form-group ">
                <label className="app_menu_para mb-2">
                  Name:
                </label>
                <input
                  type="text"
                  disabled
                  className="footer_input_box"
                  value={adminprofileData?.name}
                // onChange={(e) => handleChange(e.target.value, "name")}
                />
              </div>
              <div className="form-group mt-1">
                <div className="form-group ">
                  <label className="app_menu_para mb-2">
                    Email Id:
                  </label>
                  <input
                    type="text"
                    className="footer_input_box"
                    value={adminprofileData?.email_id}
                    disabled
                  // onChange={(e) => handleChange(e.target.value, "name")}
                  />
                </div>
                <p className="error_message">{validation?.email_id?.message || ""}</p>
              </div>
              <div className="form-group mt-2">
                <div className="form-group ">
                  <label className="app_menu_para mb-2">
                    Phone Number:
                  </label>
                  <input
                    type="number"
                    disabled
                    className="footer_input_box"
                    value={adminprofileData?.phone_number}
                  />
                </div>
              </div>
              {/* <button className="w-100">Save</button> */}
            </div>
          </div>
        </div>
      )}
      {activeTab === "password" && (
        <div className="row justify-content-center">
          <div className="col-lg-7">
            <div className="pt-4 ">
              <h3 className="fw-bold app_menu_para" style={{ fontSize: "25px" }}>Change Password</h3>
              <div className="form-group position-relative">
                <label className="app_menu_para mb-2 ">
                  Old Password
                </label>
                <input
                  type={showPassword?.oldpassword ? "text" : "password"}
                  className="footer_input_box "
                  placeholder="Old Password"
                  value={adminChangePassword?.old_password || ""}
                  onChange={(e) => handleChangePassword(e.target.value, "old_password")}
                />
                {
                  showPassword?.oldpassword ?
                    <i
                      className="fa-solid fa-eye password_eye"
                      onClick={() => handleEyeClick("oldpassword", false)}
                    ></i> :
                    <i className="fa-solid fa-eye-slash password_eye"
                      onClick={() => handleEyeClick("oldpassword", true)}
                    ></i>
                }
                {validation?.old_password?.message && <p className="error_message text-start">{`Old password ${validation?.old_password?.message}` || ""}</p>}

              </div>
              <div className="form-group mt-1 position-relative">
                <div className="form-group ">
                  <label className="app_menu_para mb-2 ">
                    New Password
                  </label>
                  <input
                    type={showPassword?.newpassword ? "text" : "password"}
                    className="footer_input_box"
                    placeholder="New Password"
                    value={adminChangePassword?.new_password || ""}
                    onChange={(e) => handleChangePassword(e.target.value, "new_password")}
                  />
                  {
                    showPassword?.newpassword ?
                      <i
                        className="fa-solid fa-eye password_eye"
                        onClick={() => handleEyeClick("newpassword", false)}
                      ></i> :
                      <i className="fa-solid fa-eye-slash password_eye"
                        onClick={() => handleEyeClick("newpassword", true)}
                      ></i>
                  }

                  {validation?.new_password?.message && <p className="error_message text-start">{`New password ${validation?.new_password?.message}` || ""}</p>}

                </div>
                <p className="error_message">{validation?.email_id?.message || ""}</p>
              </div>
              <div className="form-group mt-2 position-relative">
                <div className="form-group ">
                  <label className="app_menu_para mb-2">
                    Confirm Password
                  </label>
                  <input
                    type={showPassword?.confirmpassowrd ? "text" : "password"}
                    className="footer_input_box"
                    placeholder="Confirm Password"
                    value={adminChangePassword?.confirm_password || ""}
                    onChange={(e) => handleChangePassword(e.target.value, "confirm_password")}
                  />
                  {
                    showPassword?.confirmpassowrd ?
                      <i
                        className="fa-solid fa-eye password_eye"
                        onClick={() => handleEyeClick("confirmpassowrd", false)}
                      ></i> :
                      <i className="fa-solid fa-eye-slash password_eye"
                        onClick={() => handleEyeClick("confirmpassowrd", true)}
                      ></i>
                  }

                  {validation?.confirm_password?.message && <p className="error_message text-start">{`Confirm password ${validation?.confirm_password?.message}` || ""}</p>}

                </div>
              </div>
              <button className="w-100" onClick={(e) => handleChangePasswordSubmit(e)}>Save</button>
            </div>
          </div>
        </div>
      )}
      {activeTab === "addadmin" && (
        <div className="row justify-content-center">
          <div className="col-lg-7">
            <div className="pt-4 ">
              <h3 className="fw-bold app_menu_para" style={{ fontSize: "25px" }}>{isAdminEditMode ? "Edit Admin Details" : "Add New Admin"} </h3>
              <div className="form-group ">
                <label className="app_menu_para mb-2">
                  Name
                </label>
                <input
                  type="text"
                  className="footer_input_box"
                  value={addNewAdmin?.name || ""}
                  placeholder="Enter Name"
                  onChange={(e) => handleChangeNewAdmin(e.target.value, "name")}
                />
                {validation?.name?.message && <p className="error_message text-start">{`Name ${validation?.name?.message}` || ""}</p>}
              </div>
              <div className="form-group mt-1">
                <div className="form-group ">
                  <label className="app_menu_para mb-2">
                    Email Id
                  </label>
                  <input
                    type="text"
                    className="footer_input_box"
                    value={addNewAdmin?.email_id || ""}
                    placeholder="Enter Email"
                    onChange={(e) => handleChangeNewAdmin(e.target.value, "email_id")}
                  />
                  {validation?.email_id?.message && <p className="error_message text-start">{`Email  Id ${validation?.email_id?.message}` || ""}</p>}
                </div>
              </div>
              <div className="form-group mt-2">
                <div className="form-group ">
                  <label className="app_menu_para mb-2">
                    Phone Number
                  </label>
                  <input
                    type="number"
                    placeholder="Enter Phone Number"
                    className="footer_input_box"
                    value={addNewAdmin?.phone_number || ""}
                    onChange={(e) => handleChangeNewAdmin(e.target.value, "phone_number")}
                  />
                  {validation?.phone_number?.message && <p className="error_message text-start">{`Phone Number ${validation?.phone_number?.message}` || ""}</p>}
                </div>
              </div>
              {!isAdminEditMode && (
                <div className="form-group mt-1">
                  <div className="form-group ">
                    <label className="app_menu_para mb-2">
                      Password
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Password"
                      className="footer_input_box"
                      value={addNewAdmin?.password || ""}
                      onChange={(e) => handleChangeNewAdmin(e.target.value, "password")}
                    />
                    {validation?.password?.message && <p className="error_message text-start">{`Password ${validation?.password?.message}` || ""}</p>}
                  </div>

                </div>
              )}
              <div className="d-flex">
                <button className="w-100"
                onClick={()=>navigate(-1)}
                >Cancel</button>
                <button className="w-100" onClick={(e) => {
                  if (isAdminEditMode) {                 
                    handleUpdateAdmin(e)
                  } else {
                    handleAddNewAdmin(e)
                  }

                }}>{isAdminEditMode ? "Update Admin Details" : "Add New Admin"}</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChangePwd;
