import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import CustomModal from '../../common/CustomModel'
import { AdminDeleteProducts, AdminGetAllProducts, AdminUpdateProducts } from '../../common/Api'
import { errorMsg, successMsg } from '../../common/Toastify'
import AddProduct from './AddProduct'
import { imageBaseUrl } from '../../common/BaseUrl'


const Product = () => {
    const navigate = useNavigate()
    const [addCatModel, setAddCatModel] = useState(false)
    const [deleteModel, setDeleteModel] = useState(false)
    const [editModel, setEditModel] = useState(false)
    const [allProduct, setAllProduct] = useState([]);
    const [deleteProduct, setDeleteProduct] = useState({});

    const GetAllProduct = async () => {
        const response = await AdminGetAllProducts()
        if (response && response?.statusCode === 200) {
            setAllProduct(response?.data)
        }
        
    }
    const UpdateProductStatus = async (status, product_id) => {
        let updated_status = status === "active" ? "in-active" : "active"
        let payload = {
            status: updated_status,
            _id: product_id
        }
        const response = await AdminUpdateProducts(payload);
        if (response && response?.statusCode === 200) {
            GetAllProduct()
            successMsg(`Product Updated Successfully`);
        } else {
            errorMsg(response?.message);
        }
    }
    const DeleteProduct = async (product_id) => {
        let payload = {
            _id: product_id
        }
        const response = await AdminDeleteProducts(payload);
        if (response && response?.statusCode === 200) {
            GetAllProduct()
            successMsg(`Product Deleted Successfully`);
            setDeleteProduct({})
        } else {
            errorMsg(response?.message);
        }
    }
    const handleEditProduct = (item) => {
        navigate("/admin/add-product", {
            state: { EditproductDetails: item },
        });
    }
    useEffect(() => {
        GetAllProduct()
    }, []);


    return (
        <div>
            <div>
                <div className='d-flex justify-content-between'>
                    <h3 className='admin_head'>Product Listing</h3>
                    <button className='admin_button mt-0' onClick={() => { navigate("/admin/add-product") }}>
                        + Add Product
                    </button>
                </div>

                <div className='tabular_column_parent mt-5'>
                    <div className='row'>
                        <div className="col-lg-1">
                            <div className="d-flex justify-content-center tabular_heading">
                                <h4>S.No</h4>
                            </div>
                        </div>
                        <div className='col-lg-3'>
                            <div className='d-flex justify-content-center tabular_heading'>
                                <h4>Product</h4>
                            </div>
                        </div>
                        <div className='col-lg-2'>
                            <div className='d-flex justify-content-center tabular_heading'>
                                <h4>Category</h4>
                            </div>
                        </div>
                        <div className='col-lg-2'>
                            <div className='d-flex justify-content-center tabular_heading'>
                                <h4>Price</h4>
                            </div>
                        </div>
                        <div className='col-lg-1'>
                            <div className='d-flex justify-content-center tabular_heading'>
                                <h4>Unit</h4>
                            </div>
                        </div>
                        <div className='col-lg-1'>
                            <div className='d-flex justify-content-center tabular_heading'>
                                <h4>Stock</h4>
                            </div>
                        </div>
                        <div className='col-lg-2'>
                            <div className='d-flex justify-content-center tabular_heading'>
                                <h4>Actions</h4>
                            </div>
                        </div>
                    </div>
                </div>

                {allProduct?.length > 0 && allProduct.map((item, index) => (
                    <>
                        <div className='tabular_column_data_parent mt-5' key={index}>
                            <div className='row'>
                                <div className="col-lg-1">
                                    <div className="d-flex justify-content-center tabular_heading">
                                        <h4>{index + 1}</h4>
                                    </div>
                                </div>
                                <div className='col-lg-3'>
                                    <div className='d-flex justify-content-center tabular_heading'>
                                        <h6>{item?.product_name}</h6>
                                    </div>
                                </div>
                                <div className='col-lg-2 d-flex justify-content-center'>
                                    <div className='d-flex tabular_heading flex-column' style={{overflowWrap:"anywhere", textAlign:"center"}}>
                                        <h6 className='category'>{item?.category_name?.[0]}</h6>
                                        <h6 className='category'>{item?.category_name?.[1]}</h6>
                                    </div>
                                </div>
                                <div className='col-lg-2'>
                                    <div className='d-flex justify-content-center tabular_heading'>
                                        <h6 className='me-2'>{item?.final_price}</h6>
                                        <h6 className='' style={{ textDecoration: "line-through" }}>{item?.base_price !== 0 && item?.base_price}</h6>
                                    </div>
                                </div>
                                <div className='col-lg-1'>
                                    <div className='d-flex justify-content-center tabular_heading'>
                                        <h6>{item?.unit}</h6>
                                    </div>
                                </div>
                                <div className='col-lg-1'>
                                    <div className='d-flex justify-content-center tabular_heading'>
                                        <h6>{item?.stock === "in_stock" ? "In Stock" : "Out Of Stocks"}</h6>
                                    </div>
                                </div>
                                <div className='col-lg-2'>
                                    <div className='d-flex justify-content-center tabular_heading'>
                                        <div className='action_category d-flex'>
                                            <i class="fa-solid fa-pen-to-square action_icon"

                                                onClick={() => handleEditProduct(item)}
                                            />
                                            <label class="switch ms-4">
                                                <input type="checkbox" checked={item?.status === "active" ? true : false}
                                                    onClick={() => {
                                                        UpdateProductStatus(item?.status, item?._id)
                                                    }}
                                                />
                                                <span class="slider round"></span>
                                            </label>
                                            <i class="fa-solid fa-trash-can action_icon text-danger ms-4"
                                                onClick={() => {
                                                    setDeleteProduct(item)
                                                    setDeleteModel(true)
                                                }}></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ))
                }
                
            </div>
            <CustomModal open={addCatModel}>
                <div>
                    <div className='d-flex flex-column'>
                        <label className='model_label_admin'>
                            {editModel ? "Edit" : ""} Product Name
                        </label>
                        <input className='model_input_admin' />
                        <p className='error_message'>error message</p>
                    </div>
                    <div className='d-flex justify-content-between mt-3'>
                        <button
                            className="px-5"
                            onClick={() => {
                                if (editModel) {
                                    setEditModel(false);
                                    setAddCatModel(false);
                                } else {
                                    setAddCatModel(false);
                                }
                            }}
                        >
                            Cancel
                        </button>
                        <button className='px-5' >
                            {editModel ? "Update" : "Submit"}
                        </button>
                    </div>
                </div>
            </CustomModal>
            <CustomModal
                open={deleteModel}>
                <div className="logout-modal ">
                    <p className="text-center mb-4 text-black delete_para_model">
                        Are you sure do you want to delete ?
                    </p>
                    <div className="row">
                        <div className="col-6">
                            <button className='px-5' onClick={() => {
                                DeleteProduct(deleteProduct?._id);
                                setDeleteModel(false)
                            }}>Yes</button>
                        </div>
                        <div className="col-6">
                            <button onClick={() => setDeleteModel(false)} className='px-5 ms-5' style={{ backgroundColor: "red" }}>No</button>
                        </div>
                    </div>
                </div>
            </CustomModal>
        </div>
    )
}

export default Product
