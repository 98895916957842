import React, { useState } from "react";
import LogoSection from "./LogoSection";
import MenuSection from "./MenuSection";
import PincodeSection from "./PincodeSection";
import FooterConfiguration from "./FooterConfiguration";

const AppConfiguration = () => {
  const [activeTab, setActiveTab] = useState("logo");

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  const appconfig_tabs = [
    {
      label: "Logo",
      key: "logo",
    },
    {
      label: "Item Menu",
      key: "homepage",
    },
    // {
    //     label: "Menu Page",
    //     key: "menupage"
    // },
    {
      label: "Pincode",
      key: "pincode",
    },
    {
      label: "Footer Settings ",
      key: "footer",
    },
  ];
 

  return (
    <div>
      <ul className="ordertabs nav nav-tabs d-flex w-100" role="tablist">
        {appconfig_tabs.map((tab, index) => (
          <li className="nav-item flex-fill col-2" key={index}>
            <button className={`nav-link w-100 ${activeTab === tab?.key ? "active" : ""}`} onClick={() => handleTabClick(tab?.key)}>
              {tab?.label}
            </button>
          </li>
        ))}
      </ul>
      <div className="tab-content mt-4">
        {activeTab === "logo" && <LogoSection />}
        {activeTab === "homepage" && <MenuSection />}
        {activeTab === "pincode" && <PincodeSection />}
        {activeTab === "footer" && <FooterConfiguration />}
      </div>
    </div>
  );
};

export default AppConfiguration;
