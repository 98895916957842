import React from 'react'
import { useNavigate } from 'react-router-dom'
const Orders = () => {
    const navigate = useNavigate()
    return (
        <div>
            <div>
                <div className='d-flex justify-content-between'>
                    <h3 className='admin_head'>All Reviews</h3>
                    {/* <button className='admin_button mt-0' onClick={() => { navigate("/admin/add-product") }}>
                        + Add a Product
                    </button> */}
                </div>
                <div className='tabular_column_parent mt-5'>
                    <div className='row'>
                        <div className='col-lg-3'>
                            <div className='d-flex justify-content-center tabular_heading'>
                                <h4>Users</h4>
                            </div>
                        </div>
                        <div className='col-lg-3'>
                            <div className='d-flex justify-content-center tabular_heading'>
                                <h4>Ratings</h4>
                            </div>
                        </div>
                        <div className='col-lg-3'>
                            <div className='d-flex justify-content-center tabular_heading'>
                                <h4>Product</h4>
                            </div>
                        </div>
                        <div className='col-lg-3'>
                            <div className='d-flex justify-content-center tabular_heading'>
                                <h4>Date of Review</h4>
                            </div>
                        </div>
                        {/* <div className='col-lg-3'>
                            <div className='d-flex justify-content-center tabular_heading'>
                                <h4>Action</h4>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className='tabular_column_data_parent mt-4'>
                    <div className='row'>
                        <div className='col-lg-3'>
                            <div className='d-flex justify-content-center tabular_heading'>
                                {/* <img src={item_1} alt="product_image" /> */}
                                <h6>Test</h6>
                            </div>
                        </div>
                        <div className='col-lg-3'>
                            <div className='d-flex justify-content-center tabular_heading'>
                                <span className="">&#9733;</span>
                                <span className="">&#9733;</span>
                                <span className="">&#9733;</span>
                            </div>
                        </div>
                        <div className='col-lg-3'>
                            <div className='d-flex justify-content-center tabular_heading'>
                                <h6>Chicken Briyani</h6>
                            </div>
                        </div>
                        <div className='col-lg-3'>
                            <div className='d-flex justify-content-center tabular_heading'>
                                <h6>11/12/2024</h6>
                            </div>
                        </div>

                        {/* <div className='col-lg-3'>
                            <div className='d-flex justify-content-center tabular_heading cur_pointer' onClick={() => navigate("/admin/order-detail")}>
                                <p className='order_view_detail'>View Full Details</p>
                                <i class="fa-solid fa-arrow-right ms-1 mt-1 link_arrow" style={{ transform: "rotate(-39deg)" }}></i>
                            </div>
                        </div> */}
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Orders
