import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { errorMsg, successMsg } from "../../common/Toastify";
import { AdminDeleteAccount, AdminListing, AdminUpdateProfile, AdminUserListing } from "../../common/Api";
import CustomModal from "../../common/CustomModel";
import moment from "moment";
import { useSelector } from "react-redux";


const Admin = () => {
  const navigate = useNavigate();
  const [getAllAdmin, setGetAllAdmin] = useState([]);
  const [deleteModel, setDeleteModel] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [adminId, setAdminId] = useState("");
  const { _id } = useSelector((state) => state?.login);

  const GetAllAdmin = async () => {
    const response = await AdminListing();
    if (response && response?.statusCode === 200) {
      setGetAllAdmin(response?.data);
    }
  };
  useEffect(() => {
    GetAllAdmin();
  }, []);

  const UpdateAdminStatus = async (status, admin_id) => {
    let updated_status = status === "active" ? "in-active" : "active";

    let payload = {
      status: updated_status,
      _id: admin_id,
    };
    const response = await AdminUpdateProfile(payload);
    if (response && response?.statusCode === 200) {
      GetAllAdmin();
      successMsg(`Admin Status Updated Successfully`);
    } else {
      errorMsg(response?.message);
    }
  };
  const DeleteAdminAccount = async (admin_id) => {
    let payload = {
      _id: admin_id
    }
    const response = await AdminDeleteAccount(payload)
    if (response && response?.statusCode === 200) {
      successMsg("Admin Deleted Successfully")
      GetAllAdmin()
      setDeleteModel(false)
    }
  }
  const handleEditAdmin = (item) => {
    navigate("/admin/change-password#addadmin", {
      state: { EditAdminDetails: item },
    });
  }

  return (
    <div>
      <div>
        <div className="d-flex justify-content-between">
          <h3 className="admin_head">All Admin</h3>
          <button className='admin_button mt-0' onClick={() => { navigate("/admin/change-password#addadmin") }}>
            + Add New Admin
          </button>
        </div>
        <div className="tabular_column_parent mt-5">
          <div className="row">
            <div className="col-lg-1">
              <div className="d-flex justify-content-center tabular_heading">
                <h4>S.No</h4>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="d-flex justify-content-center tabular_heading">
                <h4>User</h4>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="d-flex justify-content-center tabular_heading">
                <h4>Email Address</h4>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="d-flex justify-content-center tabular_heading">
                <h4>Phone Number</h4>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="d-flex justify-content-center tabular_heading">
                <h4>Date</h4>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="d-flex justify-content-center tabular_heading">
                <h4>Action</h4>
              </div>
            </div>
          </div>
        </div>
        {getAllAdmin?.length > 0 &&
          getAllAdmin.map((item, index) => (
            <div className="tabular_column_data_parent mt-4" key={index}>
              <div className="row">
                <div className="col-lg-1">
                  <div className="d-flex justify-content-center tabular_heading">
                    <h4>{index + 1}</h4>
                  </div>
                </div>
                <div className="col-lg-2">
                  <div className="d-flex justify-content-center tabular_heading">
                    {/* <img src={item_1} alt="product_image" /> */}
                    <h6>{item?.name}</h6>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="d-flex justify-content-center tabular_heading">
                    <h6>{item?.email_id}</h6>
                  </div>
                </div>
                <div className="col-lg-2">
                  <div className="d-flex justify-content-center tabular_heading">
                    <h6>{item?.phone_number}</h6>
                  </div>
                </div>
                <div className="col-lg-2">
                  <div className="d-flex justify-content-center tabular_heading">
                    <h6>{moment(item?.createdAt).format("DD/MM/YYYY")}</h6>
                  </div>
                </div>
                <div className="col-lg-2">
                  <div className="d-flex justify-content-center tabular_heading">
                    {item?._id !== _id ?
                      <div className="action_category d-flex">
                        <i
                          class="fa-solid fa-pen-to-square action_icon"
                          onClick={() => { handleEditAdmin(item) }}
                        />
                        <label class="switch ms-4">
                          <input
                            type="checkbox"
                            checked={item?.status === "active" ? true : false}
                            onClick={() => {
                              UpdateAdminStatus(item?.status, item?._id)
                            }}
                          />
                          <span class="slider round"></span>
                        </label>
                        <i
                          class="fa-solid fa-trash-can action_icon text-danger ms-4"
                          onClick={() => {
                            setAdminId(item?._id)
                            setDeleteModel(true);
                          }}
                        ></i>
                      </div>
                      : "-"}
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
      <CustomModal open={deleteModel}>
        <div className="logout-modal ">
          <p className="text-center mb-4 text-black delete_para_model">Are you sure do you want to delete the admin?</p>
          <div className="row">
            <div className="col-6">
              <button className="px-5" onClick={() => DeleteAdminAccount(adminId)}>
                Yes
              </button>
            </div>
            <div className="col-6">
              <button onClick={() => setDeleteModel(false)} className="px-5 ms-5" style={{ backgroundColor: "red" }}>
                No
              </button>
            </div>
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default Admin;
