import React, { useState, useEffect } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import footer1 from "../assets/images/instagram.svg";
import footer2 from "../assets/images/facebook.svg";
import footer3 from "../assets/images/x-logo.svg";
import footer4 from "../assets/images/envelope.png";
import footer5 from "../assets/images/Symbol.png";
import logo from "../assets/images/gmb_logo.png";
import { UserSubcribe, AdminGetAppConfig } from "../common/Api";
import { errorMsg, successMsg } from "../common/Toastify";
import { EmailValidation } from "../common/validate";
import { useSelector } from "react-redux";

function Footer() {
  const [email, setEmail] = useState("");
  const configData = useSelector((state) => state.appconfig);
  const [emailValidation, setEmailValidation] = useState({});
  const NavMenus = [
    { path: "/", title: "Home" },
    { path: "/menu", title: "Menu" },
  ];
  const CMSMenus = [
    { path: "/terms-and-condition", title: "Terms & Condition" },
    { path: "/refund-and-cancellation", title: "Refund & Cancellation" },
    { path: "/privacy-policy", title: " Privacy Policy" }
  ];

  const Subcribe = async (e) => {
    e.preventDefault();
    let validate = {};
    validate.email_id = EmailValidation(email, "Email id");
    setEmailValidation(validate);
    if (validate?.email_id?.status === true) {
      let payload = {
        email_id: email,
      };
      const response = await UserSubcribe(payload);
      if (response && response?.statusCode === 200) {
        setEmail("");
        successMsg("Subcribed succesfully");
      } else {
        errorMsg("Already Subcribed");
      }
    }
  };

  return (
    <footer>
      <div className="d-flex justify-content-between footer">
        <div className="w-100 d-flex footer-name">
          <div className="col-lg-4">
            <img className="logo-1 footer_logo" src={logo} alt="Great Moghul Biryani Logo" />
            {configData?.address?.isShow && (
              <p className="footer-paragraph mb-0 p-0 mt-4 pe-5">
                {configData.address?.value}
              </p>
            )}
            <p className="footer-paragraph-new mt-3">Any queries, please reach out</p>
            {configData?.phone_number?.isShow && <span className="footer-contact">Contact: {configData?.phone_number?.value}</span>}

          </div>
          <div className="col-lg-2">
            <div className="footer-col ">
              <h5 className="footer-header-card">Company</h5>
              {NavMenus.map((nav, index) => {
                return (
                  <li className="nav-item" key={index}>
                    <NavLink className={({ isActive }) => (isActive ? "footer-link-active" : "footer-link")} to={nav.path}>
                      {nav.title}
                    </NavLink>
                  </li>
                );
              })}
            </div>
          </div>
          <div className="col-lg-2">
            <div className="footer-col  ">
              <h5 className="footer-header-card">Legal</h5>

              {CMSMenus.map((nav, index) => {
                return (
                  <li className="nav-item" key={index}>
                    <NavLink className={({ isActive }) => (isActive ? "footer-link-active" : "footer-link")} to={nav.path}>
                      {nav.title}
                    </NavLink>
                  </li>
                );
              })}
            </div>
          </div>
          <div className="col-lg-4 align-content-center">
            <div className=" subcribe_div_parent">
              <h5 className="footer-header-card1 my-3">Connect With Us</h5>
              <div className="d-flex footer-icon">
                {configData?.social_media
                  ?.filter((v) => v?.isShow)
                  ?.map((item, index) => (
                    <a key={index} href={item.url} target="_blank" rel="noopener noreferrer">
                      <span className="footer-icon1">{item.key === "instagram" ? <i class="fa-brands fa-instagram"></i> : item.key === "facebook" ? <i class="fa-brands fa-square-facebook"></i> : <i class="fa-brands fa-x-twitter"></i>}</span>
                    </a>
                  ))}
              </div>
              <h5 className="footer-header-card2">Receive exclusive offers in your mailbox</h5>

              <form>
                <div className="form-group1 d-flex">
                  <label className="icon-input-email">
                    {/* <span className="icon-email">&#9993;</span> */}
                    <i className="fas fa-envelope icon-email"></i>
                    <input
                      type="text"
                      value={email || ""}
                      className="footer_subcribe_box"
                      placeholder="Enter your email"
                      // required
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </label>
                  <button type="submit" className="submit-button1" onClick={(e) => Subcribe(e)}>
                    Submit
                  </button>
                </div>
                {emailValidation?.email_id?.message && <p className="error_message text-start">{`Email ${emailValidation?.email_id?.message}` || ""}</p>}
              </form>
            </div>
          </div>
        </div>
      </div>
      <hr className="footer-line "></hr>

      <div className="d-flex justify-content-center pt-2" style={{ backgroundColor: "#212121" }}>
        <p className="footer-para">All rights Reserved</p>
        <img className="footer-icon2" src={footer5} alt="Icon 3" />
        <p className="footer-para">greatmoghulbriyani.com</p>
      </div>
    </footer>
  );
}

export default Footer;
