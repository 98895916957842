import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  _id: '',
  logo: '',
  pincode: [],
  homepage_menus: [],
  menupage_menus: [],
  cash_on_delivery: false,
  delivery_charge: 0,
  social_media: [],
  address: {
    value: '',
    isShow: false,
  },
  phone_number: {
    value: '',
    isShow: false,
  },
};

const appConfigSlice = createSlice({
  name: 'appconfig',
  initialState,
  reducers: {
    setAppConfig(state, action) {
      return { ...state, ...action.payload };
    },
  },
});

export const { setAppConfig } = appConfigSlice.actions;
export default appConfigSlice.reducer;
