import React, { useEffect, useState } from "react";
import CustomModal from "../../common/CustomModel";
import { AdminAddCategory, AdminGetCategory, AdminUpdateCategory } from "../../common/Api";
import { errorMsg, successMsg } from "../../common/Toastify";
import { NonEmptyValidation } from "../../common/validate";

const Category = () => {
  const [addCatModel, setAddCatModel] = useState(false);
  const [deleteModel, setDeleteModel] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [category, setCatgeory] = useState({});
  const [validation, setValidation] = useState({});
  const [allCategory, setAllCategory] = useState({});


  const CreateCategory = async () => {
    let validate = {};
    validate.category = NonEmptyValidation(category?.category);
    setValidation(validate);
    const validation_status = Object.values(validate).every(
      (v) => v.status === true
    );
    if (validation_status) {
      let payload = {
        category: category?.category,
      };
      const response = await AdminAddCategory(payload);
      if (response && response?.statusCode === 200) {
        setCatgeory({})
        setAddCatModel(false)
        GetAllCategory()
        successMsg("Category Created Successfully");
      } else {
        errorMsg(response?.message);
      }
    }
  };
  const UpdateCategory = async () => {
    let validate = {};
    validate.category = NonEmptyValidation(category?.category);
    setValidation(validate);
    const validation_status = Object.values(validate).every(
      (v) => v.status === true
    );
    if (validation_status) {
      let payload = {
        _id: category?._id,
        category: category?.category,
      }
      const response = await AdminUpdateCategory(payload);
      if (response && response?.statusCode === 200) {
        successMsg("Category Updated Successfully");
        setEditModel(false)
        setAddCatModel(false)
        setCatgeory({})
        GetAllCategory()
      } else {
        errorMsg(response?.message);
      }
    }
  }
  // const HandleCategory = (value, key) => {
  //   setCatgeory({ ...category, [key]: value });
  //   if (validation[key]) setValidation({ ...validation, [key]: false })
  // };

  const HandleCategory = (value, key) => {
    const regex = /^[A-Za-z\s]*$/; 

    if (key === 'category') {
        if (value === "") {
            setValidation({ ...validation, [key]: false });
        }
        else if (!regex.test(value)) {
            setValidation({
                ...validation,
                [key]: { message: "should only contain letters" }
            });
            return; 
        } else {
            setValidation({ ...validation, [key]: false });
        }
    }

    setCatgeory({ ...category, [key]: value });
};

  const GetAllCategory = async () => {
    const response = await AdminGetCategory()
    if (response && response?.statusCode === 200) {
      setAllCategory(response?.data)
    }
  }
  const UpdateCategoryStatus = async (status, category_id) => {

    let updated_status = status === "active" ? "in-active" : "active"

    let payload = {
      status: updated_status,
      _id: category_id
    }
    const response = await AdminUpdateCategory(payload);
    if (response && response?.statusCode === 200) {
      GetAllCategory()
      successMsg(`Category Updated Successfully`);
    } else {
      errorMsg(response?.message);
    }
  }

  useEffect(() => {
    GetAllCategory()
  }, []);


  return (
    <div>
      <div>
        <div className="d-flex justify-content-between">
          <h3 className="admin_head">All Categories</h3>
          <button
            className="admin_button mt-0"
            onClick={() => { setAddCatModel(true); setCatgeory({}) }}
          >
            + Add category
          </button>
        </div>

        <div className="tabular_column_parent mt-5">
          <div className="row">
            <div className="col-lg-2">
              <div className="d-flex justify-content-center tabular_heading">
                <h4>S.No</h4>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="d-flex justify-content-center tabular_heading">
                <h4>Category</h4>
              </div>
            </div>
            {/* <div className="col-lg-3">
              <div className="d-flex justify-content-center tabular_heading">
                <h4>Category Type</h4>
              </div>
            </div> */}
            <div className="col-lg-3">
              <div className="d-flex justify-content-center tabular_heading">
                <h4>Number of Product</h4>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="d-flex justify-content-center tabular_heading">
                <h4>Actions</h4>
              </div>
            </div>
          </div>
        </div>

        {allCategory?.length && allCategory?.map((item, index) => (
          <div className="tabular_column_data_parent mt-4">
            <div className="row">
              <div className="col-lg-2">
                <div className="d-flex justify-content-center tabular_heading">
                  <h4>{index+1}</h4>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="d-flex justify-content-center tabular_heading" style={{overflowWrap:"anywhere", textAlign:"center"}}>
                  <h6>{item?.category}</h6>
                </div>
              </div>
              {/* <div className="col-lg-4">
                <div className="d-flex justify-content-center tabular_heading">
                  <h6>{item?.category_type === "home_page" ? "Home Page" : "Menu Page"}</h6>
                </div>
              </div> */}
              <div className="col-lg-3">
                <div className="d-flex justify-content-center tabular_heading">
                  <h6>{item?.product_count}</h6>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="d-flex justify-content-center tabular_heading">
                  <div className="action_category d-flex">
                    <i
                      class="fa-solid fa-pen-to-square action_icon"
                      onClick={() => {
                        setCatgeory(item)
                        setAddCatModel(true);
                        setEditModel(true);
                        setValidation({})
                      }}
                    ></i>
                    {/* <i
                    class="fa-solid fa-trash-can action_icon text-danger ms-4"
                    onClick={() => setDeleteModel(true)}
                  ></i> */}
                    <label class="switch ms-3">
                      <input type="checkbox" checked={item?.status === "active" ? true : false}
                        onClick={() => {
                          setCatgeory(item);
                          UpdateCategoryStatus(item?.status, item?._id)
                        }} />
                      <span class="slider round"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <CustomModal open={addCatModel}>
        <div>
          <div className="d-flex flex-column">
            <label className="model_label_admin">
              {editModel ? "Edit" : ""} Category 
            </label>
            <input
              value={category?.category || ""}
              className="model_input_admin"
              maxLength={20}
              onChange={(e) => HandleCategory(e.target.value, "category")}
            />
            {validation?.category?.message && (
              <p className='error_message text-start'>{`Category ${validation?.category?.message}` || ''}</p>
            )}
          </div>
          {/* <div className="d-flex flex-column mt-3">
            <label className="model_label_admin">
              {editModel ? "Edit" : ""} Category Type
            </label>
            <select
              className="model_select"
              onChange={(e) => HandleCategory(e.target.value, "category_type")}
            >
              <option disabled selected value="Select Category Type">
                Select Category Type
              </option>
              <option value="home_page">Home Page</option>
              <option value="menu_page">Menu Page</option>
            </select>
            {validation?.category_type?.message && (
              <p className='error_message text-start'>{`Category type ${validation?.category_type?.message}` || ''}</p>
            )}
          </div> */}
          <div className="d-flex justify-content-between mt-3">
            <button
              className="px-5"
              onClick={() => {
                if (editModel) {
                  setEditModel(false);
                  setAddCatModel(false);
                  setCatgeory({})

                } else {
                  setAddCatModel(false);
                }
              }}
            >
              Cancel
            </button>
            <button className="px-5" onClick={() => {
              if (!editModel) {
                CreateCategory()
              }
              else {
                UpdateCategory()
              }
            }}>{editModel ? "Update" : "Submit"}</button>
          </div>
        </div>
      </CustomModal>
      <CustomModal open={deleteModel}>
        <div className="logout-modal p-5">
          <p className="text-center mb-4 text-black delete_para_model">
            Are you sure do you want to delete ?
          </p>
          <div className="row">
            <div className="col-6">
              <button className="px-5">Yes</button>
            </div>
            <div className="col-6">
              <button
                onClick={() => setDeleteModel(false)}
                className="px-5 ms-5"
                style={{ backgroundColor: "red" }}
              >
                No
              </button>
            </div>
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default Category;
