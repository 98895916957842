import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
// import Otppage from './Otppage';
import Navbar from "../components/Navbarcontainer";
import { useSelector, useDispatch } from "react-redux";
import { setFormData } from "../formSlice";
import axios from "axios";
import { EmailValidation, NonEmptyValidation, OtpValidation, PhoneNumberValidation, EmailPhoneValidation } from "../common/validate";
import { errorMsg, successMsg } from "../common/Toastify";
import { GetCardProducts, GetDeliveryAddress, SaveDeliveryAddress, UpdateDeliveryAddress, DeleteDeliveryAddress, UserLogin, UserLoginVerifyOtp, UserSignup, SignUp_VerifyOtp, CreateOrder, AdminGetAppConfig } from "../common/Api";
import { loginSuccess, logout, otpVerified } from "../store/slice/loginSlice";
import Footer from "./Footerpage";
import CartComponent from "../common/CartComponent";
import { useRazorpay } from "react-razorpay";
import CustomModal from "../common/CustomModel";
import gif from "../assets/images/Animation - 1734693208067.gif";
import payment from "../assets/images/razorpay-icon.webp";
import { clearCart } from "../store/slice/cartSlice";
import { useNavigate } from "react-router-dom";

const CheckoutPage = () => {
  const location = useLocation();
  const { items, subtotal } = useSelector((state) => state.cart);
  const { email_id, isLoggedIn, _id, name, phone_number } = useSelector((state) => state?.login);
  const configData = useSelector((state) => state.appconfig);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { Razorpay } = useRazorpay();
  const [activeTab, setActiveTab] = useState(location.state?.activeTab || 0);
  const [deleteModel, setDeleteModel] = useState(false);
  const [activeModel, setactiveModel] = useState(false);
  const [orderModel, setorderModel] = useState(false);
  const [formType, setFormType] = useState("");
  const [userData, setUserData] = useState({});
  const [showOtp, setShowOtp] = useState(false);
  const [emailId, setEmailId] = useState("");
  const [otp, setOtp] = useState();
  const [isPhoneNumber, setIsPhoneNumber] = useState(false);
  const [validation, setValidation] = useState({});
  const [showbuttons, setShowbuttons] = useState(false);
  const [cartProducts, setCartProducts] = useState([]);
  const [grandTotal, setGrandTotal] = useState(0);
  const [savedAddress, setSavedAddress] = useState([]);
  const [addressForm, setAddressForm] = useState({});
  const [editButton, setEditButton] = useState(false);
  const [savedAddresss_id, setSavedAddresss_id] = useState({});
  const [orderPayload, setOrderPayload] = useState({});

  const handleOrderPayload = (value, key) => {
    setOrderPayload((prev) => ({ ...prev, [key]: value }));
  };

  const [loading, setLoading] = useState(false);

  const CreateOrderDetails = async () => {
    setLoading(true);
    const newPayload = {
      Products: items,
      user_id: _id,
      sub_total: subtotal,
      total_payable_amount: subtotal + configData?.delivery_charge,
      ...(Object.keys(scheduleDelivery).length && {
        schelude_date: scheduleDelivery?.schelude_date,
        schelude_time: scheduleDelivery?.schelude_time,
      }),
      ...orderPayload,
    };

    const response = await CreateOrder(newPayload);
    setLoading(false);
    if (response && response?.statusCode === 200) {
      navigate("/user/order-history");
      setorderModel(true);
      dispatch(clearCart());
    }
  };

  const getDefaultTime = () => {
    const now = new Date();
    now.setHours(now.getHours() + 3); // Add 3 hours
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    return `${hours}:${minutes}`;
  };


  const getLocalDate = () => {
    const now = new Date();
    return now.toLocaleDateString("en-CA"); // Format: YYYY-MM-DD
  };


  const getMinDate = () => {
    const now = new Date();
    const currentHour = now.getHours();
    const currentMinutes = now.getMinutes();

    const [limitHour, limitMinutes] = configData?.delivery_timelimit?.split(":")?.map(Number) ?? [];


    if (
      currentHour > limitHour ||
      (currentHour === limitHour && currentMinutes >= limitMinutes)
    ) {

      now.setDate(now.getDate() + 1);
    } else {
      const now = new Date();
      return now.toLocaleDateString("en-CA")

    }

    return now.toISOString().split("T")[0];
  };

  const [scheduleDelivery, setScheduleDelivery] = useState({
    schelude_date: getMinDate(),
    schelude_time: getDefaultTime(),
  });

  const handleScheduleDelivery = (value, key) => {
    setScheduleDelivery({ ...scheduleDelivery, [key]: value });
  };

  const handleChange = (value, key) => {
    setUserData({ ...userData, [key]: value });
    if (validation[key]) setValidation({ ...validation, [key]: false });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let validate = {};
    validate.email_id = EmailValidation(userData?.email_id, "Email id");
    validate.name = NonEmptyValidation(userData?.name);
    validate.phone_number = PhoneNumberValidation(userData?.phone_number);
    setValidation(validate);
    const validation_status = Object.values(validate).every((v) => v.status === true);
    if (validation_status) {
      let payload = {
        email_id: userData?.email_id,
        phone_number: userData?.phone_number,
        name: userData?.name,
      };
      const response = await UserSignup(payload);
      if (response && response?.statusCode === 200) {
        setUserData({});
        let loginSlice_obj = response?.data;
        dispatch(loginSuccess(loginSlice_obj));
        setShowOtp(true);
        successMsg("Signup Successful");
      } else {
        errorMsg(response?.message);
      }
    }
  };


  const handleLogin = async (e) => {
    e.preventDefault();
    let validate = {};
    validate.emailId = EmailPhoneValidation(emailId, emailId.match(/^\d+$/) ? "Phone Number" : "Email Id");
    setValidation(validate);
    const validation_status = validate.emailId.status === true;
    if (validation_status) {
      const isPhone = /^\d+$/.test(emailId);
      setIsPhoneNumber(isPhone)
      const payload = isPhone
        ? { phone_number: emailId }
        : { email_id: emailId };
      const response = await UserLogin(payload);
      if (response && response?.statusCode === 200) {
        dispatch(
          loginSuccess({
            email_id: payload.email_id || "",
            phone_number: payload.phone_number || "",
          })
        );
        setShowOtp(true);
        setEmailId("");
        successMsg("OTP Send Successfully");
      } else {
        errorMsg(response?.message);
      }
    }
  };

  const handleVerifyOtp = async (e, otptype) => {
    e.preventDefault();
    let validate = {};
    validate.otp = OtpValidation(otp);
    setValidation(validate);
    const validation_status = validate.otp.status === true;
    if (validation_status) {
      let payload;
      if (isPhoneNumber) {
        payload = {
          phone_number: phone_number,
          otp: otp,
          otp_type: otptype,
        };
      }
      else {
        payload = {
          email_id: email_id,
          otp: otp,
          otp_type: otptype,
        };
      }

      let response;
      if (otptype === "signUp") {
        response = await SignUp_VerifyOtp(payload);
      } else {
        response = await UserLoginVerifyOtp(payload);
      }
      if (response && response?.statusCode === 200) {
        dispatch(otpVerified());
        dispatch(
          loginSuccess({
            ...response?.data,
            isLoggedIn: true,
          })
        );
        setOtp();
        setActiveTab(1);
        setIsPhoneNumber(false)
        successMsg("OTP Verified Successfully");
      }
      else {
        errorMsg(response?.message);
      }
    }
  };
  const GetCartProduct = async () => {
    const response = await GetCardProducts(_id);
    if (response && response?.statusCode === 200) {
      setCartProducts(response?.data);
    }
  };
  const handleAddressForm = (value, key) => {
    setAddressForm({ ...addressForm, [key]: value });
    if (validation[key]) setValidation({ ...validation, [key]: false });
  };

  const handleSubmitAddress = async (e) => {
    e.preventDefault();
    const validate = {};
    validate.name = NonEmptyValidation(addressForm?.name);
    validate.phone_number = PhoneNumberValidation(addressForm?.phone_number);
    validate.door_number = NonEmptyValidation(addressForm?.door_number);
    validate.street = NonEmptyValidation(addressForm?.street);
    validate.pincode = NonEmptyValidation(addressForm?.pincode);
    validate.landmark = NonEmptyValidation(addressForm?.landmark);
    setValidation(validate);
    const validation_status = Object.values(validate).every((v) => v.status === true);

    if (validation_status) {
      let payload = {
        user_id: _id,
        name: addressForm?.name,
        phone_number: addressForm?.phone_number,
        door_number: addressForm?.door_number,
        street: addressForm?.street,
        pincode: addressForm?.pincode,
        landmark: addressForm?.landmark,
      };
      const response = await SaveDeliveryAddress(payload);
      if (response && response?.statusCode === 200) {
        setAddressForm({});
        setShowbuttons(false)
        await GetAddress();
        successMsg("Address Added Successfully");
      }
    }
  };

  const handleDelete = async () => {
    let payload = {
      _id: savedAddresss_id,
    };

    const response = await DeleteDeliveryAddress(payload);
    if (response && response?.statusCode === 200) {
      setDeleteModel(false);

      setSavedAddress((prevAddresses) => prevAddresses.filter((address) => address._id !== savedAddresss_id));

      successMsg("Address deleted successfully");

      await GetAddress();
    }
  };

  const GetAddress = async () => {
    const response = await GetDeliveryAddress(_id);
    if (response && response?.statusCode === 200) {
      setSavedAddress(response?.data);
    }
  };

  const UpdateSavedAddress = async (e) => {
    e.preventDefault();
    const validate = {};
    validate.name = NonEmptyValidation(addressForm?.name);
    validate.phone_number = PhoneNumberValidation(addressForm?.phone_number);
    validate.door_number = NonEmptyValidation(addressForm?.door_number);
    validate.street = NonEmptyValidation(addressForm?.street);
    validate.pincode = NonEmptyValidation(addressForm?.pincode);
    validate.landmark = NonEmptyValidation(addressForm?.landmark);
    setValidation(validate);
    const validation_status = Object.values(validate).every((v) => v.status === true);
    if (validation_status) {
      let payload = {
        _id: savedAddresss_id,
        name: addressForm?.name,
        phone_number: addressForm?.phone_number,
        door_number: addressForm?.door_number,
        street: addressForm?.street,
        pincode: addressForm?.pincode,
        landmark: addressForm?.landmark,
      };
      const response = await UpdateDeliveryAddress(payload);
      if (response && response?.statusCode === 200) {
        successMsg("Address Updated Successfully");
        setAddressForm({});
        setShowbuttons(false)
        setEditButton(false);
        await GetAddress();
      }
    }
  }

  const handlePayment = () => {
    const options = {
      key: "rzp_test_4jrrDyoBkXceJv",
      amount: 534,
      currency: "INR",
      name: "Test Company",
      description: "Test Transaction",
      handler: async (response) => {
        // setorderModel(true)
        handleOrderPayload(response?.razorpay_payment_id, "payment_order_id");
        await CreateOrderDetails();
      },
      prefill: {
        name: "John Doe",
        email: "john.doe@example.com",
        contact: "9999999999",
      },
      theme: {
        color: "#F37254",
      },
    };

    const razorpayInstance = new Razorpay(options);
    razorpayInstance.open();
  };

  const delivery_timelimit = configData?.delivery_timelimit;

  const currentTime = new Date();

  let deliveryTimeLimit;
  if (delivery_timelimit) {
    const [hours, minutes] = delivery_timelimit.split(":").map(Number);
    deliveryTimeLimit = new Date();
    deliveryTimeLimit.setHours(hours, minutes, 0, 0);
  }

  const shouldDisplay = delivery_timelimit && currentTime > deliveryTimeLimit;

  useEffect(() => {
    const calculateSubtotal = () => {
      const subtotal = cartProducts?.reduce((total, product) => {
        const itemTotal = (product?.price || 0) * (product?.quantity || 0);
        return total + itemTotal;
      }, 0);
      setGrandTotal(subtotal);
    };
    calculateSubtotal();
  }, [cartProducts]);
  useEffect(() => {
    if (_id) {
      GetCartProduct();
      GetAddress();
    }
  }, [_id]);
  // useEffect(() => {
  //   GetappConfig();
  // }, []);

  const tabs = ["Customer Details", "Delivery Information", "Schedule Delivery", "Payment Method"];

  return (
    <div>
      <div>
        <Navbar />
      </div>

      <div className="container">
        <div className="checkout-image">
          <div className="row">
            <div className="col-lg-7">
              <div className="form-container">
                <ul className="tabs">
                  {tabs.map((tab, index) => (
                    <li
                      key={index}
                      className={`tab-item ${activeTab === index ? "active" : ""}`}
                      onClick={() => {
                        if (isLoggedIn) {
                          setActiveTab(index);
                        }
                      }}
                    >
                      {tab}
                    </li>
                  ))}
                </ul>
                <div className="form-content">
                  <div className="form-section">
                    <div className="button-group" style={{ marginBottom: "15px" }}>
                      {activeTab === 0 && !isLoggedIn && (
                        <>
                          <h6>To place your order now, log in to receive the OTP on your mobile or email.</h6>
                          <button
                            onClick={() => {
                              setFormType("login");
                              setShowbuttons(false);
                              setShowOtp(false);
                            }}
                          >
                            Login
                          </button>
                          <button
                            onClick={() => {
                              setFormType("signup");
                              setShowbuttons(false);
                              setShowOtp(false);
                            }}
                          >
                            Sign Up
                          </button>
                        </>
                      )}
                    </div>
                    {activeTab === 0 && (
                      <>
                        {isLoggedIn ? (
                          <>
                            <div className="card-checkout user-card">
                              <div className="card-body-checkout">
                                <div className="user-info">
                                  <p><i className="fas fa-user"></i> <strong>Name:</strong><span className="bold-text">{name}</span></p>
                                  <p><i className="fas fa-envelope"></i> <strong>Email Address:</strong><span className="bold-text"> {email_id}</span></p>
                                  <p><i className="fas fa-phone"></i> <strong>Mobile Number:</strong><span className="bold-text"> {phone_number}</span></p>
                                </div>
                              </div>
                            </div>

                          </>
                        ) : (
                          <>
                            {formType === "signup" && !showOtp && (
                              <>
                                <h5>Sign Up Form</h5>
                                <div className="form-group">
                                  <label className="icon-input">
                                    <span className="icon">
                                      <i class="fa-solid fa-circle-user"></i>
                                    </span>
                                    <input type="text" placeholder="Name" value={userData?.name || ""} onChange={(e) => handleChange(e.target.value, "name")} />
                                  </label>
                                  {validation?.name?.message && <p className="error_message text-start">{`Name ${validation?.name?.message}` || ""}</p>}
                                </div>
                                <div className="form-group">
                                  <label className="icon-input">
                                    <span className="icon">
                                      <i class="fa-solid fa-phone"></i>
                                    </span>
                                    <input
                                      type="number"
                                      placeholder="Mobile"
                                      value={userData?.phone_number || ""}
                                      onChange={(e) => handleChange(e.target.value, "phone_number")}
                                      onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                      onWheelCapture={(e) => {
                                        e.target.blur();
                                      }}
                                    />
                                  </label>
                                  {validation?.phone_number?.message && <p className="error_message text-start">{`Phone Number ${validation?.phone_number?.message}` || ""}</p>}
                                </div>
                                <div className="form-group">
                                  <label className="icon-input">
                                    <span className="icon">
                                      <i class="fa-solid fa-envelope"></i>
                                    </span>
                                    <input type="text" placeholder="Email" value={userData?.email_id || ""} onChange={(e) => handleChange(e.target.value, "email_id")} />
                                  </label>
                                  {validation?.email_id?.message && <p className="error_message text-start">{`Email Id ${validation?.email_id?.message}` || ""}</p>}
                                </div>
                                <button className="sign-up-submit mb-3" onClick={handleSubmit}>
                                  Submit
                                </button>
                              </>
                            )}

                            {formType === "login" && !showOtp && (
                              <>
                                <h5>Login Form</h5>
                                {
                                  <div className="form-group">
                                    <label className="icon-input">
                                      <span className="icon">
                                        <i class="fa-solid fa-envelope"></i>
                                      </span>
                                      <input type="text" placeholder="Email or Mobile Number" value={emailId || ""} onChange={(e) => setEmailId(e.target.value)} />
                                    </label>
                                    {validation?.emailId?.message && <p className="error_message text-start">{` ${validation?.emailId?.message}` || ""}</p>}
                                  </div>
                                }
                                <button className="sign-up-submit mb-3" onClick={handleLogin}>
                                  Submit
                                </button>
                              </>
                            )}
                            {showOtp && (
                              <>
                                <h5>Verify Otp</h5>
                                <p>
                                  Enter the 4 digit OTP sent to<strong> {isPhoneNumber ? phone_number : email_id}</strong>
                                </p>
                                <div className="form-group">
                                  <label className="icon-input">
                                    <span className="icon">&#128276;</span>
                                    <input
                                      type="number"
                                      placeholder="Enter OTP"
                                      value={otp || ""}
                                      onChange={(e) => setOtp(e.target.value)}
                                      onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                      onWheelCapture={(e) => {
                                        e.target.blur();
                                      }}
                                    />
                                  </label>
                                  {validation?.otp?.message && <p className="error_message text-start">{`Otp ${validation?.otp?.message}` || ""}</p>}
                                </div>
                                {/* <button onClick={handleSubmit}>Cancel</button> */}
                                <button onClick={(e) => (formType === "signup" ? handleVerifyOtp(e, "signUp") : handleVerifyOtp(e, "login"))} className="w-100">
                                  Verify OTP
                                </button>
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                    {isLoggedIn && activeTab === 1 && (
                      <div className="form-section">
                        <h5 className="mb-3">Delivery Information</h5>
                        {savedAddress.length !== 0 && showbuttons === false && (
                          <>
                            {savedAddress.map((item, index) => (
                              <div className="saved-addresses">
                                <div className="address-cards">
                                  <div
                                    className={`address-card d`}
                                    onClick={() => {
                                      setSavedAddresss_id(item?._id);
                                      handleOrderPayload(item?._id, "delivery_address_id");
                                    }}
                                  >
                                    <label className="radio-label">
                                      <input type="radio" name="selectedAddress" checked={item?._id === savedAddresss_id} />
                                      <div className="card-content">
                                        <div>
                                          <div className="card-row-bold">
                                            <p className="mb-0">{item?.name}</p>
                                            <p className="mb-0">{item?.phone_number}</p>
                                            {/* <span>{info.name}</span>
                                          <span>{info.mobile}</span> */}
                                          </div>
                                          <div className="card-row">
                                            <p className="mb-0">
                                              {item?.door_number} {item?.street}{" "}
                                            </p>
                                            <p className="mb-0">
                                              {item?.landmark} chennai - {item?.pincode}{" "}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </label>
                                    <div className="card-actions">
                                      <button
                                        className="btnnew btn-edit"
                                        onClick={() => {
                                          setEditButton(true);
                                          setShowbuttons(true);
                                          setAddressForm(item);
                                        }}
                                      >
                                        Edit
                                      </button>
                                      <button
                                        className="btnnew btn-delete"
                                        onClick={() => {
                                          setDeleteModel(true);
                                          setSavedAddresss_id(item._id);
                                        }}
                                      >
                                        Delete
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                            <CustomModal open={deleteModel}>
                              <div className="logout-modal p-5">
                                <p className="text-center mb-4 text-black delete_para_model">Are you sure do you want to delete ?</p>
                                <div className="row">
                                  <div className="col-6">
                                    <button className="px-5" onClick={handleDelete}>
                                      Yes
                                    </button>
                                  </div>
                                  <div className="col-6">
                                    <button onClick={() => setDeleteModel(false)} className="px-5 ms-5" style={{ backgroundColor: "red" }}>
                                      No
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </CustomModal>
                          </>
                        )}

                        {showbuttons && (
                          <form className="delivery-form">
                            <div className="form-group">
                              <label className="icon-input">
                                <span className="icon">&#128100;</span>
                                <input type="text" value={addressForm?.name || ""} maxLength={25} placeholder="Delivery Name" onChange={(e) => handleAddressForm(e.target.value, "name")} />
                              </label>
                              {validation?.name?.message && <p className="error_message text-start">{`Name ${validation?.name?.message}` || ""}</p>}
                            </div>
                            <div className="form-group">
                              <label className="icon-input">
                                <span className="icon">&#9742;</span>
                                <input
                                  type="number"
                                  value={addressForm?.phone_number || ""}
                                  placeholder="Delivery Phone Number"
                                  onChange={(e) => handleAddressForm(e.target.value, "phone_number")}
                                  onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                  onWheelCapture={(e) => {
                                    e.target.blur();
                                  }}
                                />
                              </label>
                              {validation?.phone_number?.message && <p className="error_message text-start">{`Phone Number ${validation?.phone_number?.message}` || ""}</p>}
                            </div>
                            <div className="form-group">
                              <label className="icon-input">
                                <span className="icon">&#127968;</span>
                                <input type="text" value={addressForm?.door_number || ""} placeholder="Door Number" onChange={(e) => handleAddressForm(e.target.value, "door_number")} />
                              </label>
                              {validation?.door_number?.message && <p className="error_message text-start">{`Door Number ${validation?.door_number?.message}` || ""}</p>}
                            </div>
                            <div className="form-group">
                              <label className="icon-input">
                                <span className="icon">&#127963;</span>
                                <input type="text" value={addressForm?.street || ""} placeholder="Street" onChange={(e) => handleAddressForm(e.target.value, "street")} />
                              </label>
                              {validation?.street?.message && <p className="error_message text-start">{`Street ${validation?.street?.message}` || ""}</p>}
                            </div>
                            <div className="form-groupnew mb-3">
                              <label className="icon-inputnew mb-0">
                                <span className="iconnew">&#127759;</span>
                                <select className="dropdown" onChange={(e) => handleAddressForm(e.target.value, "pincode")} value={addressForm?.pincode || "Select Pincode"}>
                                  <option disabled selected>
                                    Select Pincode
                                  </option>

                                  {configData?.pincode?.map((item, index) => (
                                    <option key={index} value={item}>
                                      {item}
                                    </option>
                                  ))}
                                </select>
                              </label>
                            </div>
                            {validation?.pincode?.message && <p className="error_message text-start mb-3">{`Pincode ${validation?.pincode?.message}` || ""}</p>}
                            <div className="form-group">
                              <label className="icon-input">
                                <span className="icon">&#128205;</span>
                                <input type="text" value={addressForm?.landmark || ""} placeholder="Landmark" onChange={(e) => handleAddressForm(e.target.value, "landmark")} />
                              </label>
                              {validation?.landmark?.message && <p className="error_message text-start">{`Landmark ${validation?.landmark?.message}` || ""}</p>}
                            </div>
                            <div className="d-flex ">
                              <button className="my-3 w-100" onClick={() => {
                                setShowbuttons(false);
                                setValidation({})
                              }}>
                                Cancel
                              </button>
                              <button
                                className="my-3 w-100"
                                onClick={(e) => {
                                  if (editButton) {
                                    UpdateSavedAddress(e);
                                  } else {
                                    handleSubmitAddress(e);
                                  }
                                }}
                              >
                                {editButton ? "Update Address" : "Add Address"}
                              </button>
                            </div>
                          </form>
                        )}
                        {!showbuttons && (
                          <button className="btn btn-secondary my-2 w-100" onClick={() => {
                            setShowbuttons(true); setAddressForm({});
                            setValidation({}); setEditButton(false)
                          }}>
                            Add New Address
                          </button>
                        )}
                      </div>
                    )}
                    {isLoggedIn && activeTab === 2 && (
                      <div className="form-section">
                        <h5>Schedule Delivery</h5>
                        <div className="form-group">
                          <label className="icon-input">
                            <span className="icon">&#128197;</span>
                            <input
                              type="date"
                              onChange={(e) => handleScheduleDelivery(e.target.value, "schelude_date")}
                              value={scheduleDelivery?.schelude_date || ""}
                              min={getMinDate()} // Restrict past dates and make today or next day selectable
                            />
                          </label>
                          {validation?.schelude_date?.message && <p className="error_message text-start">{`Schelude Date ${validation?.schelude_date?.message}` || ""}</p>}
                        </div>
                        <div className="form-group">
                          <label className="icon-input">
                            <span className="icon">&#9200;</span>
                            <input type="time" value={scheduleDelivery?.schelude_time || ""}
                              // min={currentTime}
                              onChange={(e) => handleScheduleDelivery(e.target.value, "schelude_time")}
                            />
                          </label>
                          {validation?.schelude_time?.message && <p className="error_message text-start">{`Schelude Time ${validation?.schelude_time?.message}` || ""}</p>}
                        </div>
                        {shouldDisplay && (
                          <div className="card-message">
                            <div>
                              Our Delivery is from 10am to 4pm. Any queries, please reach out - {configData?.phone_number?.value}
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                    {activeTab === 3 &&
                      (isLoggedIn && items?.length !== 0 ? (
                        <div className="form-section">
                          <h5>Payment Method</h5>
                          <div className="form-group">
                            <label className="payment-input">
                              <span className="icon">&#128179;</span>

                              {configData?.cash_on_delivery && (
                                <div
                                  className="d-flex ms-3"
                                  onClick={() => {
                                    handleOrderPayload("cash-on-delivery", "payment_type");
                                  }}
                                >
                                  <input type="radio" checked={orderPayload?.payment_type === "cash-on-delivery"} />
                                  <label htmlFor="cashOnDelivery">Cash on Delivery</label>
                                </div>
                              )}

                              <div
                                className="d-flex ms-3"
                                onClick={() => {
                                  handleOrderPayload("online-payment", "payment_type");
                                }}
                              >
                                <input type="radio" checked={orderPayload?.payment_type === "online-payment"} />
                                <label htmlFor="onlinePayment">Online Payment</label>
                              </div>
                            </label>
                          </div>

                          {orderPayload?.payment_type === "online-payment" && (
                            <div className="d-flex flex-column">
                              <a href="http://razorpay.com">
                                <img style={{ width: "100px" }} src={payment} alt="payment" />
                              </a>
                              <button
                                onClick={() => {
                                  if (!orderPayload?.delivery_address_id) {
                                    setActiveTab(1);
                                  } else {
                                    handlePayment();
                                    // dispatch(logout())
                                  }
                                }}
                              >
                                Pay Now
                              </button>
                            </div>
                          )}

                          {orderPayload?.payment_type === "cash-on-delivery" && (
                            <button
                              style={{ width: "100%" }}
                              className="my-3"
                              onClick={() => {
                                if (!orderPayload?.delivery_address_id) {
                                  setActiveTab(1);
                                } else {
                                  CreateOrderDetails();
                                }
                              }}
                              disabled={loading}
                            >
                              {loading ? (
                                <div className="loader">Loading...</div>
                              ) : (
                                "Place the order"
                              )}
                            </button>
                          )}
                        </div>
                      ) : (
                        <p className="empty_cart">Add Items To cart !!</p>
                      ))}
                    <CustomModal open={activeModel}>
                      <div className="logout-modal p-5">
                        <p className="text-center mb-4 text-black delete_para_model">Shop is open for this date</p>
                        <div className="row">
                          <div>
                            <button onClick={() => setactiveModel(false)} className="px-5" style={{ backgroundColor: "red" }}>
                              Close
                            </button>
                          </div>
                        </div>
                      </div>
                    </CustomModal>
                    <CustomModal open={orderModel}>
                      <div className="modal-overlay">
                        <div className="logout-modal">
                          <p className="text-center mb-4 text-black delete_para_model celebrate-text">Congratulations! Your order has been placed!</p>

                          <div className="gif-container">
                            <img src={gif} alt="Fireworks" className="fireworks-gif" />
                          </div>

                          <div className="row">
                            <div>
                              <button onClick={() => setorderModel(false)} className="px-5 close-button">
                                Close
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </CustomModal>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <CartComponent cartProducts={cartProducts} grandTotal={grandTotal} checkout={false} />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CheckoutPage;
