import axios from "axios";
import { logout, selectToken } from "../store/slice/loginSlice";

import { store } from "../store/store";
import { url } from "./BaseUrl";
const ApiBaseUrl = url + "/api/v1";
const ApiInstance = axios.create({
  baseURL: ApiBaseUrl,
});

ApiInstance.interceptors.request.use((config) => {
  try {
    const token = selectToken(store.getState());
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  } catch (error) {
    return config;
  }
});


ApiInstance.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      store.dispatch(logout());
      window.location.href = '/adminlogin';
    }
    return Promise.reject(error);
  }
);
export default ApiInstance;
