import React, { useState } from "react";
import { SideBarMenu } from "./Sidebar";
const SubMenu = ({ name, icon, subMenu }) => {
  const [isExpanded, setEaxpanded] = useState(false);
  const arrowStyle = {
    transform: isExpanded ? "rotate(0deg)" : "rotate(180deg)",
    transition: "transform 0.3s ease-in-out",
    cursor: "pointer",
  };

  return (
    <div className="d-flex flex-column justify-content-between">
      <div className={`sidebar-menu-wrapper  d-flex align-items-center justify-content-between sidebar_component`} onClick={() => setEaxpanded(!isExpanded)}>
        <div className="d-flex align-items-center sidebar_admin_menus">
          <div className="sidebar-menu">
            <p>
              <span className="sidebar-icon">{icon}</span>
            </p>
          </div>
          <p className="mb-0">{name}</p>
          <i className="fa-solid fa-chevron-down ms-auto me-2" style={arrowStyle}></i>
        </div>
      </div>
      {isExpanded ? <div className="">{subMenu?.map((item, i) => SideBarMenu(i, item))}</div> : ""}
    </div>
  );
};

export default SubMenu;
