import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbarcontainer";
import Footer from "./Footerpage";
import { getCmsContent, UpdateCmsContent } from "../common/Api";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { useLocation } from "react-router-dom";
import { successMsg } from "../common/Toastify";

function CMSEditPage({ cms_document_type, title }) {
  const [cmscontent, setCmsContent] = useState();
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const location = useLocation();

  const onEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const get_Cms_Content = async () => {
    const response = await getCmsContent(cms_document_type);
    if (response && response?.statusCode === 200) {
      setCmsContent(response?.data);

      // Convert content to EditorState
      const html = response?.data?.content || "";
      const contentBlock = htmlToDraft(html);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        setEditorState(EditorState.createWithContent(contentState));
      }
    }
  };

  const saveContent = async () => {
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const htmlContent = draftToHtml(rawContentState);

    let payload = {
      content: htmlContent,
      cms_document_type: cms_document_type,
    };
    const response = await UpdateCmsContent(payload);
    if (response && response?.statusCode === 200) {
      successMsg("CMS Updated Successfully");
    }
  };

  useEffect(() => {
    get_Cms_Content();
    window.scrollTo(0, 0);

    function hideError(e) {
      if (e.message === "ResizeObserver loop completed with undelivered notifications.") {
        const resizeObserverErrDiv = document.getElementById("webpack-dev-server-client-overlay-div");
        const resizeObserverErr = document.getElementById("webpack-dev-server-client-overlay");
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }
    }

    window.addEventListener("error", hideError);
    return () => {
      window.removeEventListener("error", hideError);
    };
  }, [cms_document_type]);

  return (
    <>
      <div className="d-flex w-100 cms_header">
        <h1 className="fw-bold">{title}</h1>
        <button onClick={saveContent} className="btn btn-primary  ms-auto  ">
          Save Content
        </button>
      </div>

      <br></br>
      <Editor editorState={editorState} toolbarClassName="toolbarClassName" wrapperClassName="wrapperClassName" editorClassName="editorClassName" onEditorStateChange={onEditorStateChange} />
    </>
  );
}

export default CMSEditPage;
