import React, { useEffect, useState } from "react";
import { GetProductByCategory } from "../common/Api";
import Navbar from "../components/Navbarcontainer";
import Footer from "./Footerpage";
import { useDispatch, useSelector } from "react-redux";
import CartComponent from "../common/CartComponent";
import { addToCart } from "../store/slice/cartSlice";
import CustomModal from "../common/CustomModel";
import { imageBaseUrl } from "../common/BaseUrl";

function Menu() {
  const { _id } = useSelector((state) => state?.login);
  const configData = useSelector((state) => state.appconfig);
  const dispatch = useDispatch();
  const [products, setproducts] = useState([]);
  const [categoryId, setCategoryId] = useState("");

  const [showScrollIcon, setShowScrollIcon] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setShowScrollIcon(true);
    } else {
      setShowScrollIcon(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);
  const [isCartOpen, setIsCartOpen] = useState(false);

  const toggleCartModal = () => setIsCartOpen((prev) => !prev);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const Getproducts = async () => {
    const response = await GetProductByCategory(categoryId);
    if (response && response?.statusCode === 200) {
      setproducts(response?.data);
    } else {
    }
  };

  useEffect(() => {
    Getproducts();
  }, [categoryId]);

  return (
    <div className="">
      <Navbar />
      <div className="container-fluid text-center menu-image">
        <h1 className="header">Our Menu</h1>
        <p className="paragraph">We consider all the drivers of change gives you the components you need to change to create a truly happens.</p>
        {/* <p className="additional-text">Approximately 3 hrs Delivery Time</p>
      <p className="additional-text1">1kg Briyani Serves 7 Persons.</p> */}
      </div>
      <div className="container">
        <div className="menucontainer">
          <div className="menu-tabContainer">
            <button className={`tab-button ${categoryId === "" ? "active" : "inactive"}`} onClick={() => setCategoryId("")}>
              All
            </button>
            {configData?.menu_page_menus?.map((item, index) => (
              item?.product_count !== 0 && (
                <button className={`tab-button ${item?._id === categoryId ? "active" : "inactive"}`} onClick={() => setCategoryId(item?._id)}>
                  {item?.category}
                </button>
              )
            ))}
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-7 scrollable-column">
                <div className="row">
                  {products &&
                    products.map((item, index) => (
                      <div className="col-lg-6 mb-4">
                        <div className="menucard-top">
                          <div className="position-relative" key={index}>
                            <img className="cardimage" src={`${imageBaseUrl + item?.image}`} alt="food_images" />
                            <div className="menu-gradient-border">
                              <div className="price-details">
                                <div className="d-flex">
                                  <span className="price-amount">₹{item?.final_price}</span>
                                  {item?.base_price != null && item?.base_price !== 0 && (
                                    <span className="price-amount ms-1" style={{ textDecoration: "line-through" }}>
                                      ₹{item?.base_price}
                                    </span>
                                  )}
                                </div>
                                <hr className="m-0" />
                                <span className="price-weight">{item?.unit}</span>
                              </div>
                            </div>
                            <div className="menu-card1">
                              <div className="card-body">
                                <h5 className="card-title">{item?.product_name}</h5>
                                <p className="card-text">{item?.description}</p>
                                <div className="button-res">
                                  {item?.stock === "in_stock" ? <button
                                    className="check-button1"
                                    onClick={() => {
                                      dispatch(
                                        addToCart({
                                          id: item?._id,
                                          name: item?.product_name,
                                          price: item?.final_price,
                                          image: item?.image,
                                          unit: item?.unit,
                                        })
                                      );                               
                                    }}
                                  >
                                    + Add
                                  </button> :
                                    <button
                                      className="check-button1 bg-danger text-white"
                                      disabled
                                      style={{cursor:"none"}}
                                    >
                                      Out Of Stock
                                    </button>
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              <div className="col-lg-5 ">
                {isMobileView ? (
                  <div className="d-block d-md-none">
                    <button onClick={toggleCartModal} className="cart-icon-button">
                      <i className="fas fa-shopping-cart" style={{ fontSize: "24px" }}></i>
                    </button>

                    <CustomModal open={isCartOpen}>
                      <div className="cart-modal">
                        <CartComponent checkout={true} />
                      </div>
                    </CustomModal>
                  </div>
                ) : (
                  <CartComponent checkout={true} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <button className={`scroll-icon ${showScrollIcon ? "visible" : ""}`} onClick={scrollToTop}>
        <i className="fas fa-chevron-up"></i>
      </button>
      <Footer />
    </div>
  );
}

export default Menu;
